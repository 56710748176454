import React from 'react';

import './SlidesButtonPaid.css';

const PaidAnswersModal = () => {
  return (
    <div className='modal' id='paidAnswersModal'>
      <div className='modal-dialog modal-xl modal-dialog-centered'>
        <div className='modal-content'>
          <button type='button' className='close' data-dismiss='modal'>&times;</button>
          <p>You should go online to buy new answer</p>
        </div>
      </div>
    </div>
  );
};

export default PaidAnswersModal;
