import * as actionTypes from './types';


const addToAvatarState = resizedAvatar => ({
  type: actionTypes.GET_RESIZED_AVATAR,
  payload: resizedAvatar
});

export const changeCurrentUser = user => {
  return {
    type: actionTypes.CHANGE_CURRENT_USER,
    payload: user
  };
};

export const isAuthContextLoading = bool => {
  return {
    type: actionTypes.AUTH_CONTEXT_LOADING,
    payload: bool
  };
};

export const isOnline = online => {
  return {
    type: actionTypes.IS_ONLINE,
    payload: online
  };
};

export const addProfilePageError = err => {
  return {
    type: actionTypes.PROFILE_PAGE_ERROR,
    payload: err
  };
};

export const addSignInPageError = err => {
  return {
    type: actionTypes.SIGN_IN_PAGE_ERROR,
    payload: err
  };
};

export const isSignInPageLoading = bool => {
  return {
    type: actionTypes.SIGN_IN_PAGE_LOADING,
    payload: bool
  };
};

export const getAvatar = avatar => {
  return {
    type: actionTypes.GET_AVATAR,
    payload: avatar
  };
};

export const getResizedAvatar = (avatar, getResizedStoryImg) => {
  return async(dispatch) => {
    try {
      const img = avatar.split('/')[7].split('?')[0];
      const resizedImg = await getResizedStoryImg(img);
      await dispatch(addToAvatarState(resizedImg));
    } catch (err) {
      throw err;
    }
  };
};

export const fromLoginPage = bool => {
  return {
    type: actionTypes.FROM_LOGIN_PAGE,
    payload: bool
  };
};

export const isNonEmailVerified = bool => {
  return {
    type: actionTypes.EMAIL_NOT_VERIFIED,
    payload: bool
  };
};

export const successfulAuthorization = bool => {
  return {
    type: actionTypes.SUCCESSFUL_AUTHORIZATION,
    payload: bool
  };
};

export const setPasswordResetMessage = message => {
  return {
    type: actionTypes.PASSWORD_RESET_MESSAGE,
    payload: message
  };
};

export const setIsFileBeenChoosen = bool => {
  return {
    type: actionTypes.AVATAR_FILE,
    payload: bool
  };
};

export const clearAvatar = () => {
  return {
    type: actionTypes.CLEAR_AVATAR
  };
};

export const newUserIDPLogin = bool => {
  return {
    type: actionTypes.NEW_IDP_USER_LOGIN,
    payload: bool
  };
};
