// Firebase
export const FETCH_STORIES = 'FETCH_STORIES';
export const CREATE_STORIES  = 'CREATE_STORIES';
export const CREATE_COMMIT = 'CREATE_COMMIT';
export const DELETE_STORY = 'DELETE_STORY';
export const LOADING = 'LOADING';

// Registration
export const ADD_ERROR = 'ADD_ERROR';
export const IS_LOADING = 'IS_LOADING';
export const SIGN_UP_PAGE_ERROR = 'SIGN_UP_PAGE_ERROR';
export const SIGN_UP_PAGE_LOADING = 'SIGN_UP_PAGE_LOADING';
export const NEW_USER = 'NEW_USER';
export const SUCCESSFUL_REGISTRATION = 'SUCCESSFUL_REGISTRATION';

// Auth
export const CHANGE_CURRENT_USER = 'CHANGE_CURRENT_USER';
export const AUTH_CONTEXT_LOADING = 'AUTH_CONTEXT_LOADING';
export const IS_ONLINE = 'IS_ONLINE';
export const PROFILE_PAGE_ERROR = 'PROFILE_PAGE_ERROR';
export const SIGN_IN_PAGE_ERROR  = 'SIGN_IN_PAGE_ERROR';
export const SIGN_IN_PAGE_LOADING = 'SIGN_IN_PAGE_LOADING';
export const GET_AVATAR = 'GET_AVATAR';
export const GET_RESIZED_AVATAR = 'GET_RESIZED_AVATAR';
export const FROM_LOGIN_PAGE = 'FROM_LOGIN_PAGE';
export const EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED';
export const SUCCESSFUL_AUTHORIZATION = 'SUCCESSFUL_AUTHORIZATION';
export const PASSWORD_RESET_MESSAGE = 'PASSWORD_RESET_MESSAGE';
export const AVATAR_FILE = 'AVATAR_FILE';
export const CLEAR_AVATAR = 'CLEAR_AVATAR';
export const NEW_IDP_USER_LOGIN = 'NEW_IDP_USER_LOGIN';

// Stories Page
export const CHANGE_PARENT_ID = 'CHANGE_PARENT_ID';
export const CHANGE_CURRENT_ID = 'CHANGE_CURRENT_ID';
export const IS_MOBILE_MENU_OPEN = 'IS_MOBILE_MENU_OPEN';
export const ADD_WAYS = 'ADD_WAYS';
export const IS_MAIN = 'IS_MAIN';
export const ADD_AUDIO = 'ADD_AUDIO';
export const ADD_IMG = 'ADD_IMG';
export const ADD_BGR_IMG = 'ADD_BGR_IMG';
export const ADD_SMPICT = 'ADD_SMPICT';
export const ADD_BTNS = 'ADD_BTNS';
export const CHANGE_MODE = 'ADD_MODE';
export const ADD_TITLE = 'ADD_TITLE';
export const CHANGE_LIST = 'CHANGE_LIST';
export const CHANGE_MAIN_ID = 'CHANGE_MAIN_ID';
export const GET_MAIN_IMGS = 'GET_MAIN_IMGS';
export const LOAD_MORE_COMMITS = 'LOAD_MORE_COMMITS';
export const SET_COMMITS_NUM_TO_ZERO = 'SET_COMMITS_NUM_TO_ZERO';
export const SORTED_MAIN_STORIES = 'SORTED_MAIN_STORIES';
export const ADD_TO_OPEN_STORIES = 'ADD_TO_OPEN_STORIES';
export const ADD_TO_FINDED_STORIES = 'ADD_TO_FINDED_STORIES';

// Current Story
export const CHANGE_STORY_MODE = 'CHANGE_STORY_MODE';
export const IS_MOBILE_NAV_OPEN = 'IS_MOBILE_NAV_OPEN';
export const CONTINUE_MODAL = 'CONTINUE_MODAL';
export const CHANGE_PAGE_NUM = 'CHANGE_PAGE_NUM';
export const IS_EDIT = 'IS_EDIT';
export const ADD_NEW_BTN = 'ADD_NEW_BTN';
export const CHANGE_CURRENT_BTN = 'CHANGE_CURRENT_BTN';
export const SET_QUESTION = 'SET_QUESTION';
export const SET_DESCRIPTION = 'SET_DESCRIPTION';
export const GET_RESIZED_BGR_IMG = 'GET_RESIZED_BGR_IMG';
export const GET_RESIZED_SMPICT = 'GET_RESIZED_SMPICT';
export const SET_IMGS_TO_NULL = 'SET_IMGS_TO_NULL';
export const ADD_RATING = 'ADD_RATING';
export const FEEDBACK_ERROR = 'FEEDBACK_ERROR';
export const NEXT_BTN = 'NEXT_BTN';

// User Interaction
export const ADD_STORY_INTERACTION_DATA = 'ADD_STORY_INTERACTION_DATA';
export const SET_TO_START = 'SET_TO_START';
export const LEAVE_STORY_MODAL = 'LEAVE_STORY_MODAL';
export const STORY_COMPLETING = 'STORY_COMPLETING';
export const LAST_COMPLETED_STORY = 'LAST_COMPLETED_STORY';
export const NEW_STORY_ANIMATION = 'NEW_STORY_ANIMATION';

// Home Route
export const HOME_STORIES_BUTTON_CLICK = 'HOME_STORIES_BUTTON_CLICK';
export const OVER_EIGHTEEN = 'OVER_EIGHTEEN';
export const TERMS_AGREE = 'TERMS_AGREE';
export const FEEDBACK_PATHNAME = 'FEEDBACK_PATHNAME';
export const FEEDBACK_CLICKED = 'FEEDBACK_CLICKED';
export const REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN';
export const HOME_LOGIN_CLICKED = 'HOME_LOGIN_CLICKED';

// Buying Tickets
export const BTN_INNER_TEXT = 'BTN_INNER_TEXT';
export const CHOOSE_TICKET = 'CHOOSE_TICKET';
export const GET_PRICES = 'GET_PRICES';
export const CHANGE_TOKENS_AMOUNT = 'CHANGE_TOKENS_AMOUNT';
export const EDUCATIONAL_OVERLAY = 'EDUCATIONAL_OVERLAY';
export const EDUCATIONAL_OVERLAY_BEEN_SHOWN = 'EDUCATIONAL_OVERLAY_BEEN_SHOWN';
export const NEW_PAID_ANSWER_CHOOSING = 'NEW_PAID_ANSWER_CHOOSING';
export const SET_COUNTRY = 'SET_COUNTRY';
export const REDIRECT_TO_CHECKOUT = 'REDIRECT_TO_CHECKOUT';

// User Experience
export const RENDER_TYPEWRITER = 'RENDER_TYPEWRITER';
export const STORY_OVERLAY = 'STORY_OVERLAY';
export const QUESTIONS_DELAY = 'QUESTIONS_DELAY';
export const QUESTION_TYPING = 'QUESTION_TYPING';
export const STOP_TYPING = 'STOP_TYPING';
export const ANSWERS_DELAY = 'ANSWERS_DELAY';
export const TYPEWRITER_TYPE = 'TYPEWRITER_TYPE';
export const CHANGE_TYPEWRITER_STATE = 'CHANGE_TYPEWRITER_STATE';
export const TYPEWRITER_MOUNTED = 'TYPEWRITER_MOUNTED';

