import { useCallback, useLayoutEffect, useState } from 'react';
import FontFaceObserver from 'fontfaceobserver';

const useFontLoader = (fontFamily) => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const loadFont = useCallback(()=>{
    const font = new FontFaceObserver(fontFamily);
    font.load().then(() => {
      setFontLoaded(true);
    }).catch(() => {
      console.error(`Font ${fontFamily} failed to load.`);
      loadFont()
    });
  },[])

  useLayoutEffect(() => {
    if (!fontLoaded) {
      loadFont()
    }
  }, [fontFamily]);
  return fontLoaded;
};

export default useFontLoader;