import * as actionTypes from '../actions/types';

const initialState = {
  buttonInnerText: 'Buy',
  choosenTicket: [],
  ticketsPrices: [],
  tokensAmount: null,
  educationalOverlay: false,
  isOverlayBeenShown: false,
  newPaidAnswer: false,
  country: ['default', '$'],
  fromCheckout: (localStorage.getItem('fromCheckout') === 'true') || false 
};


const buyingTicketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BTN_INNER_TEXT:
      return {
        ...state,
        buttonInnerText: 'Processing...'
      }
    case actionTypes.CHOOSE_TICKET:
      return {
        ...state,
        choosenTicket: action.payload
      }
    case actionTypes.GET_PRICES:
      return {
        ...state,
        ticketsPrices: action.payload
      }
    case actionTypes.CHANGE_TOKENS_AMOUNT:
      return {
        ...state,
        tokensAmount: action.payload
      }
    case actionTypes.EDUCATIONAL_OVERLAY:
      return {
        ...state,
        educationalOverlay: action.payload
      }
    case actionTypes.EDUCATIONAL_OVERLAY_BEEN_SHOWN:
      return {
        ...state,
        isOverlayBeenShown: action.payload
      }
    case actionTypes.NEW_PAID_ANSWER_CHOOSING:
      return {
        ...state,
        newPaidAnswer: action.payload
      }
    case actionTypes.SET_COUNTRY:
      return {
        ...state,
        country: action.payload
      }
    case actionTypes.REDIRECT_TO_CHECKOUT:
      return {
        ...state,
        fromCheckout: action.payload
      }
    default:
      return state;
  }
};

export default buyingTicketsReducer;
