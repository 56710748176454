import { combineReducers } from 'redux';

import firebaseReducer from './firebaseReducer';
import registrationReducer from './registrationReducer';
import authReducer from './authReducer';
import storiesPageReducer from './storiesPageReducer';
import currentStoryReducer from './currentStoryReducer';
import userInteractionReducer from './userInteractionReducer';
import homeRouteReducer from './homeRouteReducer';
import buyingTicketsReducer from './buyingTicketsReducer';
import userExperienceReducer from './userExperienceReducer';


export default combineReducers({
  firebaseState: firebaseReducer,
  registrationState: registrationReducer,
  authState: authReducer,
  storiesPageState: storiesPageReducer,
  currentStoryState: currentStoryReducer,
  userInteractionState: userInteractionReducer,
  homeRouteState: homeRouteReducer,
  buyingTicketsState: buyingTicketsReducer,
  userExperienceState: userExperienceReducer,
});
