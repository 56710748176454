import firebase from 'firebase';
import "firebase/auth";
import 'firebase/firestore';
import 'firebase/functions';

const app = firebase.initializeApp({
    apiKey: "AIzaSyDT-fGbh2at9uDNTYb3rkDJNFBAvkQSqF4",
    authDomain: "myhumblestory-ceb9f.firebaseapp.com",
    projectId: "myhumblestory-ceb9f",
    databaseURL: "https://myhumblestory-ceb9f.firebaseio.com",
    storageBucket: "myhumblestory-ceb9f.appspot.com",
    messagingSenderId: "759690454234",
    appId: "1:759690454234:web:85dde0003ae3f592ec31e3",
    measurementId: "G-ZEL6FESZNY"
});


const auth = app.auth();
const db = app.firestore();
const functions = app.functions();
const providerGoogle = new firebase.auth.GoogleAuthProvider();
const providerFaceBook = new firebase.auth.FacebookAuthProvider();

db.settings({
  cacheSizeBytes: db.CACHE_SIZE_UNLIMITED,
  timestampsInSnapshots: true
});

db.enablePersistence()
  .catch((err) => {
    console.log(err.code);
  });

if(navigator.onLine) {
  db.enableNetwork();
} else {
  db.disableNetwork();
}

export { auth, db, functions, providerGoogle, providerFaceBook };
export default app;
