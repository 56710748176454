import * as actionTypes from '../actions/types';


const initialState = {
  mode: '',
  open: false,
  continueModalOpen: false,
  num: parseInt(localStorage.getItem('cur_num')) || 0,
  edit: false,
  question: '',
  description: '',
  newBtn: '',
  currentButton: '',
  bgrImg: '',
  smpict: '',
  currentRating: 0,
  feedBackError: '',
  nextBtn: true
};


const currentStoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_STORY_MODE:
      return {
        ...state,
        mode: action.payload
      }
    case actionTypes.ADD_NEW_BTN:
      return {
        ...state,
        newBtn: action.payload
      }
    case actionTypes.CHANGE_CURRENT_BTN:
      return {
        ...state,
        currentButton: action.payload
      }
    case actionTypes.IS_MOBILE_NAV_OPEN:
      return {
        ...state,
        open: action.payload
      }
    case actionTypes.CONTINUE_MODAL:
      return {
        ...state,
        continueModalOpen: action.payload
      }
    case actionTypes.IS_EDIT:
      return {
        ...state,
        edit: action.payload
      }
    case actionTypes.CHANGE_PAGE_NUM:
      return {
        ...state,
        num: action.payload
      }
    case actionTypes.SET_TO_START:
      localStorage.removeItem('cur_num');
      return {
        ...state,
        num: 0
      }
    case actionTypes.SET_QUESTION:
      return {
        ...state,
        question: action.payload
      }
    case actionTypes.SET_DESCRIPTION:
      return {
        ...state,
        description: action.payload
      }
    case actionTypes.GET_RESIZED_BGR_IMG:
      return {
        ...state,
        bgrImg: action.payload
      }
    case actionTypes.GET_RESIZED_SMPICT:
      return {
        ...state,
        smpict: action.payload
      }
    case actionTypes.SET_IMGS_TO_NULL:
      return {
        ...state,
        bgrImg: '',
        smpict: ''
      }
    case actionTypes.ADD_RATING:
      return {
        ...state,
        currentRating: action.payload
      }
    case actionTypes.FEEDBACK_ERROR:
      return {
        ...state,
        feedBackError: action.payload
      }
    case actionTypes.NEXT_BTN:
      return {
        ...state,
        nextBtn: action.payload
      }
    default:
        return state;
  }
};

export default currentStoryReducer;
