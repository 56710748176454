import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { setToStart } from '../../../actions/userInteractionActions';
import { isNextBtnNonActive, setImgsToNull } from '../../../actions/currentStoryActions';
import { setOverlay, setQuestionsDelay, isQuestionTyping, stopTyping, renderTypewriter, setTypewriterState, showAnswers } from '../../../actions/userExperienceActions';

import arrow from '../../../assets/images/Fill 1.png';

import './SlidesModal.css';


const SlidesModal = ({ currentStoryProps, setToStart, setOverlay, setQuestionsDelay, renderTypewriter,
                      setImgsToNull, isQuestionTyping, stopTyping, isNextBtnNonActive, setTypewriterState, showAnswers }) => {

  const history = useHistory();

  const isFeedbackPage = history.location.pathname.includes('feedback');

  function backToMain() {
    showAnswers(true);
    setTypewriterState(0);
    setToStart();
    setImgsToNull();
    stopTyping(false);
    isNextBtnNonActive(true);
    setQuestionsDelay(true);
    isQuestionTyping(true);
    renderTypewriter(false);
    history.push('/');
  }

  return (
    <div className='modal' id='myModal'>
      <div className='modal-dialog modal-xl modal-dialog-centered'>
        <div className='modal-content'>
          <button type='button' className='close-modal-button' data-dismiss='modal'>&times;</button>
          <h1 className='modal-h1'>Back to main page?</h1>
          {isFeedbackPage ? null : <p className='modal-p'>(All progress will be lost)</p>}
          <img src={arrow} className='arrow mt-5' alt=''/>
          <div className='profileDeleteModalBtns'>
            <button className='modal-button mr-5' data-dismiss='modal'>No</button>
            <button className='modal-button'  data-dismiss='modal' onClick={()=> backToMain()}>Yes</button>
          </div>
          </div>
        </div>
    </div>
  );
}

const mapStateToProps = state => ({
  currentStoryProps: state.currentStoryState
});

export default connect(mapStateToProps, { setToStart, setOverlay, setQuestionsDelay, setImgsToNull, renderTypewriter,
        isQuestionTyping, stopTyping, isNextBtnNonActive, setTypewriterState, showAnswers })(SlidesModal);
