import React, { useState } from 'react';
import { TailSpin } from 'react-loader-spinner'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { onHomeStoriesButtonClick, onHomeLoginClick } from '../../actions/homeRouteActions';

import { useAuth } from '../../context/AuthContext';

import logo from '../../essentials/logo.svg';
import man from '../../assets/images/home_page_img.png';
import oval from '../../assets/images/home_page_oval.svg';

import './index.css';


const Home = ({ homeRouteProps, onHomeStoriesButtonClick, onHomeLoginClick }) => {

  const history = useHistory();
  const [isLoader, setIsLoader] = useState(false)
  const [isLoaderLogin, setIsLoaderLogin] = useState(false)
  const { currentUser } = useAuth();

  const onClick = () => {
    setIsLoader(true)
    onHomeStoriesButtonClick(true);
    localStorage.setItem('homeSearchStoriesClicked', true);
    localStorage.setItem('isFirstTime',true)
    history.push('/ageVerify');
  };

  const onLoginClick = () => {
    setIsLoaderLogin(true)
    onHomeLoginClick(true);
    onHomeStoriesButtonClick(true);
    localStorage.removeItem('isFirstTime')
    history.push('/login');
  }

  return (
    <div className='startHome' style={{ backgroundImage: `url(${oval})` }}>
      <img src={logo} alt='myHumbleStory' className='homeLogoMobile' />
      <div className='homeImgWrapper'>
        <img src={man} alt='' className='homeImg'/>
      </div>
      <div className='homeDescription'>
        <img src={logo} alt='myHumbleStory' className='homeLogo' />
        <p>
          In the world where most of the content is being produced by AI, perhaps it is refreshing
          to read something written entirely by human.
          MyHumbleStory is a way to delve deep into the minds and chemistry of sexual encounters,
          navigate each situation and make it as innocent or as sinful as you desire,
          the only constraints are the limits of your own imagination.
          If you are one of the rare individuals able to experience the mind-blowing touch on your lips,
          the hypnotizing aroma of perfume, the feeling of breath on your neck and
          much more through imagination, then I hope you will enjoy MyHumbleStory.
        </p>
        <div className='homeButtonsContainer'>
          {currentUser ?
            null :
            <button className='home-button' onClick={() => onLoginClick()}>
            {isLoaderLogin?(<TailSpin
            visible={true}
            height="40px"
            width="40px"
            color="#FFFFFF"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="loader"
            />):"Login"}
          </button>
          }
          <button className='home-button' onClick={() => onClick()}>
            {isLoader ? (<TailSpin
            visible={true}
            height="40px"
            width="40px"
            color="#FFFFFF"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="loader"
            />):
            (currentUser ? 'Next' : 'First Time Here') }
          </button>
      </div>
      </div>
    </div>
  );

};

const mapStateToProps = state => ({
  homeRouteProps: state.homeRouteState
});

export default connect(mapStateToProps, { onHomeStoriesButtonClick, onHomeLoginClick })(Home);
