import * as actionTypes from './types';


export const renderTypewriter = bool => {
  return {
    type: actionTypes.RENDER_TYPEWRITER,
    payload: bool
  }
};

export const setOverlay = bool => {
  return {
    type: actionTypes.STORY_OVERLAY,
    payload: bool
  }
};

export const setQuestionsDelay = bool => {
  return {
    type: actionTypes.QUESTIONS_DELAY,
    payload: bool
  }
};

export const isQuestionTyping = bool => {
  return {
    type: actionTypes.QUESTION_TYPING,
    payload: bool
  }
};

export const stopTyping = bool => {
  return {
    type: actionTypes.STOP_TYPING,
    payload: bool
  }
};

export const showAnswers = bool => {
  return {
    type: actionTypes.ANSWERS_DELAY,
    payload: bool
  }
};

export const setTypewriterType = type => {
  return {
    type: actionTypes.TYPEWRITER_TYPE,
    payload: type
  }
};

export const setTypewriterState = num => {
  return {
    type: actionTypes.CHANGE_TYPEWRITER_STATE,
    payload: num
  }
};

export const isTypeWriterMounted = bool => {
  return {
    type: actionTypes.TYPEWRITER_MOUNTED,
    payload: bool
  }
};
