import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { addNewBtn, changeCurrentBtn, changePageNum  } from '../../../actions/currentStoryActions';
import { isQuestionTyping, showAnswers } from '../../../actions/userExperienceActions';

import { useAuth } from '../../../context/AuthContext';
import { useFirestore } from '../../../context/FirestoreContext';

import SlidesButtonFree from './slidesButtonFree/SlidesButtonFree';
import SlidesButtonPaid from './slidesButtonPaid/SlidesButtonPaid';

import './SlidesButtons.css';


const SlidesButtons = ({ userExperienceProps, currentStoryProps, addNewBtn, changeCurrentBtn, changePageNum, story, isQuestionTyping, showAnswers }) => {

  const { newBtn, currentButton } = currentStoryProps;

  const { questionTyping } = userExperienceProps;

  const history = useHistory();

  const { user } = useAuth();
  const { stories, updateParentWays, updateTitle } = useFirestore();

  function handleWaysChange(e) {
    addNewBtn(e.target.value)
  }

  function save(e) {
    e.preventDefault();
    const ways = story.ways.filter(btn => btn.value !== currentButton.value);
    const way = stories.find(item => item.title === currentButton.value && item.parentId === story.id);
    updateTitle(way.id, newBtn);
    updateParentWays(story.id, [{ ...currentButton, value: newBtn }, ...ways]);
    changeCurrentBtn({});
  }

  function push(id, name) {
    const story = stories.find(item => item.parentId === id && item.title === name);
    if (!story) {
      return;
    }
    changePageNum(0);
    return history.push(`/story/${story.id}`);
  }

  useEffect(() => {
    if (!questionTyping) {
      setTimeout(function() {
        showAnswers(false);
      }, 1000);
    }
  },[questionTyping]);

  const buttonsMap = story.ways.map(item => item.value);
  const check = stories.filter(item => item.parentId === story.id && buttonsMap.includes(item.title));
  const valid = check.map(item => item.title);

  const button = story.ways.map(item =>
    !user.admin && !item.published ? null : valid.includes(item.value) ? item.paid ?
      <SlidesButtonPaid story={story}
                        item={item}
                        push={push}
                        save={save}
                        key={item.value}
                        handleWaysChange={handleWaysChange} /> :
      <SlidesButtonFree story={story}
                        item={item}
                        push={push}
                        save={save}
                        key={item.value}
                        handleWaysChange={handleWaysChange} />
      : null);

  return questionTyping  ? null : button;
}

const mapStateToProps = state => ({
  currentStoryProps: state.currentStoryState,
  userExperienceProps: state.userExperienceState
});

export default connect(mapStateToProps, { showAnswers, addNewBtn, changeCurrentBtn, changePageNum, isQuestionTyping })(SlidesButtons);
