import React from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import arrowDown from "../../../assets/icons/pwa_app.svg";

import { useAuth } from "../../../context/AuthContext";

import { isMobileMenuOpen } from "../../../actions/storiesPageActions";

import useWindowSize from "../../../hooks/useWindowSize";

import "./index.css";

import HeaderTickets from "./HeaderTickets";
import PWAPopUp from "./PWAPopUp";

import NewUserAlert from "../../../pages/searchHistory/newUserAlert";

import Logo from "../../../essentials/logo.svg";
import logo from "../../../essentials/logo.svg";
import SearchInput from "../../searchInput";
import burger from "../../../assets/icons/sandwich.png";
import Twitter from "../../../assets/images/X.png";
import Fb from "../../../assets/images/meta.png";
import Inst from "../../../assets/images/Inst.png";

const isStandAlone = window.matchMedia("(display-mode: standalone)").matches;

const Header = ({
  search,
  open,
  isMobileMenuOpen,
  homeRouteProps,
  newIDPUser,
  isAllLoaded
}) => {
  const { currentUser } = useAuth();

  const { width } = useWindowSize();

  const { homeSearchStoriesClicked, overEighteen, termsAgree } = homeRouteProps;

  const isAllHomeRoutePropsTrue =
    homeSearchStoriesClicked && overEighteen && termsAgree;
  const canLinksBeShown =
    (currentUser && !newIDPUser) || isAllHomeRoutePropsTrue;

  const onClick = () => {
    isMobileMenuOpen(!open);
  };

  return (
    <React.Fragment>
      <div className="main-header" id="main-header">
        {currentUser ? (
          <HeaderTickets isMobile={true} isAllLoaded={isAllLoaded}/>
        ) : (
          <div className="main-header-stub-mobile" />
        )}
        <Link to="/">
          <img className="main-header-logo" src={Logo} alt="Logo" />
        </Link>

        {!isStandAlone && !localStorage.getItem("alertDismissed") ? (
          <NewUserAlert />
        ) : null}
        {/* <SearchInput search={search} /> */}
        {!isStandAlone && width <= 1200 ? (
          <PWAPopUp />
        ) : (
          <div className="downloadLinkPlaceholder">

          </div>
        )}
        {currentUser ? (
          <HeaderTickets isMobile={false} isAllLoaded={isAllLoaded} />
        ) : (
          <div className="main-header-stub-desktop" />
        )}
        <button className={`ham ${open ? "showClose" : ""}`} onClick={onClick}>
          {open ? null : <img src={burger} alt="" />}
        </button>
        <nav className={`navbar ${open ? "showNav" : ""}`}>
          <div className="footer2">
            <img src={logo} alt="" className="logo-nav" />
            <div className="navList">
              <NavLink
                className="navLinks"
                to={canLinksBeShown ? "/" : "/home"}
                onClick={() => isMobileMenuOpen(false)}
              >
                Stories
              </NavLink>
              <NavLink
                className="navLinks"
                to={canLinksBeShown ? "/profile" : "/home"}
                onClick={() => isMobileMenuOpen(false)}
              >
                Profile
              </NavLink>
              <NavLink
                className="navLinks"
                to={canLinksBeShown ? "/privacy" : "/home"}
                onClick={() => isMobileMenuOpen(false)}
              >
                Privacy
              </NavLink>
              <NavLink
                className="navLinks"
                to={canLinksBeShown ? "/faq" : "/home"}
                onClick={() => isMobileMenuOpen(false)}
              >
                FAQ
              </NavLink>
            </div>
            <div className="footerRight">
              <div className="roundLinks">
                <img src={Twitter} alt="Twitter" />
              </div>
              <div className="roundLinks">
                <img src={Fb} alt="Facebook" />
              </div>
              <div className="roundLinks">
                <img src={Inst} alt="Instagram" />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  homeRouteProps: state.homeRouteState,
  open: state.storiesPageState.mobileMenuOpen,
  newIDPUser: state.authState.newIDPUser,
});

export default connect(mapStateToProps, { isMobileMenuOpen })(Header);