import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { useAuth } from '../../context/AuthContext';

import { setPasswordResetMessage } from '../../actions/authActions';

import SlidesHamButton from '../../pages/Slides/slidesHamButton/SlidesHamButton';
import SlidesNav from '../../pages/Slides/slidesNav/SlidesNav';

import logo from '../../essentials/logo.svg';

import './index.css';


const ForgotPassword = ({ sendPasswordResetEmailMessage, setPasswordResetMessage }) => {

  const history = useHistory();

  const emailRef = useRef();

  const { resetPassword } = useAuth();

  const onPasswordReset = async () => {
    await resetPassword(emailRef.current.value);
  }

  useEffect(() => {
    if (sendPasswordResetEmailMessage === 'Password reset email sent') {
      setTimeout(function() {
        history.push('/Login');
        setPasswordResetMessage('');
      }, 2000);
    }
  }, [sendPasswordResetEmailMessage]);

  return (
    <div className='page-containerLogin'>
      <SlidesHamButton />
      <SlidesNav />
      <div className='containerLogin loginPage'>
        <div className='form-headerReset'>
          <div>
            <img src={logo} alt='' className='profile-imagelogin' />
          </div>
        </div>
        <div className='form-bodyLogin'>
          <p className='reset'>Enter your email to reset your password</p>
          <input type='text' placeholder='Email' className='form-inpu' ref={emailRef} />
        </div>
        <div className='container-headerReset'>
          <button className='continueButton' onClick={onPasswordReset} data-toggle='modal' data-target='#resetPassModal'>Send email</button>
        </div>
        <div>
          <p className='reset mt-4'>{sendPasswordResetEmailMessage}</p>
        </div>
      </div>
    </div>
  );

};

const mapStateToProps = state => ({
  sendPasswordResetEmailMessage: state.authState.sendPasswordResetEmailMessage
});

export default connect(mapStateToProps, { setPasswordResetMessage })(ForgotPassword);
