import * as actionTypes from '../actions/types';

const initialState = {
    stories: [],
    commits: [],
    loading: true,
    error: null,
    filesLoading: null
}
function deleteStory(state, payload) {
    const idx = state.stories.findIndex(item => item.id === payload.id)
    return {
        ...state,
        stories: [...state.stories.splice(0, idx),
        ...state.stories.splice(idx, 1)]
    }

}

const firebaseReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_STORIES:
            return state = {
                ...state,
                stories: [...action.payload],
                loading: false,
                error: null
            }
        case actionTypes.LOADING:
            return state = {
                ...state,
                filesLoading: action.payload
            }
        case actionTypes.CREATE_STORIES:
            return state = {
                ...state,
                stories: [...state.stories, action.payload],
                loading: false,
                error: null
            }
            case actionTypes.CREATE_COMMIT:
                return state = {
                    ...state,
                    commits: action.payload,
                    loading: false,
                    error: null
                }
        case actionTypes.DELETE_STORY:
            return deleteStory(state, action.payload)
        default:
            return state;
    }
}

export default firebaseReducer;
