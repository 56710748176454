import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { useAuth } from '../../../context/AuthContext';

import './index.css';

import Ticket from '../../../assets/icons/Ticket.svg';

const HeaderTickets = ({ userTokens, isMobile,isAllLoaded }) => {

  const { user } = useAuth();

  let paddingClass;
  if (!isMobile) {
    paddingClass = user.tokens <= 9 ? 'one-digit-padding' : 'two-digits-padding';
  } else {
    paddingClass = user.tokens <= 9 ? 'one-digit-padding-mobile' : 'two-digits-padding-mobile';
  }

  let stipeCheckout
  if (isAllLoaded){
    stipeCheckout = localStorage.getItem('stipecheckout')
  } else {
    stipeCheckout = false
  }
  
  if(stipeCheckout){
    setTimeout(()=>{
      localStorage.removeItem('stipecheckout')
    },1500)
  }

  useEffect(() => {}, [user]);

  return (
    <Link to='/buyTickets' className={isMobile ? 'header-tickets-mobile-wrapper' : null}>
      <div className={`${isMobile ? 'header-tickets-mobile' : 'header-tickets'} ${paddingClass} ${user.tokens === 0 ? 'tickets-red' : null} ${stipeCheckout ? 'first-tokens' : null}`} style={{ backgroundImage: `url(${Ticket})` }}>
        {user.tokens}
      </div>
    </Link>
  );
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps)(HeaderTickets);
