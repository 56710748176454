import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { setOverlay, setQuestionsDelay, isQuestionTyping, stopTyping,
        renderTypewriter, setTypewriterState, showAnswers } from '../../../actions/userExperienceActions';
import { setToStart, isLeaveStoryModalOpen } from '../../../actions/userInteractionActions';
import { isNextBtnNonActive, setImgsToNull } from '../../../actions/currentStoryActions';

import arrow from '../../../assets/images/Fill 1.png';

import './LeaveStoryModal.css';


const LeaveStoryModal = ({ userInteractionProps, setToStart, setOverlay, isLeaveStoryModalOpen, renderTypewriter, 
                    isNextBtnNonActive, setQuestionsDelay, isQuestionTyping, stopTyping, setImgsToNull, setTypewriterState, showAnswers }) => {

  const history = useHistory();

  const { leaveStoryModalOpen, href } = userInteractionProps;

  function onClickYes() {
    showAnswers(true);
    setTypewriterState(0);
    setToStart();
    setImgsToNull();
    stopTyping(false);
    isNextBtnNonActive(true);
    setQuestionsDelay(true);
    isQuestionTyping(true);
    renderTypewriter(false);
    history.push(`/${href}`);
  }

  function onClickNo() {
    isLeaveStoryModalOpen(false);
  }


  const modal = leaveStoryModalOpen ? (
    <div className='modal' id='leaveStoryModal' aria-modal="true" role="dialog" style={{'display': 'block'}}>
      <div className='modal-dialog modal-xl modal-dialog-centered'>
        <div className='modal-content'>
          <h1 className='modal-h1'>Leave Story?</h1>
          <p className='modal-p'>(All progress will be lost)</p>
          <img src={arrow} className='arrow mt-5' alt=''/>
          <div className='profileDeleteModalBtns'>
            <button className='modal-button mr-5' data-dismiss='modal'onClick={()=> onClickNo()}>No</button>
            <button className='modal-button'  data-dismiss='modal' onClick={()=> onClickYes()}>Yes</button>
          </div>
        </div>
      </div>
    </div>
  ): null;

  return modal;
}

const mapStateToProps = state => ({
  userInteractionProps: state.userInteractionState
});

export default connect(mapStateToProps, { setToStart, setOverlay,
                      isLeaveStoryModalOpen, setImgsToNull, renderTypewriter,
                      isNextBtnNonActive, setQuestionsDelay, isQuestionTyping,
                      stopTyping, setTypewriterState, showAnswers })(LeaveStoryModal);
