import React from 'react';
import { connect } from 'react-redux';

import { isMobileNavOpen } from '../../../actions/currentStoryActions';

import burger from '../../../assets/icons/sandwich.png';

import './SlidesHamButton.css';

const SlidesHamButton = ({ open, isMobileNavOpen }) => {

  const onClick = () => {
    isMobileNavOpen(!open)
  }

  return (
    <button className={`ham ${open ? 'showClose' : ''}`} onClick={onClick} >
      {open ? null : <img src={burger} alt='' />}
    </button>
  );
}

const mapStateToProps = state => ({
  open: state.currentStoryState.open
});

export default connect(mapStateToProps, { isMobileNavOpen })(SlidesHamButton);
