import { useState, useEffect } from 'react';

const useWindowSize = () => {

  const getSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
      setWindowSize(getSize());
  }, []);
  
  return windowSize;

};

export default useWindowSize;
