import * as actionTypes from './types';


export const addError = error => {
  return {
    type: actionTypes.ADD_ERROR,
    payload: error
  }
};

export const isLoading = bool => {
  return {
    type: actionTypes.IS_LOADING,
    payload: bool
  }
};

export const addSignUpPageError = error => {
  return {
    type: actionTypes.SIGN_UP_PAGE_ERROR,
    payload: error
  }
};

export const isSignUpPageLoading = bool => {
  return {
    type: actionTypes.SIGN_UP_PAGE_LOADING,
    payload: bool
  }
};

export const setNewUser = bool => {
  return {
    type: actionTypes.NEW_USER,
    payload: bool
  }
}

export const successfulRegistation =  bool => {
  return {
    type: actionTypes.SUCCESSFUL_REGISTRATION,
    payload: bool
  }
}
