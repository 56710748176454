const data = [
  {
    question: "Why can't I find App in Google Play Store or Apple App Store?",
    answer: 'Due to adult nature of the content it is not possible to deliver this as a native app, therefore it was build as Progressive Web App (PWA), which you can download and save to your homescreen like a native app.'
  },
  {
    question: 'How can I download the App?',
    answer: 'The "App download" icon is available on mobile devices and will display a short video with simple 2 click process. On iOS devices only Safari browser can be used to save App to home screen.'
  },
  {
    question: 'Can I submit ideas for future development?',
    answer: 'Of course, ideas and improvements are always welcome and can be emailed to info@myhumblestory.com'
  },
  {
    question: 'Can you notify me when the new story is published?',
    answer: 'For the time being please follow us Facebook and Twitter for latest updates on the new stories. As soon as this feature is available we will notify you.'
  },
  {
    question: 'Do you use any 3rd party cookies or share any information with advertising agencies?',
    answer: 'Absolutely not, the only cookies we use are the ones needed to successfully run the App, save progress, prevent you from signing in every time and so on.'
  },
  {
    question: 'Do you track my location?',
    answer: 'The App checks the country you connect from to provide appropriate currency during token purchase stage. If you do not grant permission, it will default to USD.'
  },
  {
    question: 'Why is only USD available?',
    answer: "During purchase stage the App with look at your location and determine the currency to use. If you didn't grant such permission it will default to USD. If your currency is not one of the available options, it will default to USD."
  },
  {
    question: 'Does deleting my account delete any unused tokens?',
    answer: 'Yes, during account deletion all information that was previously stored is removed. Any unused tokens are also deleted and are not refunded.'
  },
  {
    question: 'If I login with Facebook will anything be posted on my feed?',
    answer: 'Absolutely not, Facebook and Google logins are only used to give you access without having to create and remember a new password. We do not store your password. Therefore this is the recommended and simplest way to sign in.'
  },
  {
    question: 'I have a question which is not answered here?',
    answer: 'Please feel free to email us on info@myhumblestory.com with any further questions and we will reply at the earliest possible convenience.'
  },
];

export default data;
