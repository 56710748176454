import React, { useEffect } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import useWindowSize from '../../hooks/useWindowSize';
import { useAuth } from '../../context/AuthContext';
import { useFirestore } from '../../context/FirestoreContext';
import { changePageNum, isNextBtnNonActive, setImgsToNull } from '../../actions/currentStoryActions';
import { setToStart, setLastCompletedStory } from '../../actions/userInteractionActions';
import { setQuestionsDelay, isQuestionTyping, stopTyping, renderTypewriter, setTypewriterState, showAnswers, isTypeWriterMounted } from '../../actions/userExperienceActions';
import { addFeedbackPath, isFeedbackClicked } from '../../actions/homeRouteActions';
import { addToLocalCompletedStory } from '../../actions/userInteractionActions';

import SlidesOverlay  from '../Slides/slidesOverlay/SlidesOverlay';

import DesktopImg from '../../assets/images/The_End_Desktop.svg';
import MobileImg from '../../assets/images/The_End_Mobile.svg';

import './index.css';


const TheEnd = ({ openStories, num, changePageNum, setToStart, setImgsToNull, overlay, setLastCompletedStory,
                  addFeedbackPath, isFeedbackClicked, setQuestionsDelay, isQuestionTyping, renderTypewriter,
                  stopTyping, isNextBtnNonActive, addToLocalCompletedStory, setTypewriterState, showAnswers,
                  isTypeWriterMounted }) => {

    const params = useParams();
    const history = useHistory();
    const { width } = useWindowSize();
    const { currentUser } = useAuth();
    const { addToCompletedStories } = useFirestore();

    useEffect(() => {
      isTypeWriterMounted(false);
      setLastCompletedStory(openStories[openStories.length - 1]);
      stopTyping(false);
      isNextBtnNonActive(true);
      setQuestionsDelay(true);
      isQuestionTyping(true);
      renderTypewriter(false);

      if (!currentUser) {
        addFeedbackPath(params.id);
      }

      changePageNum(0);
      showAnswers(true);
      setTypewriterState(0);
      setToStart();
      setImgsToNull();
      addToCompletedStories(currentUser, params.id);
      addToLocalCompletedStory(params.id);
    }, []);

    function onLeaveFeedbaclClick () {
      if (!currentUser) {
        isFeedbackClicked(true);
      }
      history.push(`/feedback/${params.id}`)
    }

    function onNewStoryClick () {
      if (!currentUser) {
        history.push('/login');
      } else {
        history.push('/');
        localStorage.removeItem('localCompletedStory');
      }
    }

    return (
      <div className='overlayWrapper' style={{backgroundColor: 'black'}}>
        <SlidesOverlay />
        <div className='theEnd'>
          <SlidesOverlay />
          <div className='theEndImgWrapper'>
            <img src={width > 625 ? DesktopImg : MobileImg } alt=''/>
          </div>
          <p className='theEndText big'>We hope you enjoyed the experience</p>
          <p className='theEndText small'>Please leave any comments, thoughts, suggestions in the feedback section, they are always welcome.</p>
          <div className='theEndBtnscontainer'>
            <button className='continueButton' onClick={()=> onLeaveFeedbaclClick()}>Leave Feedback</button>
            <button className='continueButton' onClick={() => onNewStoryClick()}>Stories</button>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = state => ({
  num: state.currentStoryState.num,
  overlay: state.userExperienceState.overlay,
  openStories: state.storiesPageState.openStories
});

export default connect(mapStateToProps, { changePageNum, setToStart, isTypeWriterMounted,
                      setImgsToNull, renderTypewriter, addFeedbackPath, setLastCompletedStory,
                      isFeedbackClicked, setQuestionsDelay, isQuestionTyping, stopTyping,
                      isNextBtnNonActive, addToLocalCompletedStory, setTypewriterState, showAnswers })(TheEnd);
