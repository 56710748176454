import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import { connect } from 'react-redux';

import Xarrow from "react-xarrows";

import * as timeOutsConstants from '../../constants/timeOutsConstants';

import { getResizedBgrImgAction, isContinueModalOpen } from '../../actions/currentStoryActions';
import { addStoryInteractionData, isLeaveStoryModalOpen } from '../../actions/userInteractionActions';
import { renderTypewriter } from '../../actions/userExperienceActions';
import { setNewUser } from '../../actions/registrationActions';
import { fromLoginPage, isNonEmailVerified } from '../../actions/authActions';
import { redirectToCheckout } from '../../actions/buyingTicketsActions';

import { useFirestore } from '../../context/FirestoreContext';
import useWindowSize from '../../hooks/useWindowSize';

import PaidAnswersModal from './slidesButtons/slidesButtonPaid/PaidAnswersModal';
import EducationalOverlay  from './educationalOverlay/EducationalOverlay';
import SlidesOverlay from './slidesOverlay/SlidesOverlay';
import SlidesContinueModal from './slidesContinueModal/SlidesContinueModal';
import QuestionsHeader from '../../components/mainLayout/questionsHeader';
import QuestionsFooter from '../../components/mainLayout/questionsFooter';
import SlidesNav from './slidesNav/SlidesNav';
import SlidesBigImage from './slidesBigImage/SlidesBigImage';
import SlidesHamButton from './slidesHamButton/SlidesHamButton';
import SlidesModal from './slidesModal/SlidesModal';
import SlidesAdminButtons from './slidesAdminButtons/SlidesAdminButtons';
import SlidesMainContentQuestions from './slidesMainContentQuestions/SlidesMainContentQuestions';

import './Slides.css';


const Slides = ({ openStories, fromCheckout, redirectToCheckout, redirectToVerifyEmail,
                  isNonEmailVerified, setNewUser, fromLoginPage, fromLogin, isLeaveStoryModalOpen,
                  getAnswers, currentStoryProps, userInteractionProps, isContinueModalOpen,
                  renderTypewriter, educationalOverlay, getResizedBgrImgAction, overlay,
                  addStoryInteractionData, userExperienceProps, newUser }) => {

  const { stories, updateStoryMode, getResizedBgrImg } = useFirestore();

  const { width } = useWindowSize();

  const params = useParams();

  const { bgrImg, num } = currentStoryProps;
  const { typeWriter } = userExperienceProps;
  const { storyId } = userInteractionProps;

  let story = stories.find(item => item.id === params.id);
  const text = story.description.split('/n');

  useEffect(() => {
    function isMainStory() {
      if ((storyId === '' || newUser || fromLogin || redirectToVerifyEmail || fromCheckout)) {
        isContinueModalOpen(false);
      } else {
        isContinueModalOpen(true);
      }
    }

    isMainStory();

    setNewUser(false);
    fromLoginPage(false);
    isNonEmailVerified(false);
    redirectToCheckout(false);
    localStorage.removeItem('fromCheckout');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });


  useEffect(() => {
    let screenSize;
    if (width <= 425) {
      screenSize = 0;
    } else if (width <= 768) {
      screenSize = 1;
    } else {
      screenSize = 2;
    }
    getResizedBgrImgAction(story, getResizedBgrImg, screenSize);
  }, [story]);

  useEffect(() => {
    const localStoryId = story.id;
    const mainStoryId = story.mainId === undefined ? story.id : story.mainId;
    const isMain = localStoryId === mainStoryId;
    if (!storyId) {
      addStoryInteractionData(localStoryId, mainStoryId, isMain, num);
    }
    localStorage.setItem("currentStoryId",story.id)
  }, [num, story.id]);

  async function editMode(e) {
    e.preventDefault();
    if(story.mode === 'Dark') {
      await updateStoryMode(story.id, 'Default');
    } else {
      await updateStoryMode(story.id, 'Dark');
    }
  }

  useEffect(()=> {
    if (num === 0) {

      setTimeout(function() {
        renderTypewriter(true);
      }, timeOutsConstants.CHANGE_TYPEWRITER_STATE_DELAY);
    } else {
      renderTypewriter(true);
    }
  }, []);

  const localBgrImg = localStorage.getItem(`bgrImg-${story.id}`);
  const fullContentWrapperClass = (width > 768) ? 'blackFullContentWrapper' : (story.mode === 'Dark' ? 'blueFullContentWrapper' : 'whiteFullContentWrapper');
  const toggleOverlay = (!!text[num + 1] || !!story.question) ? overlay : false; // if it is the end of the story, then use overlay immediately

  return (
    <React.Fragment>
    <div className='overlayWrapper'>
      <SlidesContinueModal story={story} />
      {/* <EducationalOverlay /> */}
      <SlidesOverlay />
      <div className={`fullContentWrapper ${fullContentWrapperClass}`} style={{ 
        backgroundImage: `url(${localBgrImg ? localBgrImg : (bgrImg ? bgrImg : '')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
        }}>
        <QuestionsHeader />
          <SlidesHamButton />
          {window.innerWidth>=650 ? <SlidesBigImage story={story} /> : null}
          <SlidesNav />
          <SlidesAdminButtons story={story} editMode={editMode} text={text} />
          <SlidesModal />
          {!typeWriter || toggleOverlay ? null :
            <React.Fragment>
              <SlidesMainContentQuestions text={text} />
              {story.audio ? <ReactAudioPlayer src={story.audio} id='storyAudio' autoPlay controls loop  volume={0.05}
                            onAbort={() => params.id !== story.id} className='d-none' /> : null}
            </React.Fragment>
          }
          {/* {educationalOverlay ? <div className='xarrowWrapper' style={{ zIndex: 99 }}>
            <Xarrow
              start='headerTickets'
              end='paidBtnSelected'
              color='#f4cc31'
              dashness={true}
              curveness={1.5}
              strokeWidth={2.5}
              headSize={5}
            />
        </div> : null} */}
        <PaidAnswersModal />
      </div>
    <QuestionsFooter story={story} text={text} />
    </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  newUser: state.registrationState.newUser,
  fromLogin: state.authState.fromLogin,
  currentStoryProps: state.currentStoryState,
  userInteractionProps: state.userInteractionState,
  userExperienceProps: state.userExperienceState,
  educationalOverlay: state.buyingTicketsState.educationalOverlay,
  redirectToVerifyEmail: state.authState.redirectToVerifyEmail,
  fromCheckout: state.buyingTicketsState.fromCheckout,
  openStories: state.storiesPageState.openStories,
  overlay: state.userExperienceState.overlay
});

export default connect(mapStateToProps, { redirectToCheckout, isNonEmailVerified,
                        getResizedBgrImgAction, setNewUser, fromLoginPage,
                        addStoryInteractionData, isContinueModalOpen, isLeaveStoryModalOpen,
                        renderTypewriter })(Slides);
