import React, {useRef} from 'react';
import { useHistory } from "react-router-dom";

import { useAuth } from '../../context/AuthContext';

import logo from '../../essentials/logo.svg';

import './index.css';


const ChangePassword = () => {

  const oldPassRef = useRef();
  const newPassRef = useRef();
  const repPassRef = useRef();

  const { changePassword,checkOldPassword } = useAuth();

  const history = useHistory();

  async function onPasswordChange() {
    if(newPassRef.current.value.length < 6){
      return alert('password should have 6 symbols')
    }
    if(newPassRef.current.value !== repPassRef.current.value) {
      return alert('repeat password right');
    } else {
      const isOldPasswordCorrect = await checkOldPassword(oldPassRef.current.value)
      console.log(isOldPasswordCorrect)
      if (isOldPasswordCorrect) {
        await changePassword(newPassRef.current.value);
        history.push('/profile');
      } else{
        return alert('old password incorect')
      }
    }
  }

  return (
    <React.Fragment>
      <div className='page-containerLogin'>
        <div className='containerLogin loginPage'>
          <div className='form-headerReset'>
            <div>
              <img src={logo} alt='' className='profile-imagelogin' />
            </div>
          </div>
          <div className='form-bodyLogin'>
            <h1 className='reset'>Old password</h1>
            <input type='password' placeholder='Old password' className='form-inpu' ref={oldPassRef}/>
          </div>
          <div className='form-bodyLogin'>
            <h1 className='reset'>New password</h1>
            <input type='password' placeholder='New password' className='form-inpu' ref={newPassRef}/>
          </div>
          <div className='form-bodyLogin'>
            <h1 className='reset'>Repeat New Password</h1>
            <input type='password' placeholder='New password' className='form-inpu' ref={repPassRef}/>
          </div>
          <div className='container-headerReset'>
          <button className='upload-bt' onClick={onPasswordChange}>Submit</button>
        </div>
        </div>
      </div>
    </React.Fragment>
  );

};

export default ChangePassword;
