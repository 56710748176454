import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useFirestore } from '../../context/FirestoreContext';

import './commitDeletionPage.css';


const CommitDeletionPage = () => {

  const params = useParams();
  const history = useHistory();

  const { commits, deleteCommitById } = useFirestore();

  let commit = commits.find(item => item.id === params.id);

  function deleteCommit(commitId, rating, storyId) {
    deleteCommitById(commitId, rating, storyId);
    history.push('/');
  }

  return (
    <div className='commitDeletionPage'>
      <span>Are you sure you want to delete review with id: <b>{params.id}</b> ?</span>
    <button type='button' className='deleteBtn' onClick={() => deleteCommit(params.id, commit.rating, commit.storyId)}>
        Delete
      </button>
    </div>
  );

};

export default CommitDeletionPage;
