import React, { useContext, useEffect, useCallback, useState } from 'react';
import app, { db } from '../firebase';

import { useSelector, useDispatch } from 'react-redux';
import { fetchStories, createStories, createCommit, deleteStories, leadingData } from '../actions/firebaseActions';

import Loading from '../components/loading/Loading';

const FirestoreContext = React.createContext();

export function useFirestore() {
    return useContext(FirestoreContext)
}


export function FirestoreProvider({ children }) {

    const state = useSelector(state => state.firebaseState);
    const dispatch = useDispatch();
    const fetch = useCallback((story) => dispatch(fetchStories(story)), [dispatch]);
    const actionCreate = useCallback((story) => dispatch(createStories(story)), [dispatch]);
    const deleteStoryAction = useCallback((story) => dispatch(deleteStories(story)), [dispatch]);
    const setLoading = useCallback((loading) => dispatch(leadingData(loading)), [dispatch]);
    const addCommit = useCallback((commit) => dispatch(createCommit(commit)), [dispatch]);
    const [stories, setStories] = useState();

    async function createWay(parentId, body, ways) {

      const curStoriesAmount = await db.collection('helper_data').doc('stories_amount');
      const getCurStoriesAmount = await curStoriesAmount.get();
      const numObj = await getCurStoriesAmount.data();
      const num = numObj.num;

      if (parentId === 'main') {
        body.index = num;
        await curStoriesAmount.update({num: num + 1});
      }
      const data = await db.collection('stories').add(
        {
          parentId,
          ...body,
          ways
        });

      fetchData();
      return data.id
    }

    async function updateParent(parentId, body) {
      const data = await db.collection('stories').doc(parentId).update({ ...body });
      return fetchData();
    }
    async function update(id, body) {
      const data = await db.collection('stories').doc(id).update({ ...body });
      return fetchData();
    }
    async function updateParentWays(parentId, ways) {
      await db.collection('stories').doc(parentId).update({ ways });
      return fetchData();
    }
    async function updateTitle(id, newTitle) {
      await db.collection('stories').doc(id).update({ title: newTitle });
    }

    async function updateList(id, list) {
      await db.collection('stories').doc(id).update({ list });
      return fetchData();
    }

    function regular(string) {
      const reg = /([^/]*)$/;
      const reg2 = /^([^?]+)/;
      const result = reg.exec(string);
      return reg2.exec(result);
    }

    async function deleteUserF(email){
        var query = db.collection('users').where('email','==',email);
        query.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
        doc.ref.delete();
        });
      });
    }

    async function updateStoryMode(id, newMode) {
      await db.collection('stories').doc(id).update({ mode: newMode });
      return fetchData();
    }

    async function updateStoryPictures(id, smallPicture,bigPicture) {
      await db.collection('stories').doc(id).update({ smpict: smallPicture });
      await db.collection('stories').doc(id).update({ bgrImg:bigPicture });
      return fetchData();
    }

    async function deleteStory(id) {

      const curStory = await db.collection('stories').doc(id).get();
      const curStoryIndex = await curStory.data().index;

      const stories = await db.collection('stories').get();
      const storiesData = stories.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      let mainStories = [];
      storiesData.map(story => {
        if (story.parentId === 'main') {
          mainStories.push(story);
        }
      });

      mainStories.map( async (story) => {
        if (story.index > curStoryIndex) {
          await db.collection('stories').doc(story.id).update({index: (story.index - 1)});
        }
      });


      const story = state.stories.find(item => item.id === id)
      const storageRef = app.storage().ref();

      const curStoriesAmount = await db.collection('helper_data').doc('stories_amount');
      const getCurStoriesAmount = await curStoriesAmount.get();
      const numObj = await getCurStoriesAmount.data();
      const num = numObj.num;
      await curStoriesAmount.update({num: num - 1});

      // thumbnails links
      const pathReference = storageRef.child('thumbnails');

      const bgrImgName = regular(story.bgrImg);
      const smpictName = regular(story.smpict);
      const imgName = regular(story.img);

      const bgrImgsLinks =[`${bgrImgName[0]}_728x425`, `${bgrImgName[0]}_1700x768`, `${bgrImgName[0]}_1920x862`];
      const smpictsLinks = [`${smpictName[0]}_728x425`, `${smpictName[0]}_1700x768`, `${smpictName[0]}_1920x862`];
      const imgsLinks = [`${imgName[0]}_728x425`, `${imgName[0]}_1700x768`, `${imgName[0]}_1920x862`];

      if (story.parentId === 'main') {
        const image = regular(story.img);
        const images = storageRef.child(image[0]);
        await images.delete();
      }
      const picture = regular(story.bgrImg);
      let audio = null
      if (story.audio) {
        audio = regular(story.audio);
      }
      const smpict = regular(story.smpict);

      bgrImgsLinks.map(async (link) => {
        let pic = pathReference.child(link);
        await pic.delete();
      });

      smpictsLinks.map(async (link) => {
        let pic = pathReference.child(link);
        await pic.delete();
      });

      imgsLinks.map(async (link) => {
        let pic = pathReference.child(link);
        await pic.delete();
      });

      const smpicture = storageRef.child(smpict[0]);
      const pictures = storageRef.child(picture[0]);
      let audios = null
      if (audio) {
        audios = storageRef.child(audio[0]);
      }
      await smpicture.delete();
      await pictures.delete();
      if (audios) {
        await audios.delete();
      }

      await db.collection('stories').doc(id).delete();
      await db.collection('commits').where('storyId', '==', id).get().then((commits) => {
              commits.forEach(async (commit) => {
                  await db.collection('commits').doc(commit.id).delete();
              });
          })
            .catch((error) => {
                console.log('Error getting documents: ', error);
            });
        deleteStoryAction(story);

        if (story.list) {
          story.list.map(async item => {
            const picture1 = regular(item.bgrImg);
            const picture2 = regular(item.smpict);
            let audio1 = null;
            if (item.audio) {
              audio1 = regular(item.audio);
            }

            // thumbnails links
            const bgrImgName1 = regular(item.bgrImg);
            const smpictName1 = regular(item.smpict);

            const bgrImgsLinks1 =[`${bgrImgName1[0]}_728x425`, `${bgrImgName1[0]}_1700x768`, `${bgrImgName1[0]}_1920x768`];
            const smpictsLinks1 = [`${smpictName1[0]}_728x425`, `${smpictName1[0]}_1700x768`, `${smpictName1[0]}_1920x862`];

            bgrImgsLinks1.map(async (link) => {
              let pic = pathReference.child(link);
              await pic.delete();
            });

            smpictsLinks1.map(async (link) => {
              let pic = pathReference.child(link);
              await pic.delete();
            });

            const pictures1 = storageRef.child(picture1[0]);
            const pictures2 = storageRef.child(picture2[0]);
            let audios1;
            if (audio1) {
              audios1 = storageRef.child(audio1[0]);
            }
            await pictures1.delete();
            await pictures2.delete();
            if (audios1) {
              await audios1.delete();
            }
            await db.collection('stories').doc(item.storyId).delete()
          })
        }

      fetchData();
    }

    async function saveAudio(data) {
      if(data.audio[0]) {
        const storageRef = app.storage().ref();
        const name = data.audio[0].name + Date.now();
        const fileRef = storageRef.child(name);
        await fileRef.put(data.audio[0])
        const aud = await fileRef.getDownloadURL();
        return aud;
      }
      const aud = null;
      return aud;
    }

    async function downloadImages(data) {
      const storageRef = app.storage().ref();
      const name = data.picture[0].name + Date.now();
      const fileRef = storageRef.child(name);
      await fileRef.put(data.picture[0])
      const img = await fileRef.getDownloadURL();
      return img;
    }

    function resizeImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function(e) {
          const img = new Image();
          img.onload = function() {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = 100;
            canvas.height = 100;
            ctx.drawImage(img, 0, 0, 100, 100);
            canvas.toBlob((blob) => {
              resolve(blob);
            }, 'image/jpeg');
          }
          img.src = e.target.result;
        }
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    } 

    const uploadAvatar = async (data, current) => {
      const storageRef = app.storage().ref();
      const name = data.image[0].name + Date.now();
      const resizedAvatar = await resizeImage(data.image[0])
      const fileRef = storageRef.child(name);
      await fileRef.put(resizedAvatar)
      const image = await fileRef.getDownloadURL();
      db.collection('users').doc(current.uid).update({avatar: image})
      return image;
    };

    async function saveSmPict(data) {
      const storageRef = app.storage().ref();
      const name = data.smpict[0].name + Date.now();
      const fileRef = storageRef.child(name);
      await fileRef.put(data.smpict[0])
      const smpict = await fileRef.getDownloadURL();
      return smpict;
    }

    async function downloadImageBgr(data) {
      const storageRef = app.storage().ref();
      const name = data.image[0].name + Date.now();
      const fileRef = storageRef.child(name);
      await fileRef.put(data.image[0])
      const img = await fileRef.getDownloadURL();
      return img;
    }

    async function updateImg(data, img) {
      const name = await downloadImages(data);
      const storageRef = app.storage().ref();
      const image = regular(img);
      const images = storageRef.child(image[0]);
      await images.delete();
      return name;
    }

    async function updateBgr(data, bgr) {
      const name = await downloadImageBgr(data);
      const storageRef = app.storage().ref();
      const image = regular(bgr);
      const images = storageRef.child(image[0]);
      await images.delete();
      return name;
    }

    async function updateSmPic(data, smpic) {
      const name = await saveSmPict(data);
      const storageRef = app.storage().ref();
      const image = regular(smpic);
      const images = storageRef.child(image[0]);
      await images.delete();
      return name;
    }

    async function updateAudio(data, audio) {
      const name = await saveAudio(data);
      const storageRef = app.storage().ref();
      const image = regular(audio);
      const images = storageRef.child(image[0]);
      await images.delete();
      return name;
    }

    async function getResizedStoryImg(img) {
      if (navigator.onLine) {
        const storageRef = app.storage().ref();
        const pathReference = await storageRef.child('thumbnails');
        let downloadLink
        try {
          downloadLink = await pathReference.child(`${img}_728x425`).getDownloadURL();
        } catch {
          
        } 
        let downloadLinkImg
        if (downloadLink) {
          downloadLinkImg = downloadLink;
        }
        if(navigator.serviceWorker) {
          navigator.serviceWorker.ready.then( registration => {
            registration.active.postMessage({urls: `${downloadLinkImg}`});
          });
        }
        return downloadLinkImg;
      }
    }

    async function getResizedBgrImg(img, screenSize) {

      if (navigator.onLine) {
        const storageRef = app.storage().ref();
        const pathReference = await storageRef.child('thumbnails');

        let downloadLink;

        if (screenSize === 0) {
          let downloadLinkS
          try {
            downloadLinkS = await pathReference.child(`${img}_728x425`).getDownloadURL();
          } catch {
            
          } 
          if (downloadLinkS) {
            downloadLink = downloadLinkS;
          } 
        } else if (screenSize === 1) {
          let downloadLinkM 
          try {
            downloadLinkM = await pathReference.child(`${img}_1700x768`).getDownloadURL();
          } catch {
            
          } 

          if (downloadLinkM) {
            downloadLink = downloadLinkM;
          } 
        } else if (screenSize === 2) {

          let downloadLinkL
          try {
            downloadLinkL = await pathReference.child(`${img}_1920x862`).getDownloadURL();
          } catch {
            
          } 

          if (downloadLinkL) {
            downloadLink = downloadLinkL;
          } 
        }
        if (navigator.serviceWorker) {
          navigator.serviceWorker.ready.then( registration => {
            registration.active.postMessage({urls: `${downloadLink}`});
          });
        }
        return downloadLink;
      }

    }

    async function getResizedSmpict(img, screenSize) {

      if (navigator.onLine) {
        const storageRef = app.storage().ref();
        const pathReference = await storageRef.child('thumbnails');

        let downloadLink;

        if (screenSize === 0) {
          let downloadLinkS 
          try {
            downloadLinkS = await pathReference.child(`${img}_728x425`).getDownloadURL();
          } catch {
            
          } 
          if (downloadLinkS) {
            downloadLink = downloadLinkS;
          } 
        } else if (screenSize === 1) {
          let downloadLinkM

          try {
            downloadLinkM = await pathReference.child(`${img}_1700x768`).getDownloadURL();
          } catch {
            
          } 

          if (downloadLinkM) {
            downloadLink = downloadLinkM;
          } 
        } else if (screenSize === 2) {
          let downloadLinkL
          try {
            downloadLinkL = await pathReference.child(`${img}_1920x862`).getDownloadURL();
          } catch {
            
          } 

          if (downloadLinkL) {
            downloadLink = downloadLinkL;
          } 
        }
        if (navigator.serviceWorker) {
          navigator.serviceWorker.ready.then( registration => {
            registration.active.postMessage({urls: `${downloadLink}`});
          });
        }
        return downloadLink;
      }

    }


    const onPublish = async (id, published) => {
      await db.collection('stories').doc(id).update({ published })
      return fetchData();
    }

    const onCheckBox = async (id, array, item) => {
      const way = array.filter(doc => doc.value !== item.value);
      await db.collection('stories').doc(id).update({ ways: [item, ...way] })
      return fetchData();
    }

    const fetchData = useCallback(async () => {
      const data = await db.collection('stories').get();
      const story = data.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      fetch(story);
      setStories(story)
      return story;
    }, [fetch])

    const fetchCommits = useCallback(async () => {
      const data = await db.collection('commits').get();
      const commit = data.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      addCommit(commit);

      return commit;
    }, [addCommit, stories])

    useEffect(() => {
      fetchData();
      fetchCommits();
    }, []);

    async function onCommitSubmit(storyId, rating, commit, userId, userEmail, userAvatar) {
      const story = state.stories.find(item=> item.id === storyId);
      if(story.rating){
        const stars = (story.rating*story.numberReviews + rating) / (story.numberReviews + 1);
        const numberReviews = story.numberReviews + 1;
        await db.collection('stories').doc(storyId).update({rating: stars, numberReviews: numberReviews})
      }else {
        await db.collection('stories').doc(storyId).update({rating, numberReviews: 1})
      }
      db.collection('commits').add(
        {
          storyId,
          userId,
          rating,
          commit,
          userEmail,
          userAvatar
        });

        const updateState = () => {
          fetchData();
          fetchCommits();
        };

        return updateState();
    }

    async function getCommitInfo(storyId, rating, commit, userId) {

      let currentCommit;

      const commitsRef = await db.collection('commits');
      const commitQuery = commitsRef
                          .where('storyId', '==', storyId)
                          .where('rating', '==', rating)
                          .where('commit', '==', commit)
                          .where('userId', '==', userId);

      await commitQuery.get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              let id = doc.id;
              currentCommit = id;
            });
          })
        .catch((error) => {
            console.log('Error getting documents: ', error);
        });

        return currentCommit;

    }

    async function deleteCommitById(commitId, commitRating, storyId) {

      const allStars = await db.collection('commits').where('storyId', '==', storyId);

      let rating = 0;
      let amount = 0;

      await allStars.get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              let curRating = await doc.data().rating;
              rating = rating + curRating;
              amount = amount + 1;
            });
          })
        .catch((error) => {
            console.log('Error getting documents: ', error);
        });

      amount = await amount - 1;
      rating = await rating - commitRating;
      const averageRating = await rating / amount;

      await db.collection('stories').doc(storyId).update({rating: averageRating, numberReviews: amount});



      const commitRef = await db.collection('commits').doc(commitId);

      commitRef.get().then((doc) => {
        if (doc.exists) {
            doc.ref.delete();
            console.log('commit deleted');
        } else {
            console.log('No such document!');
        }
      }).catch((error) => {
          console.log('Error getting document:', error);
      });

    }

    async function addToCompletedStories (current, storyId) {
      if (current) {
        let completedStories;
        const query = await db.collection('users').doc(current.uid);
        await query.get().then((value) =>  completedStories = value.data().completedStories);
        if (completedStories) {
          const isCompleted = await completedStories.findIndex(element => element === storyId);
          if (isCompleted === -1) {
            await db.collection('users').doc(current.uid).update({ completedStories: [...completedStories, storyId] });
          }
        } else {
          await db.collection('users').doc(current.uid).update({ completedStories: [storyId] });
        }
      }
    }

    async function getAllUsers() {
        const users = [];
        var query = db.collection('users');
        await query.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          users.push({
            'id': doc.id,
            'email': doc.data().email,
            'avatar': doc.data().avatar
          })
        });
      });
      return users;
    }

    const fetchPrices = async () => {
      const data = await db.collection('price_obj').get();
      const ticketsPrice = data.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return ticketsPrice;
    };

    const rememberPaidAnswer = async (userId, answer) => {

      const user = await db.collection('users').doc(userId).get();
      const userData = await user.data();

      let paidAnswers = [];
      if(userData.paidAnswers) {
        paidAnswers = await userData.paidAnswers;
      }
      paidAnswers.push(answer);
      await db.collection('users').doc(userId).update({
        paidAnswers: paidAnswers
      });

    };

    const buyAnswer = async (userId, answerPrice) => {

      const user = await db.collection('users').doc(userId).get();
      const userData = await user.data();
      const userTokens = await userData.tokens;
      const newTokensAmount = parseInt(userTokens) - parseInt(answerPrice);
      if (newTokensAmount >= 0) {
        await db.collection('users').doc(userId).set({
          ...userData,
          tokens: newTokensAmount
        });
      }
    };

    const deleteUserAvatar = async (userId, avatar) => {

        const storageRef = await app.storage().ref();
        const pathReference = await storageRef.child('thumbnails');

        const avatarName = regular(avatar);
        const mainImgRef = await storageRef.child(avatarName[0]);
        await mainImgRef.delete();
        try {
          const resized425 = `${avatarName[0]}_728x425`;
          const resized768 = `${avatarName[0]}_1700x768`;
          const resized1440 = `${avatarName[0]}_1920x862`;

          
          const refTo425 = await pathReference.child(resized425);
          const refTo768 = await pathReference.child(resized768);
          const refTo1440 = await pathReference.child(resized1440);

          
          await refTo425.delete();
          await refTo768.delete();
          await refTo1440.delete();
        } catch(e){

      }
        await db.collection('users').doc(userId).update({ avatar: '' });
    };


    const value = {
        stories: state.stories,
        commits: state.commits,
        loading: state.filesLoading,
        state,
        dispatch,
        actionCreate,
        createWay,
        updateParent,
        downloadImages,
        downloadImageBgr,
        setLoading,
        saveAudio,
        saveSmPict,
        updateStoryMode,
        updateStoryPictures,
        deleteStory,
        updateParentWays,
        update,
        updateTitle,
        updateList,
        onPublish,
        onCheckBox,
        updateImg,
        updateBgr,
        updateSmPic,
        updateAudio,
        getResizedStoryImg,
        getResizedBgrImg,
        getResizedSmpict,
        onCommitSubmit,
        getCommitInfo,
        deleteCommitById,
        deleteUserF,
        uploadAvatar,
        addToCompletedStories,
        getAllUsers,
        fetchPrices,
        rememberPaidAnswer,
        buyAnswer,
        deleteUserAvatar
    };
    
    return (
        <FirestoreContext.Provider value={value}>
          {!state.loading ? children : <Loading/>}
        </FirestoreContext.Provider>
    );
}
