import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../context/AuthContext.js';

function CommitDeletionRoute({ component: Component, ...rest }) {

  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={props => {
        return user.admin ? <Component {...props} /> : <Redirect to='/' />
      }}></Route>
  );

}


export default CommitDeletionRoute;
