import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { useAuth } from '../../../context/AuthContext.js';

import { isLeaveStoryModalOpen } from '../../../actions/userInteractionActions';
import LeaveStoryModal from '../../../pages/Slides/leaveStoryModal/LeaveStoryModal';

import Twitter from '../../../assets/images/X.png';
import Fb from '../../../assets/images/meta.png';
import Inst from '../../../assets/images/Inst.png';

import './index.css'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.js';


const Footer = ({ fromCheckout, authProps, newUser, num, homeRouteProps,
                  userInteractionProps, isLeaveStoryModalOpen, newIDPUser  }) => {

  const history = useHistory();
  const { currentUser } = useAuth();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const isLargeScreen = window.innerWidth > 800;
      const hasStoryPath = location.pathname.includes('/story/');
      setIsVisible(isLargeScreen && hasStoryPath);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [location]);

  if (isVisible) {
    return null;
  }

  const { homeSearchStoriesClicked, overEighteen, termsAgree } = homeRouteProps;
  const { fromLogin, redirectToVerifyEmail } = authProps;
  const { storyId } = userInteractionProps;

  const isAllHomeRoutePropsTrue = homeSearchStoriesClicked && overEighteen && termsAgree;
  const canLinksBeShown = (currentUser && !newIDPUser) || isAllHomeRoutePropsTrue;
  const isStoryPage = history.location.pathname.substring(1, 6) === 'story';
  const isStory = storyId !== '';

  function onStoryClick(event) {
    const href = event.target.parentNode.href.split('/')[3];
    if (isStoryPage) {
      event.preventDefault();
      isLeaveStoryModalOpen(true, href);
    }
  }

  return (
    <React.Fragment>
      <footer>
        <div className='footerLeft'>
          <NavLink
            to={isStory && (newUser || fromLogin || redirectToVerifyEmail || fromCheckout) ? `/story/${storyId}` : canLinksBeShown ? '/' : '/home'}
            onClick={(event) => onStoryClick(event)}
            >
            <div className='navLinks navFirst'>Stories</div>
          </NavLink>
          <NavLink to={canLinksBeShown ? '/profile' : '/home'} onClick={(event) => onStoryClick(event)}>
            <div className='navLinks'>Profile</div>
          </NavLink>
          <NavLink to={canLinksBeShown ? '/privacy' : '/home'} onClick={(event) => onStoryClick(event)}>
            <div className='navLinks'>T&C's</div>
          </NavLink>
          <NavLink to={canLinksBeShown ? '/faq' : '/home'} onClick={(event) => onStoryClick(event)}>
            <div className='navLinks'>FAQ</div>
          </NavLink>
        </div>
        <div className='footerRight'>
          <Link className='roundLinks' to='#'>
            <img src={Fb} alt='Facebook'/>
          </Link>
          <Link className='roundLinks' to='#'>
            <img src={Twitter} alt='Twitter'/>
          </Link>
          <Link className='roundLinks' to='#'>
            <img src={Inst} alt='Instagram'/>
          </Link>
        </div>
      </footer>
      <LeaveStoryModal />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  homeRouteProps: state.homeRouteState,
  userInteractionProps: state.userInteractionState,
  authProps: state.authState,
  num: state.currentStoryState.num,
  newUser: state.registrationState.newUser,
  fromCheckout: state.buyingTicketsState.fromCheckout,
  newIDPUser: state.authState.newIDPUser
});

export default connect(mapStateToProps, { isLeaveStoryModalOpen })(Footer);
