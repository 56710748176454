import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { TailSpin } from 'react-loader-spinner'

import * as timeOutsConstants from '../../../../constants/timeOutsConstants';

import { addNewBtn, changeCurrentBtn, isNextBtnNonActive } from '../../../../actions/currentStoryActions';
import { setOverlay, stopTyping, setQuestionsDelay, isQuestionTyping, renderTypewriter, showAnswers, isTypeWriterMounted } from '../../../../actions/userExperienceActions';
import { changeTokensAmount,setEducationalOverlay,isEducationalOverlayBeenShown, setNewPaidAnswer } from '../../../../actions/buyingTicketsActions';
import { setNewUser } from '../../../../actions/registrationActions';
import { isNonEmailVerified } from '../../../../actions/authActions';

import { useAuth } from '../../../../context/AuthContext';
import { useFirestore } from '../../../../context/FirestoreContext';

import PriceToken from '../../../../assets/icons/Price-ticket.svg';

import Save from '../../../../assets/images/save.png';
import Edit from '../../../../assets/images/edit-button.png';

import './SlidesButtonPaid.css';

const SlidesButtonPaid = ({ isNonEmailVerified, setOverlay, currentStoryProps, addNewBtn, changeCurrentBtn,
                            story, renderTypewriter, setEducationalOverlay, newPaidAnswer, showAnswers,
                            item, push, save, handleWaysChange, stopTyping, isNextBtnNonActive, newUser,
                            educationalOverlay, setQuestionsDelay, isQuestionTyping, setNewPaidAnswer,
                            changeTokensAmount, userTokens, isOverlayBeenShown, isEducationalOverlayBeenShown,
                            isTypeWriterMounted }) => {

  const { currentUser, user } = useAuth();
  const { onCheckBox, rememberPaidAnswer, buyAnswer,stories } = useFirestore();
  const [isLoader, setIsLoader] = useState(false)
  const [isClicked,setIsClicked] = useState(false)
  const [val, setVal] = useState('');
  const [btnBeenClicked, setBtnBeenClicked] = useState(false);

  const paidAnswerObj = {
    storyId: story.id,
    wayIndex: story.ways.findIndex(way => way === item)
  };

  let isAnswerAlreadyBeenPaid;
  if (user.paidAnswers) {
    isAnswerAlreadyBeenPaid = user.paidAnswers.findIndex(answer => JSON.stringify([answer.storyId, answer.wayIndex]) === JSON.stringify([paidAnswerObj.storyId, paidAnswerObj.wayIndex]));
  } else {
    isAnswerAlreadyBeenPaid = -1;
  }

  const history = useHistory();

  const { newBtn, currentButton } = currentStoryProps;

  let firstDelay = 0;
  let secondDelay = 5000;
  if (story.ways) {
    if (story.ways.length !== 1 && story.ways.length <= 3) {
      firstDelay = 2000;
      secondDelay = 9500;
    } else if (story.ways.length > 3) {
      firstDelay = 5000;
      secondDelay = 12500;
    }
  }

  useEffect(() => {
    if (user.paidAnswers) {
      if ((!user.paidAnswers.length && !isOverlayBeenShown) || newUser) {
        setTimeout(function() {
          setEducationalOverlay(true);
        }, firstDelay)
        setTimeout(function() {
          setEducationalOverlay(false);
        }, secondDelay);
        isEducationalOverlayBeenShown(true);
        setNewUser(false);
      }
    }
  }, [user]);

  const paidAnswerAnimation = (value) => {
    setVal(value);
    setNewPaidAnswer(true);
    changeTokensAmount(userTokens - item.tokens);
    setTimeout(function() {
      setNewPaidAnswer(false);
    }, 900);
  };

  const paidBtn = (id, value) => {
    isTypeWriterMounted(false);
    stopTyping(false);
    const nextStory = stories.find(item => item.parentId === id && item.title === value);
    if(story["bgrImg"]!==nextStory["bgrImg"]) {
      setOverlay(true);
    } else {
      setOverlay(false)
    }
    isNextBtnNonActive(true);
    setQuestionsDelay(true);
    isQuestionTyping(true);
    renderTypewriter(false);
    showAnswers(true);
    setTimeout(function() {
      renderTypewriter(true);
    }, timeOutsConstants.CHANGE_TYPEWRITER_STATE_DELAY_NOT_MAIN);
    setTimeout(function() {
      setOverlay(false);
    }, timeOutsConstants.REMOVE_OVERLAY_DELAY);
    push(id, value);
  };

  const onButtonClick = async (id, value) => {
    setIsLoader(true)
    setIsClicked(true)
    if (!btnBeenClicked) {
      setBtnBeenClicked(true);
      if (currentUser && (user.tokens > 0 || isAnswerAlreadyBeenPaid >= 0)) {
        if (isAnswerAlreadyBeenPaid === -1) {
          if (!navigator.onLine) {
            return;
          } else if (user.tokens < item.tokens) {
            history.push('/buyTickets');
            return;
          } else if (user.providerData[0].providerId === 'password' && !user.emailVerified) {
            isNonEmailVerified(true);
            history.push('/profile');
            return;
          } else {
            await buyAnswer(user.id, item.tokens);
          }
          await rememberPaidAnswer(user.id, paidAnswerObj);
          paidAnswerAnimation(value);
          setTimeout(function() {
            paidBtn(id, value);
          }, 2100);
        } else {
          setTimeout(function() {
            paidBtn(id, value);
          }, timeOutsConstants.ANSWER_BUTTON_DELAY);
        }
      } else if (user.tokens === 0) {
        isTypeWriterMounted(false);
        history.push('/buyTickets');
      } else {
        isTypeWriterMounted(false);
        history.push('/login');
      }
    }
  };

  return (
    <div className='paid-btn slides-btn'>
      {currentButton.value === item.value ?
        <React.Fragment>
          <img src={Save} alt='' className='float-right mt-3' onClick={save} />
          <span className='position-relative d-flex justify-content-center align-items-center ml-3' key={item.value} >
            <div className='my-tabs-premium d-flex justify-content-around align-items-center' >
              <textarea value={newBtn} onChange={handleWaysChange} className='form-control' />
            </div>
            <span className='col-12 premium-label-wrapper d-flex justify-content-center'>
              <img className='priceToken' src={PriceToken} alt='' />
            </span>
          </span>
        </React.Fragment> :
        <React.Fragment>
          {user.admin ? <React.Fragment>
            <label key={item.value}><input type='checkbox' checked={item.paid}
                onChange={() => onCheckBox(story.id, story.ways, { ...item, paid: !item.paid })} />
            </label>
            <img src={Edit} className='float-right mt-3' alt='' onClick={() => {
                changeCurrentBtn(item);
                return addNewBtn(item.value);
              }}/>
          </React.Fragment> : null}
          <span className={`${(val === item.value) && 'active'} ${(isAnswerAlreadyBeenPaid !== -1 ) ? 'alreadyPaid' : 'notPaidAnswer' } paid-btn-span-${isClicked} paid-btn-span position-relative`} data-toggle='modal' data-target={`${(navigator.onLine || (isAnswerAlreadyBeenPaid !== -1)) ? null :'#paidAnswersModal'}`} onClick={() => onButtonClick(story.id, item.value)}> 
            <div className='my-tabs-premium d-flex justify-content-around align-items-center' >
              <span>{(isLoader)?
              (<TailSpin
                visible={true}
                height="40px"
                width="40px"
                color="#FFFFFF"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass="loader"
                />)
                :item.value}</span>
            </div>
            {(isAnswerAlreadyBeenPaid === -1) && PriceToken ? <span className={`paidBtnSelected col-12 premium-label-wrapper d-flex justify-content-center`} > 
            {/* style={educationalOverlay ? { transform: 'scale(1.1)' } : null} */}
              <img id='paidBtnSelected' className='priceToken' src={PriceToken} alt='' />
              <b>{item.tokens}</b>
            </span> : null}
          </span>
          {user.admin &&
          <label><input type='checkbox' checked={item.published}
                onChange={() => onCheckBox(story.id, story.ways, { ...item, published: !item.published })} /></label>}
        </React.Fragment>}
      </div>
  );
}

const mapStateToProps = state => ({
  currentStoryProps: state.currentStoryState,
  userTokens: state.authState.online.tokens,
  educationalOverlay: state.buyingTicketsState.educationalOverlay,
  isOverlayBeenShown: state.buyingTicketsState.isOverlayBeenShown,
  newPaidAnswer: state.buyingTicketsState.newPaidAnswer,
  newUser: state.registrationState.newUser,
});

export default connect(mapStateToProps, { isNonEmailVerified, setOverlay, addNewBtn, changeCurrentBtn, setNewPaidAnswer,
                      stopTyping, renderTypewriter, setEducationalOverlay, isNextBtnNonActive, showAnswers, isTypeWriterMounted,
                      setQuestionsDelay, isQuestionTyping, changeTokensAmount, isEducationalOverlayBeenShown })(SlidesButtonPaid);
