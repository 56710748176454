import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { onTermsAgree } from '../../actions/homeRouteActions';
import { newUserIDPLogin } from '../../actions/authActions';

import logo from '../../essentials/logo.svg';
import termsAndConditionsImg from '../../assets/images/tandc_img.svg';

import './TermsAndConditions.css';
import useFontLoader from '../../hooks/useFontLoader';

const TermsAndConditions = ({ onTermsAgree, redirectToLogin, newUserIDPLogin }) => {

  const history = useHistory();
  const [loaded,setLoaded] = useState(false)
  const [isDisapearOut,setIsDisapearOut] = useState(false)

  const handleLoad = () =>{
    return setLoaded(true)
  }

  const isFontReady = useFontLoader("Gloria Hallelujah")

  const onClickYes = () => {
    setIsDisapearOut(true)
    onTermsAgree(true);
    newUserIDPLogin(false);
    localStorage.removeItem('homeSearchStoriesClicked');
    localStorage.removeItem('overEighteen');
    localStorage.removeItem('newIDPUser');
    setTimeout(()=>{
      history.push('/');;
    },3000)
  };

  const onClickNo = () => {
    setIsDisapearOut(true)
    onTermsAgree(false);
    setTimeout(()=>{
      history.push('/ageVerify');
    },3000)
  };

  const onPrivacyLinkClick = () => {
    onTermsAgree(false);
    history.push('/privacy');
  };

  return (
    <div className={`termsAndConditions ${isDisapearOut? `termsAndConditions-disapear`:undefined}`}>
      <img src={logo} className='ageVerifyLogo' alt='' />
      <div className='termsAndConditionsText'>
        {isFontReady&& loaded  ? 
        (<><h2>Do you accept our Terms and Conditions?</h2> 
        <button
          className='privacyLink'
          data-dismiss='modal'
          onClick={()=> onPrivacyLinkClick()}
        >
          You can read all about our T&C's here
        </button></>)
        :
        (<h2></h2>)
        }
      </div>
      <div className='termsAndConditionsButtons'>
        { (loaded) ?
        <button className='termsAndConditionBtn Reject' onClick={() => onClickNo()}>
          Reject
        </button> :<></>
        }
        <img src={termsAndConditionsImg} alt='' className='termsAndConditioImg' loading='eager' onLoad={handleLoad}/>
        { (loaded) ?
        <button className='termsAndConditionBtn Accept' onClick={() => onClickYes()}>
          Accept
        </button>:<></>
        }
      </div>
    </div>
  );

}

const mapStateToProps = state => ({
  redirectToLogin: state.homeRouteState.redirectToLogin
});

export default connect(mapStateToProps, { onTermsAgree, newUserIDPLogin })(TermsAndConditions);
