import * as actionTypes from './types';


const addToImgsState = mainImgs => ({
  type: actionTypes.GET_MAIN_IMGS,
  payload: mainImgs
});


export const changeParentId = parentId => {
  return {
    type: actionTypes.CHANGE_PARENT_ID,
    payload: parentId
  }
};

export const changeCurrentId = currentId => {
  return {
    type: actionTypes.CHANGE_CURRENT_ID,
    payload: currentId
  }
};

export const isMobileMenuOpen = bool => {
  return {
    type: actionTypes.IS_MOBILE_MENU_OPEN,
    payload: bool
  }
};

export const addWays = waysArr => {
  return {
    type: actionTypes.ADD_WAYS,
    payload: waysArr
  }
};

export const isMain = bool => {
  return {
    type: actionTypes.IS_MAIN,
    payload: bool
  }
};

export const addAudio = audio => {
  return {
    type: actionTypes.ADD_AUDIO,
    payload: audio
  }
};

export const addImg = img => {
  return {
    type: actionTypes.ADD_IMG,
    payload: img
  }
};

export const addBgrImg = bgrImg => {
  return {
    type: actionTypes.ADD_BGR_IMG,
    payload: bgrImg
  }
};

export const addSmpict = smpict => {
  return {
    type: actionTypes.ADD_SMPICT,
    payload: smpict
  }
};

export const addBtns = btns => {
  return {
    type: actionTypes.ADD_BTNS,
    payload: btns
  }
}

export const changeMode = mode => {
  return {
    type: actionTypes.CHANGE_MODE,
    payload: mode
  }
}

export const addTitle = title => {
  return {
    type: actionTypes.ADD_TITLE,
    payload: title
  }
}

export const changeList = list => {
  return {
    type: actionTypes.CHANGE_LIST,
    payload: list
  }
}

export const changeMainId = mainId => {
  return {
    type: actionTypes.CHANGE_MAIN_ID,
    payload: mainId
  }
}

export const getMainImgs = (stories, getResizedStoryImg) => {
  return async(dispatch) => {
    try {
      const mainImgs = {};
      stories.map(async (story) => {
        if (story.parentId === 'main') {
          if(!!story.img) {
            const img = story.img.split('/')[7].split('?')[0];
            const resizedImg = await getResizedStoryImg(img);
            const isAlreadyInStorage = !!(localStorage.getItem(`mainImg-${story.id}`));
            if (!isAlreadyInStorage) {
              localStorage.setItem(`mainImg-${story.id}`, resizedImg);
            }
            mainImgs[`${story.id}`] = resizedImg;
          }
        }
        dispatch(addToImgsState(mainImgs));
      });
    } catch (err) {
      throw err;
    }
  }
}

export const loadMoreCommits = () => {
  return {
    type: actionTypes.LOAD_MORE_COMMITS
  }
}

export const setCommitsNumToZero = () => {
  return {
    type: actionTypes.SET_COMMITS_NUM_TO_ZERO
  }
}

export const addSortedMainstories = (arr, completedStories, localCompletedStory) => {
  return {
    type: actionTypes.SORTED_MAIN_STORIES,
    payload: arr,
    completedStories,
    localCompletedStory
  }
};

export const addToFindedStories = arr => {
  return {
    type: actionTypes.ADD_TO_FINDED_STORIES,
    payload: arr
  }
}
