import React from 'react';
import { Link } from 'react-router-dom';

import SlidesHamButton from '../../pages/Slides/slidesHamButton/SlidesHamButton';
import SlidesNav from '../../pages/Slides/slidesNav/SlidesNav';
import data from './data';

import logo from '../../essentials/logo.svg';

import './faq.css';

const Faq = () => {

  return (
    <div className='faqContainer'>
      <SlidesHamButton />
      <SlidesNav />
      <div className='header'>
        <Link to='/'><img src={logo} alt='' /></Link>
      </div>
      <div className='faqWrapper'>
        <h2 className='faqHeader'>Frequently Asked Questions</h2>
        <div className='questionsContiner'>
          {data.map((question, i) => (
            <div className='questionContiner' key={i}>
              <div className='visibleArea'>
                <p>{question.question}</p>
                <button type='button' data-toggle='collapse' data-target={`#collapse${i}`} aria-expanded='false' aria-controls={`collapse${i}`}>+</button>
              </div>
              <p className='collapse' id={`collapse${i}`}>{question.answer}</p>
            </div>
          ))}
      </div>
      </div>
    </div>
  );

}

export default Faq;
