import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import useWindowSize from '../../hooks/useWindowSize';

import { useFirestore } from '../../context/FirestoreContext';
import { useAuth } from '../../context/AuthContext';

const NewUserAlert = () => {

  const { width } = useWindowSize();

  return (
    <React.Fragment>
      {width < 900 ?
        <div className='alert alert-warning alert-dismissible fade show' role='alert'>
          <strong>&#9757; This App is optimised to be used as standalone app,</strong> please save to home-screen for best user experience.
          <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
            <span aria-hidden='true'>&times;</span>
          </button>
        </div> :
        null
      }

  </React.Fragment>
  )
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps)(NewUserAlert);
