import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { useAuth } from '../../context/AuthContext';

import { setLastCompletedStory, setNewStoryAnimation } from '../../actions/userInteractionActions';

import './index.css';


const UnlockedStoryOverlay = ({ isNewAnimation, fromLogin, storiesPageProps, lastCompletedStory, setLastCompletedStory, setNewStoryAnimation, newStoryanimation, docId,isAllLoaded }) => {

  const { currentUser } = useAuth();
  const [ proccesingAnimation,setProccesingAnimation ] = useState(true)
  const { openStories } = storiesPageProps;
  const lastOpenStory = openStories[openStories.length - 1];

  const isNewStoryBeenOpen = (lastOpenStory !== lastCompletedStory) && (lastCompletedStory !== '');

  const doesOverlayNeeded = isNewStoryBeenOpen || fromLogin;
  let renderOverlay = true;
  useEffect(() => {
    if (!doesOverlayNeeded) {
      setTimeout(function() {
        renderOverlay = false;
      }, 1000);
    }
  }, [doesOverlayNeeded]);

  useEffect(() => {
    if (!!currentUser && (isNewStoryBeenOpen || fromLogin)) {
      setTimeout(function() {
        setNewStoryAnimation(true);
      }, 800);
      setTimeout(function() {
        setNewStoryAnimation(false);
      }, 2000);
      setTimeout(function() {
        setLastCompletedStory('');
      }, 2500);
    }
  }, [lastOpenStory, isNewStoryBeenOpen]);
  useEffect(()=>{
    if(isNewAnimation&&isAllLoaded) {
      setTimeout(()=>{
        setProccesingAnimation(false)
      },1200)
    }
  },[isAllLoaded,isNewAnimation])
  return (
    <React.Fragment>
      {renderOverlay && lastOpenStory === docId ?
        <div className={`unlockedStoryOverlay ${ isNewAnimation&&isAllLoaded&&proccesingAnimation ? 'animate' : ''}`} /> : // newStoryanimation isNewAnimation&&isAllLoaded
        null
      }
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  fromLogin: state.authState.fromLogin,
  storiesPageProps: state.storiesPageState,
  lastCompletedStory: state.userInteractionState.lastCompletedStory,
  newStoryanimation: state.userInteractionState.newStoryanimation
});

export default connect(mapStateToProps, { setLastCompletedStory, setNewStoryAnimation })(UnlockedStoryOverlay);
