import React from 'react';
import { connect } from 'react-redux';

import './SlidesOverlay.css';


const SlidesOverlay = ({ userExperienceProps }) => {

  const { overlay } = userExperienceProps;

  const storyOverlay = overlay ? <div id='storyOverlay' className='theEndOverlay' /> : null;

  return storyOverlay;
};

const mapStateToProps = state => ({
  userExperienceProps: state.userExperienceState
});

export default connect(mapStateToProps)(SlidesOverlay);
