import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { useAuth } from '../context/AuthContext.js';

function HomeRoute({ homeRouteProps, storyId, newIDPUser }) {

  const { homeSearchStoriesClicked, overEighteen, termsAgree, homeLoginClicked } = homeRouteProps;
  const isAllHomeRoutePropsTrue = homeSearchStoriesClicked && overEighteen && termsAgree;

  const { user } = useAuth();

  let isUserLoggedInWithPassword;
  let isExistingIDPUser;
  if (user) {
    isUserLoggedInWithPassword = user.providerData[0].providerId === 'password';
    isExistingIDPUser = !isUserLoggedInWithPassword && !newIDPUser
  }

  const canLinksBeShown = isUserLoggedInWithPassword || isAllHomeRoutePropsTrue;
  const isCheckout = localStorage.getItem('isCheckout')
  const isStoryStored = !(storyId === '');


  return (
    <Route
      render={() => {
        if (!canLinksBeShown&&!isCheckout ) {
          if ((homeSearchStoriesClicked && !homeLoginClicked) || newIDPUser) {
            return <Redirect to='/ageVerify' />;
          } else if (isExistingIDPUser || isUserLoggedInWithPassword) {
            return <Redirect to='/' />
          } else {
            return <Redirect to='/home' />
          }
        } else if (isStoryStored) {
          return <Redirect to={`/story/${storyId}`} />
        }
      }}></Route>
  );

}

const mapStateToProps = state => ({
  homeRouteProps: state.homeRouteState,
  storyId: state.userInteractionState.storyId,
  newIDPUser: state.authState.newIDPUser
});

export default connect(mapStateToProps)(HomeRoute);
