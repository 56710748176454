import * as actionTypes from './types';

const fetchStories = stories => {
    return {
        type: actionTypes.FETCH_STORIES,
        payload: stories
    }
}
const createStories = story => {
    return {
        type: actionTypes.CREATE_STORIES,
        payload: story
    }
}

const createCommit = commit => {
    return {
        type: actionTypes.CREATE_COMMIT,
        payload: commit
    }
}
const deleteStories = story => {
    return {
        type: actionTypes.DELETE_STORY,
        payload: story
    }
}
const leadingData = loading => {
    return {
        type: actionTypes.LOADING,
        payload: loading
    }
}

export {
    fetchStories,
    createStories,
    deleteStories,
    leadingData,
    createCommit
}
