import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { functions } from '../../firebase';
import { useStripe } from '@stripe/react-stripe-js';
import { TailSpin } from 'react-loader-spinner'

import { useAuth } from '../../context/AuthContext';

import { changeBtnText, redirectToCheckout } from '../../actions/buyingTicketsActions';
import { isNonEmailVerified } from '../../actions/authActions';


import logo from '../../essentials/logo.svg';

import './BuyTickes.css';

let BuyTicketsPayment;

if (navigator.onLine) {
  BuyTicketsPayment = ({ isNonEmailVerified, choosenTicket, uid, successUrl, countryCode, buttonInnerText, changeBtnText }) => {
    const [isLoader, setIsLoader] = useState(false)
    const stripe = useStripe();

    const history = useHistory();
    const { user } = useAuth();

    const handleClick = async (event) => {
      localStorage.removeItem('isCheckout')
      setIsLoader(true)
      if (user) {
        redirectToCheckout(true);
        localStorage.setItem('fromCheckout', true);
        if (user.providerData[0].providerId === 'password' && !user.emailVerified) {
          isNonEmailVerified(true);
          history.push('/profile');
          return;
        }
      }
      changeBtnText();
      const createStripeCheckout = functions.httpsCallable('createStripeCheckout');
      setTimeout(async ()=>{ await createStripeCheckout({choosenTicket, uid, successUrl, countryCode})
      .then(res => {
        localStorage.removeItem('storyId');
        localStorage.setItem('stipecheckout',true)
        localStorage.setItem('isCheckout',true)
        const sessionId = res.data.id
        stripe.redirectToCheckout({ sessionId: sessionId })
      })},2000)
    };

    return (
      <button className='ButtonForPay' role='link' onClick={handleClick}>
        {isLoader? (<TailSpin
            visible={true}
            height="40px"
            width="40px"
            color="#FFFFFF"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="loader"
            />) :
        ([buttonInnerText])}
      </button>
    );
  };
} else {
  BuyTicketsPayment = () => {
    return (
      <p>You should be online to access payment</p>
    );
  }
}

const mapStateToProps = state => ({
  buttonInnerText: state.buyingTicketsState.buttonInnerText
});

export default connect(mapStateToProps, { isNonEmailVerified, changeBtnText })(BuyTicketsPayment);
