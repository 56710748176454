import * as actionTypes from './types';


const addToResizedBgrImgState = resizedImage => ({
  type: actionTypes.GET_RESIZED_BGR_IMG,
  payload: resizedImage
});

const addToResizedSmpictState = resizedImage => ({
  type: actionTypes.GET_RESIZED_SMPICT,
  payload: resizedImage
});


export const changeStoryMode = storyMode => {
  return {
    type: actionTypes.CHANGE_STORY_MODE,
    payload: storyMode
  }
};

export const addNewBtn = newBtn => {
  return {
    type: actionTypes.ADD_NEW_BTN,
    payload: newBtn
  }
};

export const changeCurrentBtn = currentBtn => {
  return {
    type: actionTypes.CHANGE_CURRENT_BTN,
    payload: currentBtn
  }
};

export const isMobileNavOpen = bool => {
  return {
    type: actionTypes.IS_MOBILE_NAV_OPEN,
    payload: bool
  }
};

export const isContinueModalOpen = bool => {
  return {
    type: actionTypes.CONTINUE_MODAL,
    payload: bool
  }
};

export const isEdit = bool => {
  return {
    type: actionTypes.IS_EDIT,
    payload: bool
  }
};

export const changePageNum = num => {
  localStorage.setItem('cur_num', num);
  return {
    type: actionTypes.CHANGE_PAGE_NUM,
    payload: num
  }
};

export const setQuestion = question => {
  return {
    type: actionTypes.SET_QUESTION,
    payload: question
  }
};

export const setDescription = description => {
  return {
    type: actionTypes.SET_DESCRIPTION,
    payload: description
  }
};

export const getResizedBgrImgAction = (story, getResizedBgrImg, screenSize) => {
  return async(dispatch) => {
    try {
      //get image name from url
      const bgrImg = story.bgrImg.split('/')[7].split('?')[0];

      const resizedImage = await getResizedBgrImg(bgrImg, screenSize);
      const isAlreadyInStorage = !!(localStorage.getItem(`bgrImg-${story.id}`));
      if (!isAlreadyInStorage) {
        localStorage.setItem(`bgrImg-${story.id}`, resizedImage);
      }
      dispatch(addToResizedBgrImgState(resizedImage));
    } catch (err) {
      throw err;
    }
  }
}

export const getResizedSmpictAction = (story, getResizedSmpict, screenSize) => {
  return async(dispatch) => {
    try {
      //get image name from url
      const smpict = story.smpict.split('/')[7].split('?')[0];

      const resizedImage = await getResizedSmpict(smpict, screenSize);
      const isAlreadyInStorage = !!(localStorage.getItem(`smpictImg-${story.id}`));
      if (!isAlreadyInStorage) {
        localStorage.setItem(`smpictImg-${story.id}`, resizedImage);
      }
      dispatch(addToResizedSmpictState(resizedImage));
    } catch (err) {
      throw err;
    }
  }
}

export const setImgsToNull = () => {
  return {
    type: actionTypes.SET_IMGS_TO_NULL,
  }
};

export const addRating = num => {
  return {
    type: actionTypes.ADD_RATING,
    payload: num
  }
};

export const addFeedbackError = (error) => {
  return {
    type: actionTypes.FEEDBACK_ERROR,
    payload: error
  }
};

export const isNextBtnNonActive = (bool) => {
  return {
    type: actionTypes.NEXT_BTN,
    payload: bool
  }
};
