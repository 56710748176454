import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import './index.css';


const NotCachedStoryOverlay = ({ openStories, docId }) => {

  const isOnline = navigator.onLine;
  const haveStoryBeenCashed = !!(localStorage.getItem(`cashedStorywithId-${docId}`));
  const isOpen = openStories.find(storyId => storyId === docId);

  const isNotCashedOverlay = !isOnline && !haveStoryBeenCashed && isOpen;

  return (
    <React.Fragment>
      {isNotCashedOverlay ?
        <div className='lockStoryOverlay notCashedOverlay'>
          <p>Connect to Internet</p>
        </div> :
        null
      }
    </React.Fragment>
  );

};

const mapStateToProps = state => ({
  openStories: state.storiesPageState.openStories
});

export default connect(mapStateToProps)(NotCachedStoryOverlay);
