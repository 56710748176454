import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setOverlay, setQuestionsDelay, isQuestionTyping, stopTyping, renderTypewriter, setTypewriterState, showAnswers } from '../../../actions/userExperienceActions';
import { isContinueModalOpen, isNextBtnNonActive, setImgsToNull } from '../../../actions/currentStoryActions';
import { setToStart } from '../../../actions/userInteractionActions';

import continuePicture from '../../../assets/images/Group 143.svg';

import './SlidesContinueModal.css';


const SlidesContinueModal = ({ isContinueModalOpen, setToStart, setOverlay, isOpen, setImgsToNull, renderTypewriter,
    story, isNextBtnNonActive, setQuestionsDelay, isQuestionTyping, stopTyping, setTypewriterState, showAnswers }) => {

  const history = useHistory();

  function startOver() {
    showAnswers(true);
    setTypewriterState(0);
    setToStart();
    setImgsToNull();
    stopTyping(false);
    isNextBtnNonActive(true);
    setQuestionsDelay(true);
    isQuestionTyping(true);
    renderTypewriter(false);
    history.push('/');
    isContinueModalOpen(false);
  };

  const modal = isOpen ? (
      <div className='modal show' aria-modal='true' id='continueModal' role='dialog' style={{'display': 'block'}}>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <button type='button' className='close-modal-button' onClick={() => isContinueModalOpen(false)} data-dismiss='modal'>&times;</button>
          <h1 className='modal-h1'>Continue or Back to Main Menu?</h1>
          <img src={continuePicture} className='continuePicture mt-1' alt=''/>
          <div  className='profileDeleteModalBtns'>
            <button className='continueButton' onClick={() => isContinueModalOpen(false)} data-dismiss='modal'>Continue</button>
            <button className='continueButton' onClick={() => startOver()} data-dismiss='modal'>Main Menu</button>
          </div>
        </div>
      </div>
    </div>
  ): null;

  return modal;
};

const mapStateToProps = state => ({
  isOpen: state.currentStoryState.continueModalOpen
});


export default connect(mapStateToProps, { isContinueModalOpen, setToStart, setOverlay, isNextBtnNonActive, renderTypewriter,
                        setImgsToNull, setQuestionsDelay, isQuestionTyping, stopTyping, setTypewriterState, showAnswers })(SlidesContinueModal);
