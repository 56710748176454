import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AuthProvider } from '../../context/AuthContext';
import { FirestoreProvider } from '../../context/FirestoreContext';

import SingInRoute from '../SinginRoute';
import HomeRoute from '../HomeRoute';
import PrivateRoute from '../PrivateRoute';
import CommitDeletionRoute from '../commitDeletionRoute';

import Home from '../Home';
import AgeVerify from '../ageVerify';
import TermsAndConditions from '../TermsAndConditions';
import SearchHistory from '../../pages/searchHistory';
import Privacy from '../Privacy';
import Faq from '../FAQ/faq';
import Registration from '../registration/Registration';
import LoginList from '../Login/Login';
import Login from '../sing-up/Login';
import Profile from '../user-profile/Profile';
import ChangePassword from '../ChangePassword';
import ForgotPassword from '../ForgotPassword/';
import CommitDeletionPage from '../../pages/commitDeletionPage/CommitDeletionPage';
import Slides from '../../pages/Slides/Slides';
import TheEnd from '../../pages/the-end/TheEnd';
import BuyTickesFlashPage from '../../pages/BuyTickes/BuyTickesFlashPage';
import BuyTicketsPayment from '../../pages/BuyTickes/BuyTicketsPayment';
import FeedBack from '../../pages/feedback/FeedBack';
import Footer from './../mainLayout/footer';


const Strip = () => {
  return (
    <Switch>
        <AuthProvider>
          <FirestoreProvider>
              <React.Fragment>
                <HomeRoute path='/' component={Home} exact />
                <SingInRoute path='/login' component={LoginList} exact />
                <SingInRoute path='/sing-in' component={Login} exact />
                <Route path='/registration' component={Registration} exact />
                <PrivateRoute exact path='/profile' component={Profile} />
                <Route path='/privacy' component={Privacy} />
                <Route path='/faq' component={Faq} />
                <Route exact path='/' component={SearchHistory} />
                <Route exact path='/home' component={Home} />
                <Route path='/story/:id' component={Slides} />
                <Route path='/ageVerify' component={AgeVerify} exact />
                <Route path='/termsAndConditions' component={TermsAndConditions} exact />
                <Route path='/forgot-password' component={ForgotPassword} />
                <PrivateRoute path='/changePassword' component={ChangePassword} exact />
                <Route path='/theEnd/:id' component={TheEnd} />
                <PrivateRoute path='/feedBack/:id' component={FeedBack} />
                <CommitDeletionRoute exact path='/deleteCommit/:id' component={CommitDeletionPage} />
                <Route path='/buyTickets' exact component={BuyTickesFlashPage} />
                <Route path='/payment' exact component={BuyTicketsPayment} />
                <Footer />
              </React.Fragment>
          </FirestoreProvider>
        </AuthProvider>
    </Switch>
  );
};

export default Strip