import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import useWindowSize from '../../../hooks/useWindowSize';
import { getResizedSmpictAction } from '../../../actions/currentStoryActions';
import { useFirestore } from '../../../context/FirestoreContext';

import './SlidesBigImage.css';

const SlidesBigImage = ({ smpict, getResizedSmpictAction, story }) => {

  const { width } = useWindowSize();
  const { getResizedSmpict } = useFirestore();

  useEffect(() => {
    let screenSize;
    if (width <= 425) {
      screenSize = 0;
    } else if (width <= 768) {
      screenSize = 1;
    } else {
      screenSize = 2;
    }
    getResizedSmpictAction(story, getResizedSmpict, screenSize);
  }, [story]);

  return (
    <React.Fragment>
      <div className='bigImage'>
        <img src={localStorage.getItem(`smpictImg-${story.id}`) ? localStorage.getItem(`smpictImg-${story.id}`) : (smpict ? smpict : '')} alt='' />
      </div>
     </React.Fragment>
  );
}

const mapStateToProps = state => ({
  smpict: state.currentStoryState.smpict
});

export default connect(mapStateToProps, { getResizedSmpictAction })(SlidesBigImage);
