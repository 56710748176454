import React, { useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { addError, isLoading, setNewUser } from '../../actions/registrationActions';
import { changeTokensAmount } from '../../actions/buyingTicketsActions';

import { useAuth } from '../../context/AuthContext';

import SlidesHamButton from '../../pages/Slides/slidesHamButton/SlidesHamButton';
import SlidesNav from '../../pages/Slides/slidesNav/SlidesNav';

import logo from '../../essentials/logo.svg';
import registrationImg from '../../assets/images/registration_page_img.svg';

import './registration.css';
import './switch.css';


function Registration({ registrationProps, addError, isLoading, storyId,
                        isFeedbackBtnClicked, setNewUser, changeTokensAmount }) {

    const history = useHistory();

    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const { signup } = useAuth();

    const { error, isRegSuccessful } = registrationProps;


    useEffect(() => {
      if (isRegSuccessful) {
        setNewUser(true);
        changeTokensAmount(3);
        history.push('/profile');
        addError('');
      }
    }, [isRegSuccessful]);

    async function handleSubmit(event) {
        event.preventDefault();
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
          return addError('Passwords do not match.');
        }
        try {
          isLoading(true);
          await signup(emailRef.current.value, passwordRef.current.value);
          localStorage.removeItem("isFirstTime")
        } catch {
          addError('Failed to create an account.');
        }
        isLoading(false);
    }

    function goBack () {
      history.push('/sing-in');
      addError('');
    }

    return (
      <div className='reg'>
        <SlidesHamButton />
        <SlidesNav />
        <div className='registarationHeader'>
          <Link to='/'>
            <img src={logo} alt='logo' className='logo' />
          </Link>
        </div>
        <div className='closeBtnWrapper'>
          <button type='button' className='buyCloseBtn' onClick={()=> goBack()}>
            &times;
          </button>
        </div>
        <div className='registartionContent'>
          <form>
            <div className='formContent'>
              <div className='registartionInputs'>
                <img src={registrationImg} alt='' />
                <input
                  type='text'
                  className='cred'
                  placeholder='Email*'
                  ref={emailRef}
                />
                <input
                  type='password'
                  className='cred'
                  placeholder='Password*'
                  ref={passwordRef}
                />
                <input
                  type='password'
                  className='cred'
                  placeholder='Confirm password*'
                  ref={passwordConfirmRef}
                />
                <button
                  className='saveProfile'
                  type='submit'
                  onClick={handleSubmit}>
                  Save Profile
                </button>
                {error ?
                  <div className='error mt-3'>{error}</div> :
                  null
                }
              </div>
            </div>
        </form>
        </div>
      </div>
    );

}

const mapStateToProps = state => ({
  registrationProps: state.registrationState,
  isFeedbackBtnClicked: state.homeRouteState.isFeedbackBtnClicked,
  storyId: state.userInteractionState.storyId,
});

export default connect(mapStateToProps, { addError, isLoading, setNewUser,
                                          changeTokensAmount })(Registration);
