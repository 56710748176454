import * as actionTypes from '../actions/types';

const initialState = {
  homeSearchStoriesClicked: (localStorage.getItem('homeSearchStoriesClicked') === 'true') || false,
  overEighteen: (localStorage.getItem('overEighteen') === 'true') || false,
  termsAgree: false,
  feedBackPathname: '',
  isFeedbackBtnClicked: false,
  redirectToLogin: false,
  homeLoginClicked: false
};

const homeRouteReducer = (state = initialState, action) =>  {
  switch (action.type) {
    case actionTypes.HOME_STORIES_BUTTON_CLICK:
      return {
        ...state,
        homeSearchStoriesClicked: action.payload
      }
    case actionTypes.OVER_EIGHTEEN:
      return {
        ...state,
        overEighteen: action.payload
      }
    case actionTypes.TERMS_AGREE:
      return {
        ...state,
        termsAgree: action.payload
      }
    case actionTypes.FEEDBACK_PATHNAME:
    return {
      ...state,
      feedBackPathname: action.payload
    }
    case actionTypes.FEEDBACK_CLICKED:
      return {
        ...state,
        isFeedbackBtnClicked: action.payload
      }
    case actionTypes.REDIRECT_TO_LOGIN:
      return {
        ...state,
        redirectToLogin: true
      }
    case actionTypes.HOME_LOGIN_CLICKED:
      return {
        ...state,
        homeLoginClicked: action.payload
      }
    default:
        return state;
  }
};

export default homeRouteReducer;
