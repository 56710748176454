import React from 'react';

import yellowStar from '../../assets/icons/star-yellow.svg';
import greyStar from '../../assets/icons/star-grey.svg';

import './RatingStar.css';


const StarComponent = ({ isEmpty, size }) => {
  return (
    <React.Fragment>
      <img
        height={`${size}px`}
        width={`${size}px`}
        alt=''
        src={`${isEmpty ? greyStar : yellowStar}`}
      />
    </React.Fragment>
  );
};

export default StarComponent;
