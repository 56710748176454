import React from 'react';
import { connect } from 'react-redux';

import { changePageNum } from '../../../../actions/currentStoryActions';
import { renderTypewriter, isTypeWriterMounted, stopTyping } from '../../../../actions/userExperienceActions';

import leftArrow from '../../../../assets/icons/left_arrow.svg';

import '../SlidesMainContentQuestions.css';
import { useHistory } from 'react-router-dom';

const LeftArrow = ({ num, text, arrowClick, overlayTransition, changePageNum,
                    renderTypewriter, isTypeWriterMounted, isMobile, stopTyping, story }) => {
  
  const history = useHistory();
  const prevBtnClick = () => {
    if (num >= 1){
      arrowClick();
      renderTypewriter(true);
      changePageNum(num - 1);
    } else{
      history.push(`/story/${story.parentId}`);
      changePageNum(text.length)
    }
  };

  return (
    <React.Fragment>
      {(story?.id === story?.mainId && num===0) || (story?.mainId===undefined && num===0)  ? (
        <div
        className={`${
          isMobile ? "mobile-btn-stub-left" : "desktop-btn-stub-left"
        } btn-stub-left`}
      ></div>
      ) : (
        <div
          className={`${
            isMobile ? "mobile-circle-btn" : "desktop-circle-btn"
          } circle-btn`}
          onClick={() => prevBtnClick()}
        >
          <img src={leftArrow} alt="" />
        </div>
        
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  num: state.currentStoryState.num,
});

export default connect(mapStateToProps, {
  changePageNum,
  renderTypewriter,
  isTypeWriterMounted,
  stopTyping,
})(LeftArrow);
