import React from 'react';
import { Redirect } from 'react-router-dom';

import Question from './Question';


const ToggleQuestionAndRedirect = ({ story }) => {

  return (
    <React.Fragment>
      {story.question ?
        <Question story={story} /> :
        <Redirect to={`/theEnd/${story.mainId}`} />
      }
    </React.Fragment>
  );

}


export default ToggleQuestionAndRedirect;
