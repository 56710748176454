import React from 'react';
import { connect } from 'react-redux';

import * as timeOutsConstants from '../../../../constants/timeOutsConstants';

import { addNewBtn, changeCurrentBtn, isNextBtnNonActive } from '../../../../actions/currentStoryActions';
import { isTypeWriterMounted, setOverlay, stopTyping, setQuestionsDelay, isQuestionTyping, renderTypewriter, showAnswers } from '../../../../actions/userExperienceActions';

import { useAuth } from '../../../../context/AuthContext';
import { useFirestore } from '../../../../context/FirestoreContext';

import Save from '../../../../assets/images/save.png';
import Edit from '../../../../assets/images/edit-button.png';

import './SlidesButtonFree.css';

const SlidesButtonFree = ({ setOverlay, currentStoryProps, addNewBtn, changeCurrentBtn, isTypeWriterMounted,
                            story, item, push, save, handleWaysChange, stopTyping, renderTypewriter,
                            isNextBtnNonActive, setQuestionsDelay, isQuestionTyping, showAnswers }) => {

  const { newBtn, currentButton } = currentStoryProps;

  const { stories } = useFirestore()
  const { user } = useAuth();
  const { onCheckBox } = useFirestore();

  const onButtonClick = (id, value) => {
    setTimeout(function() {
      isTypeWriterMounted(false);
      stopTyping(false);
      const nextStory = stories.find(item => item.parentId === id && item.title === value);
      if(story["bgrImg"]!==nextStory["bgrImg"]) {
        setOverlay(true);
      } else {
        setOverlay(false)
      }
      isNextBtnNonActive(true);
      setQuestionsDelay(true);
      isQuestionTyping(true);
      renderTypewriter(false);
      showAnswers(true);
      setTimeout(function() {
        renderTypewriter(true);
      }, timeOutsConstants.CHANGE_TYPEWRITER_STATE_DELAY_NOT_MAIN);
      setTimeout(function() {
        setOverlay(false);
      }, timeOutsConstants.REMOVE_OVERLAY_DELAY);
      push(id, value);
    }, timeOutsConstants.ANSWER_BUTTON_DELAY);
  };

  return (
    <div className='free-btn slides-btn'>
      {currentButton.value === item.value ?
      <React.Fragment>
        <img src={Save} alt='' className='float-right mt-3' onClick={save} />
        <span className='position-relative d-flex justify-content-center align-items-center ml-3'>
          <div className='my-tabs d-flex justify-content-center align-items-center' key={item.value}><textarea value={newBtn} onChange={handleWaysChange} className='form-control' /></div>
        </span>
      </React.Fragment> :
      <React.Fragment>
        {user.admin ?
        <React.Fragment>
          <label><input type='checkbox' checked={item.paid}
               onChange={() => onCheckBox(story.id, story.ways, { ...item, paid: !item.paid })} />
          </label>
          <img src={Edit} alt='' className='float-right mt-3' onClick={() => {
            changeCurrentBtn(item)
            return addNewBtn(item.value)
            }} />
        </React.Fragment> : null}
        <span className='position-relative'>
          <div className='my-tabs' onClick={() => onButtonClick(story.id, item.value)} >
            {item.value}</div>
        </span>
        {user.admin &&
        <label><input type='checkbox'
            checked={item.published}
            onChange={() => onCheckBox(story.id, story.ways, { ...item, published: !item.published })}
          /></label>}
      </React.Fragment>}
    </div>
  );
}

const mapStateToProps = state => ({
  currentStoryProps: state.currentStoryState
});

export default connect(mapStateToProps, { setOverlay, addNewBtn, changeCurrentBtn, renderTypewriter,
  stopTyping, isNextBtnNonActive, setQuestionsDelay, isQuestionTyping, showAnswers, isTypeWriterMounted })(SlidesButtonFree);
