import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { useFirestore } from '../../context/FirestoreContext';
import { useAuth } from '../../context/AuthContext';

import { addSortedMainstories } from '../../actions/storiesPageActions';

import LockedStoryIcon from '../../assets/icons/locked_story_icon.svg';

import './index.css';


const LockedStoryOverlay = ({ openStories, localCompletedStory, addSortedMainstories, docId }) => {

  const { stories } = useFirestore();
  const { user } = useAuth();


  useEffect(() => {
    let mainstories = [];
    stories.map(story => {
      if (story.parentId === 'main') {
        mainstories.push(story);
      }
    });
    mainstories.sort((a,b) => (a.index - b.index));
    addSortedMainstories(mainstories, user.completedStories, localCompletedStory);
  }, [localCompletedStory, user.completedStories]);

  return (
    <React.Fragment>
      {!openStories.find(storyId => storyId === docId) ?
        <div className='lockStoryOverlay' data-toggle='modal' data-target='#lockStoryModal'>
          <img src={LockedStoryIcon} alt='' />
        </div> :
        null}
    </React.Fragment>
  );

};

const mapStateToProps = state => ({
  openStories: state.storiesPageState.openStories,
  localCompletedStory: state.userInteractionState.localCompletedStory
});

export default connect(mapStateToProps, { addSortedMainstories })(LockedStoryOverlay);
