import React from 'react';

import './profile.css';

const ProfileModal = ({ onDelete }) => {
  return (
    <div className='modal' id='profileDeleteModal'>
      <div className='modal-dialog modal-xl modal-dialog-centered'>
        <div className='modal-content'>
          <button type='button' className='close' data-dismiss='modal'>
            &times;
          </button>
          <h1 className='modal-h1'>Are you sure?</h1>
          <p>Any unused tokens will be removed and will not be refunded</p>
          <div className='profileDeleteModalBtns'>
            <button className='modal-button  mr-5' data-dismiss='modal'>
              No
            </button>
            <button
              className='modal-button deleteProfile'
              data-dismiss='modal'
              onClick={onDelete}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;
