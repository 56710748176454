import React from 'react';
import { connect } from 'react-redux';

import { addToFindedStories } from '../../actions/storiesPageActions';

import searchIcon from '../../assets/images/search_icon.png';

import './index.css';


const SearchInput = ({ search, addToFindedStories }) => {

    async function onSearch(e) {
        e.preventDefault();
        const searchResult = search(e.target.value);
        addToFindedStories(searchResult);
    }

    function onFocusOut (e){
      e.preventDefault();
      addToFindedStories([]);
    }

    return (
      <React.Fragment>
        <input
          className='mb-3 inp'
          type='search'
          placeholder='Search story'
          aria-label='Search'
          onChange={onSearch}
          style={{ backgroundImage: `url(${searchIcon})` }}
          onBlur={onFocusOut}
          onFocus={onSearch}
        />
      </React.Fragment>
    );
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { addToFindedStories })(SearchInput);
