import React from 'react';
import { connect } from 'react-redux';

import { setQuestion } from '../../../../../actions/currentStoryActions';

import SlidesButtons from './../../../slidesButtons/SlidesButtons';


const QuestionEdit = ({ currentStoryProps, story, setQuestion }) => {

  const { question } = currentStoryProps;

  const handleChangeQuestion = (event) => {
    setQuestion(event.target.value);
  };

  return (
    <React.Fragment>
      <textarea value={question} onChange={handleChangeQuestion} className='form-control'></textarea>
      <div className='slides-buttons-container'>
        <SlidesButtons story={story} />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  currentStoryProps: state.currentStoryState
});

export default connect(mapStateToProps, { setQuestion })(QuestionEdit);
