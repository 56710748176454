import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { isEdit, setQuestion, setDescription } from '../../../actions/currentStoryActions';
import { setToStart } from '../../../actions/userInteractionActions';
import { setTypewriterState, showAnswers } from '../../../actions/userExperienceActions';

import { useAuth } from '../../../context/AuthContext';
import { useFirestore } from '../../../context/FirestoreContext';

import './SlidesAdminButtons.css';

const SlidesAdminButtons = ({ setToStart, currentStoryProps, isEdit, setQuestion,
            setDescription, story, editMode, text, setTypewriterState, showAnswers }) => {

  const { edit, num, question, description } = currentStoryProps;

  const history = useHistory();
  const { user } = useAuth();
  const { deleteStory, update } = useFirestore();

  const onAdminButtonClick = (description, question) => {
    isEdit(true);
    setDescription(description);
    setQuestion(question);
  };

  const onDeleteClick = () => {
    showAnswers(true);
    setTypewriterState(0);
    setToStart();
    localStorage.removeItem('localCompletedStory');
    deleteStory(story.id);
    history.push('/');
  }

  return (
    <React.Fragment>
      {user.admin ?
      <div className='col-6 mt-5'>
        { edit ? <div>
            <button className='border-0 my-button' onClick={() => {
              if (text[num]) {
                update(story.id, { description: description });
                return isEdit(false);
              }
              update(story.id, { question: question });
              isEdit(false);
              }}>Save</button>
            <button className='btn border-0 my-button float-right' onClick={() => isEdit(false)}>Close</button>
          </div> :
          <div>
            <button className='btn border-0 my-button' onClick={(event) => editMode(event)}>Change Story Mode</button>
          <button className='border-0 my-button' onClick={() => onAdminButtonClick(story.description, story.question)}>
            Edit Content
          </button>
        </div>} </div> : null}
      {user.admin && story.parentId === 'main' ?
      <div className='buttonBack'><button className='btnD' onClick={() => onDeleteClick()}>Delete</button></div> : null}
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  currentStoryProps: state.currentStoryState
});

export default connect(mapStateToProps, { setToStart, isEdit, setQuestion,
          setDescription, setTypewriterState, showAnswers })(SlidesAdminButtons);
