import React, { useRef } from 'react';
import ReactStars from "react-rating-stars-component";
import { connect } from 'react-redux';
import emailjs from 'emailjs-com';

import useWindowSize from '../../hooks/useWindowSize';

import { addRating, addFeedbackError } from '../../actions/currentStoryActions';
import { addFeedbackPath } from '../../actions/homeRouteActions';
import { useFirestore } from '../../context/FirestoreContext';
import { useParams, useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

import SlidesHamButton from '../../pages/Slides/slidesHamButton/SlidesHamButton';
import SlidesNav from '../../pages/Slides/slidesNav/SlidesNav';

import BookButton from '../../components/bookButton';
import SlidesModal from '../Slides/slidesModal/SlidesModal';
import StarComponent from '../../components/ratingStar/RatingStar';

import checkBoxIcon from '../../assets/icons/checkbox.svg';

import './index.css';


const FeedBack = ({ rating, feedBackError, addRating, addFeedbackError, addFeedbackPath }) => {

    const history = useHistory();
    const params = useParams();
    const commentRef = useRef();

    const { width } = useWindowSize();

    const { onCommitSubmit, getCommitInfo } = useFirestore();
    const { user } = useAuth();

    function changeRating(newRating) {
      addRating(newRating);
    }

    const serviceId = `${process.env.REACT_APP_EMAIL_SERVICE_ID}`;
    const templateId = `${process.env.REACT_APP_EMAIL_TEMPLATE_ID}`;
    const userId = `${process.env.REACT_APP_EMAIL_USER_ID}`;

    let screenSize;
    if (width <= 625) {
      screenSize = 0;
    } else {
      screenSize = 1;
    }

    async function onDone() {

      const commitInfo = {
        storyId: params.id,
        userId: user.uid,
        commit: commentRef.current.value,
        rating: rating,
        id: '',
      }

      if(rating === 0) {
        addFeedbackError('Mark the story by stars');
        return;
      } else if (commentRef.current.value === '') {

      } else if (commentRef.current.value.match(/^\s*$/g)) {
        addFeedbackError('Entering only spaces is not allowed');
        return;
      } else if(commentRef.current.value.match(/^[0-9]*$/g)) {
        addFeedbackError('Entering only numbers not allowed');
        return;
      } else if(commentRef.current.value.match(/(<([^>]+)>)/ig)) {
        addFeedbackError('No html tags allowed');
        return;
      }

      const avatar = user.avatar ? user.avatar : '';

      await onCommitSubmit(params.id, rating, commentRef.current.value, user.uid, user.email, avatar);
      const commitId = await getCommitInfo(params.id, rating, commentRef.current.value, user.uid);
      addRating(0);
      commitInfo.id = commitId;



      addFeedbackPath('');
      history.push('/');
    }

    return (
      <React.Fragment>
          <div className='feedbackContainer'>
            <SlidesHamButton />
            <SlidesNav />
            <div className='container-fluid col-12 d-flex align-items-center justify-content-center p-5 flex-column' >
              <div className='head col-10 d-flex flex-row'>
              <div className='backModalBtnContainer'>
                <BookButton />
                <SlidesModal />
              </div>
              </div>
              <h2 className='feedbackHeader'>Please rate the story</h2>
                <ReactStars
                  classNames='starFeedback'
                  count={5}
                  a11y={false}
                  edit={true}
                  onChange={changeRating}
                  value={rating}
                  size={screenSize === 1 ? 38 : 26}
                  isHalf={false}
                  emptyIcon={<StarComponent  isEmpty={true} size={screenSize === 1 ? 38 : 26} />}
                  filledIcon={<StarComponent isEmpty={false} size={screenSize === 1 ? 38 : 26} />}
                />
            <textarea className='cartF mt-4 mb-4' ref={commentRef} id='feedback-input-field' name='feedback-input-field' rows='6' cols='50' />
            <p className='errorMessage'>{feedBackError}</p>
            <button className='border-0 feedBackButton' onClick={onDone}>Submit</button>
          </div>
        </div>
        <div className='feedbackMobileFooter'>
          <div className='bookBtnWrapper'><BookButton /></div>
          <button className='feedBackMobileButton' onClick={onDone}><img src={checkBoxIcon} alt='' /></button>
        </div>
      </React.Fragment>
    );
}

const mapStateToProps = state => ({
  rating: state.currentStoryState.currentRating,
  feedBackError:  state.currentStoryState.feedBackError
});

export default connect(mapStateToProps, { addRating, addFeedbackError, addFeedbackPath })(FeedBack);
