import React, { useRef, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { addSignUpPageError, isSignUpPageLoading } from '../../actions/registrationActions';
import { fromLoginPage } from '../../actions/authActions';

import { useAuth } from '../../context/AuthContext.js';

import SlidesHamButton from '../../pages/Slides/slidesHamButton/SlidesHamButton';
import SlidesNav from '../../pages/Slides/slidesNav/SlidesNav';

import logo from '../../essentials/logo.svg';

import backBtn from '../../assets/icons/back-btn-oval.svg';

import './index.css';


function Login({ fromLoginPage, homeRouteProps, logInError, isAuthSuccessful,
                storyId, addSignUpPageError, isSignUpPageLoading }) {

  const history = useHistory();

  const emailRef = useRef();
  const passwordRef = useRef();

  const { login } = useAuth();

  const { overEighteen, termsAgree, feedBackPathname, isFeedbackBtnClicked } = homeRouteProps;
  const isAllHomeRoutePropsTrue = overEighteen && termsAgree;


  useEffect(() => {
    if (isAuthSuccessful) {
      emailRef.current.value = '';
      passwordRef.current.value = '';
      addSignUpPageError('');
      if ((feedBackPathname !== '') && isFeedbackBtnClicked) {
        return history.push(`/feedback/${feedBackPathname}`);
      } else if (storyId === '') {
        return history.push('/');
      } else {
        return history.push(`/story/${storyId}`);
      }
    }
  }, [isAuthSuccessful]);

  function push() {
    return history.push('/registration');
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      isSignUpPageLoading(true);
      fromLoginPage(true);
      await login(emailRef.current.value, passwordRef.current.value);
    } catch {
      addSignUpPageError('Failed to log in');
    }
    isSignUpPageLoading(false);
  }

  function goBack () {
    addSignUpPageError('');
    history.push('/login');
  }

  return (
    <React.Fragment>
      <div className='containerLogin'>
        <SlidesHamButton />
        <SlidesNav />
        <div className='closeBtnWrapperLogin'>
          <button type='button' className='closeBtnLogin' onClick={()=> goBack()}>
            <img src={backBtn} alt='' height='15px'/>
          </button>
        </div>
        <div className='form-headerLogin'>
          <div>
            <img src={logo} alt='' className='profile-imagelogin' />
          </div>
        </div>
        <div className='form-bodyLogin'>
          <input
            type='text'
            placeholder='Email'
            className='form-inpu'
            ref={emailRef}
          />
          <input
            type='password'
            placeholder='Password'
            className='form-inpu'
            ref={passwordRef}
          />
        </div>
        <button className='sing-in-page-btn' onClick={handleSubmit}>
          Login
        </button>
        {isAllHomeRoutePropsTrue ?
          <button className='sing-in-page-btn' onClick={push}>
            Registration
          </button> :
          null
        }
        {logInError ?
          <div className='error mb-5'>User or password incorrect, try again</div> :
          null
        }
        <div className='forgot-password'>
          <NavLink to='/forgot-password'>
            Forgot password?
          </NavLink>
        </div>
    </div>
  </React.Fragment>
  );
}

const mapStateToProps = state => ({
  homeRouteProps: state.homeRouteState,
  logInError: state.registrationState.logInError,
  isAuthSuccessful: state.authState.isAuthSuccessful,
  storyId: state.userInteractionState.storyId,
});

export default connect(mapStateToProps, { fromLoginPage, addSignUpPageError,
                                          isSignUpPageLoading })(Login);
