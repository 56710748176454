import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store';

import Strip from './components/strip';

import './index.css';


function App() {
  return (
    <Provider store={store}>
      <Router>
        <Strip />
      </Router>
    </Provider>
  );
}

export default App;
