import React from 'react';
import { connect } from 'react-redux';

import './EducationalOverlay.css';

const EducationalOverlay = ({ educationalOverlay }) => {
  return educationalOverlay ? <div id='educationalOverlay'/> : null;
};

const mapStateToProps = state => ({
  educationalOverlay: state.buyingTicketsState.educationalOverlay
});

export default connect(mapStateToProps)(EducationalOverlay);
