import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ReactStars from "react-rating-stars-component";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';

import './index.css';

import { useFirestore } from '../../context/FirestoreContext';
import { useAuth } from '../../context/AuthContext';
import useWindowSize from '../../hooks/useWindowSize';

import * as timeOutsConstants from '../../constants/timeOutsConstants';

import CommitsModal from './CommitsModal';
import LockedStoryModal from './LockedStoryModal';
import LockedStoryOverlay from './LockedStoryOverlay';
import UnlockedStoryOverlay from './UnlockedStoryOverlay';
import NotCachedStoryOverlay from './NotCachedStoryOverlay';

import { successfulAuthorization, fromLoginPage } from '../../actions/authActions';
import { setOverlay, renderTypewriter, setTypewriterState, showAnswers } from '../../actions/userExperienceActions';
import { isMobileNavOpen, changePageNum, setImgsToNull, getResizedBgrImgAction, getResizedSmpictAction  } from '../../actions/currentStoryActions';
import { setNewStoryAnimation, setToStart } from '../../actions/userInteractionActions';
import { changeParentId, changeCurrentId, addWays, isMain, addAudio, addImg,
        addBgrImg, addSmpict, addBtns, changeMode, addTitle, changeList,
        changeMainId, getMainImgs, addSortedMainstories } from '../../actions/storiesPageActions';

import SlidesOverlay  from '../Slides/slidesOverlay/SlidesOverlay';

import StarComponent from '../../components/ratingStar/RatingStar';
import Header from '../../components/mainLayout/header'
import Edit from '../../assets/images/edit-button.png'
import Placeholder from '../../assets/icons/Placeholder.svg';
import Loading from '../../components/loading/Loading';

const SearchHistory = ({ storiesPageProps, userInteractionProps, setToStart, renderTypewriter, fromLoginPage,
                        isMobileNavOpen, changePageNum, changeParentId, setImgsToNull, setTypewriterState, showAnswers,
                        changeCurrentId, addWays, isMain, addAudio, addImg, addSortedMainstories, newStoryanimation,
                        addBgrImg, addSmpict, addBtns,changeMode, addTitle, localCompletedStory, getResizedBgrImgAction, getResizedSmpictAction,
                        changeList, changeMainId, getMainImgs, setOverlay, successfulAuthorization, fromLogin }) => {

    const { stories, createWay, updateParent, downloadImages, downloadImageBgr, saveAudio,
            updateParentWays, setLoading, loading, updateList, onPublish, saveSmPict,
            updateImg, updateBgr, updateAudio, updateSmPic, getResizedStoryImg,
            addToCompletedStories, getResizedBgrImg, getResizedSmpict,updateStoryMode,updateStoryPictures } = useFirestore();

    const { user, currentUser } = useAuth();
    const [pressed,setPressed] = useState(false)
    const history = useHistory();

    const { width } = useWindowSize();

    const isStandAlone = window.matchMedia('(display-mode: standalone)').matches;

    const { parentId, currentId, audio, img, bgrImg, smpict, btns, mode, findedStories, title, list, ways, mainId, mainImgs, sortedStories, openStories } = storiesPageProps;
    const [ modeOfStory,setModeOfStory] = useState("Default")
    const [isReuseChecked,setIsReuseChecked] = useState(false)
    const { register, handleSubmit } = useForm();
    const [isLogoActive,setIsLogoActive] = useState(true)
    const [isAllLoaded,setIsAllLoaded] = useState(false)
    const [counter,setCounter] = useState(1)
    const [isNewAnimation, setIsNemAnimation] = useState(false)
    const titleRef = useRef();
    const questionRef = useRef();
    const descriptionRef = useRef();
    const tokensRef = useRef({value: 0});

    const lastOpenStory = openStories[openStories.length - 1];


    let screenSize;
    if (width <= 625) {
      screenSize = 0;
    } else {
      screenSize = 1;
    }

    function changeToInteractionId(id) {
      const haveStoryBeenCached = !!localStorage.getItem(`cashedStorywithId-${id}`);
      const isOnline = navigator.onLine;
      const num = userInteractionProps.num;
      const storyId = userInteractionProps.storyId;
      const mainStoryId = userInteractionProps.mainStoryId;
      if ((!openStories.find(storyId => storyId === id)) && !user.admin) {
        return;
      } else if (!haveStoryBeenCached && !isOnline) {
        return;
      }
      setOverlay(true);
      setTimeout(function() {
        setOverlay(false);
      }, timeOutsConstants.REMOVE_OVERLAY_DELAY);
      if ((mainStoryId !== '') && (mainStoryId !== id)) {
        showAnswers(true);
        setTypewriterState(0);
        setToStart();
        setImgsToNull();
        setOverlay(true);
        renderTypewriter(false);
        push(id);
        changePageNum(0);
      } else if (userInteractionProps.isMain === true) {
        push(id);
        changePageNum(0);
      } else if (storyId === '') {
        push(id);
        changePageNum(num);
      } else if (mainStoryId === id) {
        push(storyId);
        changePageNum(num);
      } else {
        push(id);
        changePageNum(0);
      }
    };


    const onSubmit = async (data) => {
        if (!data) return;
        setLoading(true);
        if (parentId === 'main') {
            const name = await downloadImages(data);
            addImg(name);
        }
        const bgr = await downloadImageBgr(data);
        const audio = await saveAudio(data);
        const smpict = await saveSmPict(data);
        addSmpict(smpict)
        addBgrImg(bgr);

        addAudio(audio);
        setLoading(false)
    }
    const onSubmitImg = async (data) => {
        if (!data) return;
        setLoading(true);
        const name = await updateImg(data, img)
        addImg(name);
        setLoading(false);

    }
    const onSubmitBgr = async (data) => {
        if (!data) return;
        setLoading(true);
        const name = await updateBgr(data, bgrImg)
        addBgrImg(name)
        setLoading(false)
    }
    const onUpdateSmPic = async (data) => {
        if (!data) return;
        setLoading(true);
        const name = await updateSmPic(data, smpict)
        addBgrImg(name)
        setLoading(false)
    }
    const onUpdateAudio = async (data) => {
        if (!data) return;
        setLoading(true);
        const name = await updateAudio(data, storiesPageProps.audio)
        addAudio(name)
        setLoading(false)
    }



    async function save(event) {
      event.preventDefault();
      const tokensAmount = tokensRef.current !== undefined ? parseInt(tokensRef.current.value) : null;
      let body = { title: titleRef.current.value, question: questionRef.current.value, description: descriptionRef.current.value, tokens: tokensAmount, img, bgrImg, audio, smpict, mode};
      const story = stories.find(story => story.id === currentId);
      let parentStory;

      if (story) {
        parentStory = stories.find(parentStory => parentStory.id === story.parentId);
        if (story.parentId !== 'main') {
          const newWayIndex = parentStory.ways.findIndex(way => way.value === 'no title');
          const existedWayIndex = parentStory.ways.findIndex(way => way.value === story.title);

          parentStory.ways[`${(newWayIndex !== -1) ? newWayIndex : existedWayIndex}`].value = body.title;
          parentStory.ways[`${(newWayIndex !== -1) ? newWayIndex : existedWayIndex}`].tokens = body.tokens;
          updateParentWays(parentStory.id, parentStory.ways);
        }
      } else if (!story) {
        parentStory = stories.find(parentStory => parentStory.id === parentId);
        if (parentStory) {
          const newWayIndex = parentStory.ways.findIndex(way => way.value === 'no title');
          parentStory.ways[newWayIndex].value = body.title;
          parentStory.ways[newWayIndex].tokens = body.tokens;
          updateParentWays(parentStory.id, parentStory.ways);
        }
      }
      if (story) {
        await updateParent(story.id, { mainId, ...body });
        changeCurrentId(story.id);
        return changeParentId(parentId);
      }

      if (parentId === 'main') {
        const id = await createWay(parentId, { published: false, ...body }, ways);
        changeMainId(id);
        changeParentId(parentId)
        changeCurrentId(id);
        return;
      }

      const storyId = await createWay(parentId, { mainId, ...body }, ways);
      changeParentId(parentId)
      changeCurrentId(storyId);
      changeList([...list, { storyId, bgrImg, audio, img, smpict }])

    }

    async function create(event) {
        event.preventDefault();
        addTitle(event.target.value);
        const story = stories.find(story => story.id === currentId);
        if (story) {
            if (parentId === 'main') {
                changeParentId(story.id);
            }
            isMain(false);
            await updateParentWays(story.id, [...ways, ...btns]);
            titleRef.current.value = '';
            descriptionRef.current.value = '';
            questionRef.current.value = '';
            if (parentId !== 'main') {
              tokensRef.current.value = 0; //TODO
            }
            changeParentId(story.id)
            addImg('');
            addBgrImg('');
            addAudio('');
            addWays([]);
            addBtns([]);
            changeCurrentId();

            return;
        }

        const body = { title: titleRef.current.value, question: questionRef.current.value, description: descriptionRef.current.value, tokens: parseInt(tokensRef.current.value), img, bgrImg, audio, smpict, mode};
        if (parentId === 'main') {
            const id = await createWay(parentId, { published: false, ...body }, ways);
            changeParentId(id);
            changeMainId(id)
            isMain(false);
            titleRef.current.value = '';
            descriptionRef.current.value = '';
            questionRef.current.value = '';
            if (parentId !== 'main') {
              tokensRef.current.value = 0; //TODO
            }
            addImg('');
            addBgrImg('');
            addAudio('');
            addSmpict('');
            addWays([]);
            addBtns([]);
            changeCurrentId();
            return;
        }
        const storyId = await createWay(parentId, { mainId, ...body }, ways,);
        changeParentId(storyId);
        changeList([...list, { storyId, bgrImg, audio, img, smpict }]);
        isMain(false);
        titleRef.current.value = '';
        descriptionRef.current.value = '';
        questionRef.current.value = '';
        if (parentId !== 'main') {
          tokensRef.current.value = 0; //TODO
        }
        addImg('');
        addBgrImg('');
        addAudio('');
        addSmpict('');
        addWays([]);
        addBtns([]);
        changeCurrentId();
    }

    function existWay(event, id, name) {
      event.preventDefault();
      addTitle(name.value);
      const story = stories.find(item => item.parentId === id && item.title === name.value);
      if (!story) {
        titleRef.current.value = '';
        descriptionRef.current.value = '';
        questionRef.current.value = '';
        addImg('');
        addBgrImg('');
        addAudio('');
        addSmpict('');
        addWays([]);
        changeCurrentId();
        isMain(false);
        addBtns([])
        return changeParentId(currentId);
      }
      isMain(false)
      addTitle(story.title)
      changeParentId(story.parentId);
      addImg(story.img);
      addBgrImg(story.bgrImg);
      changeMode(story.mode)
      addAudio(story.audio);
      addSmpict(story.smpict)
      questionRef.current.value = story.question;
      titleRef.current.value = story.title;
      descriptionRef.current.value = story.description;
      tokensRef.current.value = story.tokens;
      changeCurrentId(story.id);
      const btn = story.ways.map(item => item)
      addBtns(btn)
    }

    function onBack(event) {
        event.preventDefault();
        let story = stories.find(story => story.id === parentId);
        if (!story) {
          story = stories.find(story => story.id === currentId);
        }
        addTitle(story.title);
        if (parentId === 'main') {
            isMain(true);
            changeParentId(story.id);
            addWays([]);
        }

        isMain(false)
        titleRef.current.value = story.title;
        descriptionRef.current.value = story.description;
        questionRef.current.value = story.question;
        tokensRef.current.value = story.tokens;
        addImg(story.img);
        addBgrImg(story.bgrImg);
        addAudio(story.audio);
        addSmpict(story.smpict)
        changeMode(story.mode)
        addWays([]);
        changeCurrentId(story.id);
        changeParentId(story.parentId)
        const btn = story.ways.map(item => item)
        addBtns(btn)
    }

    function editStory(id) {
        const story = stories.find(title => title.id === id);
        isMain(true);
        changeMainId(story.id)
        changeList(story.list || [])
        titleRef.current.value = story.title;
        descriptionRef.current.value = story.description;
        questionRef.current.value = story.question;
        if (parentId !== 'main') {
          tokensRef.current.value = story.tokens; //TODO
        }
        addTitle(titleRef.current.value);
        addImg(story.img);
        addBgrImg(story.bgrImg);
        addAudio(story.audio);
        addSmpict(story.smpict)
        changeMode(story.mode)
        addWays([]);
        changeCurrentId(story.id);
        changeParentId(story.parentId)
        const btn = story.ways.map(item => item)
        addBtns(btn)

    }

    function push(id) {
        return history.push(`/story/${id}`);
    }


    async function onClose(event) {
        event.preventDefault();
        setIsReuseChecked(false)
       if (list) {
            updateList(mainId, list)
        }

        changeList('');
        changeMainId();
        titleRef.current.value = '';
        descriptionRef.current.value = '';
        questionRef.current.value = '';
        tokensRef.current.value = 0;
        addTitle('');
        changeCurrentId();
        changeParentId('main');
        addWays([]);
        addBtns([]);
        addImg('');
        addBgrImg('');
        addAudio('');
        addSmpict('');
        isMain(true);
    }

    function setWay(event, paid) {
        event.preventDefault();
        addWays([{ value: 'no title', paid, published: true }, ...ways])
    }

    useEffect(() => {
      if (!!userInteractionProps.storyId) {
        history.push(`/story/${userInteractionProps.storyId}`);
      }
    }, [userInteractionProps.storyId]);
    const curStory = localStorage.getItem("currentStoryId")
    if (curStory){
      localStorage.removeItem("currentStoryId")
    }
    useEffect(() => {
      setTimeout(function() {
        setTypewriterState(0);
      }, 1000);
    }, []);

    useEffect(() => {
      showAnswers(true);
      successfulAuthorization(false);
      try {
        if (user.completedStories) {
          const notInArr = !(user.completedStories.find(id => id === localCompletedStory));
          if (notInArr) {
            if (localCompletedStory) {
              addToCompletedStories(currentUser, localCompletedStory);
            }
          }
        }
      } catch (err) {
        throw err;
      }
      getMainImgs(stories, getResizedStoryImg);
      isMobileNavOpen(false);

      let mainstories = [];
      stories.map(story => {
        if (story.parentId === 'main') {
          mainstories.push(story);
        }
      });
      mainstories.sort((a,b) => (a.index - b.index));
      addSortedMainstories(mainstories, user.completedStories, localCompletedStory);
    }, [stories]);

    useEffect(() => {

      let screenSize;
      if (width <= 425) {
        screenSize = 0;
      } else if (width <= 768) {
        screenSize = 1;
      } else {
        screenSize = 2;
      }

      const mainOpenStories = stories.filter(story => openStories.find(openStory => openStory === story.id));
      const subsidiaryStories = stories.filter(story => openStories.find(openStory => (openStory === story.mainId) && (story.parentId !== 'main')));
      const storiesToBeCashed = [...mainOpenStories, ...subsidiaryStories];

      if (navigator.onLine) {
        openStories.map(openStory => localStorage.setItem(`cashedStorywithId-${openStory}`, openStory));
        storiesToBeCashed.map(story => getResizedBgrImgAction(story, getResizedBgrImg, screenSize));
        storiesToBeCashed.map(story => getResizedSmpictAction(story, getResizedSmpict, screenSize));
      }

    }, [openStories]);

    useLayoutEffect(()=>{
      const element = document.getElementById("main-header");
      element.scrollIntoView();
    },[])

    useEffect(() => {
      if(fromLogin) {
        setTimeout(function() {
          fromLoginPage(false);
        }, 3000);
      }
    }, [fromLogin]);

    function search (term) {
      if (term.length === 0) return sortedStories;
      const data = sortedStories.filter((item) => term.toLowerCase() ? item.title.toLowerCase().indexOf(term.toLowerCase()) > -1 : item.title.indexOf(term) > -1)
      return data;
    }

    useEffect(()=>{
      if(newStoryanimation){
        setIsNemAnimation(true)
      }
    },[newStoryanimation])

    const calculateBlockCount = () => {
      const blockWidth = 280;
      const blockHeight = 202;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
  
      const blocksPerRow = Math.floor(screenWidth / blockWidth);
      const blocksPerColumn = Math.floor(screenHeight / blockHeight);

      const blockCount = blocksPerRow * blocksPerColumn;

  
      return Math.min(toggleSearch.length, blockCount);
    };

    function onLoad() {
      const amountOfBlocks = calculateBlockCount()
      if (counter >= amountOfBlocks-2){
        setTimeout(function() {
          const content = document.querySelector(".fullContent");
          content.style.opacity = "1";
          setIsLogoActive(false)
          setTimeout(()=>{setIsAllLoaded(true)},1500)
        }, 2000); 
      } else{
        setCounter(prev=>prev+1)
      }
    }

    async function editMode(e) {
      e.preventDefault()
      if(modeOfStory === 'Dark') {
        await updateStoryMode(currentId, 'Dark');
      } else {
        await updateStoryMode(currentId, 'Default');
      }
    }

    async function isReusePressed(){
      if (!isReuseChecked) {
        let story = stories.find(story => story.id === parentId);
        addSmpict(story['smpict']);
        addBgrImg(story['bgrImg'])
      }
      setIsReuseChecked(!isReuseChecked)
    }

  const toggleSearch = findedStories[0] ? findedStories : sortedStories;
  
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(()=>{
    if (toggleSearch.length===0){
      setIsLogoActive(false)
      setIsAllLoaded(true)
      const content = document.querySelector(".fullContent");
      content.style.opacity = "1";
    }
  })

  const story = toggleSearch.map(doc => {

    const title = doc.title.slice(0, 10);

    if (user.admin) {
      return (
        <div className='story-wrapper' key={doc.id} >
          <span className='row'><img src={Edit} alt='' data-toggle='modal' data-target='#myModal' onClick={() => editStory(doc.id)} /></span>
          <span className='story-wrap' onClick={() => changeToInteractionId(doc.id)}>
            <LazyLoadImage
              className='story'
              effect='blur'
              alt=''
              src={localStorage.getItem(`mainImg-${doc.id}`) ? localStorage.getItem(`mainImg-${doc.id}`) : Placeholder} 
              onLoad={()=>onLoad(doc.id)} />
          </span>
          <span className='story-description'>
            <div className='story-text'>{title}</div>
            <span className='story-description-rating'>
              {doc.rating ? <div className='commitsLink' data-toggle='modal' data-target={`#commitsModal_${doc.id}`} onClick={()=>{setPressed(true)}}>
                <ReactStars
                  classNames='starMain'
                  count={5}
                  a11y={false}
                  edit={false}
                  value={doc.rating}
                  size={screenSize === 1 ? 15 : 10}
                  isHalf={false}
                  emptyIcon={<StarComponent isEmpty={true} size={screenSize === 1 ? 15 : 10} />}
                  filledIcon={<StarComponent isEmpty={false} size={screenSize === 1 ? 15 : 10} />}
                />
                <span>
                  ({doc.numberReviews > 99 ? '99+' : doc.numberReviews})
                </span>
              </div>: null}
                <label>
                  <input type='checkbox'
                        checked={doc.published}
                        onChange={() => onPublish(doc.id, !doc.published)} />
              </label>
          </span>
        </span>
        <CommitsModal screenSize={screenSize} storyId={doc.id} storyTitle={doc.title} pressed={pressed} />
      </div>
    );

    } else if (!user.admin && doc.published) {
        return <div className='story-wrapper' key={doc.id} >
                <span className='story-wrap' onClick={() => changeToInteractionId(doc.id)}>
                  <div className='lockStoryOverlayWrapper' style={{ backgroundImage: `url(${Placeholder})` }}>
                    <LockedStoryOverlay docId={doc.id} />
                    <UnlockedStoryOverlay docId={doc.id} isAllLoaded={isAllLoaded} isNewAnimation={isNewAnimation}/>
                    <NotCachedStoryOverlay docId={doc.id} />
                    <LazyLoadImage
                      effect='blur'
                      className={`story ${ (lastOpenStory === doc.id) && isNewAnimation && isAllLoaded ? 'animate' : ''}`} 
                      alt=''
                      src={localStorage.getItem(`mainImg-${doc.id}`) ? localStorage.getItem(`mainImg-${doc.id}`) : ''} 
                      onLoad={()=>onLoad(doc.id)} 
                      />
                  </div>
                </span>
                <span className='story-description'>
                    <div className='story-text'>{title}</div>
                    <span className='story-description-rating'>
                    {doc.rating ? <div className='commitsLink' data-toggle='modal' data-target={`#commitsModal_${doc.id}`} onClick={()=>{setPressed(true)}}>
                        <ReactStars
                          classNames='starMain'
                          count={5}
                          a11y={false}
                          edit={false}
                          value={doc.rating}
                          size={screenSize === 1 ? 15 : 10}
                          isHalf={false}
                          emptyIcon={<StarComponent isEmpty={true} size={screenSize === 1 ? 15 : 10} />}
                          filledIcon={<StarComponent isEmpty={false} size={screenSize === 1 ? 15 : 10} />}
                        />
                        <span>
                        ({doc.numberReviews > 99 ? '99+' : doc.numberReviews})
                    </span>
                  </div>: null}
                    </span>
                </span>
                <CommitsModal screenSize={screenSize} storyId={doc.id} storyTitle={doc.title} pressed={pressed} />
            </div>
   } else {
      return false;
      }

    });

    const way = ways.map(way => way.paid ?
      <button className='btn btn-warning wayPaid' onClick={(event) => create(event)} value={way.value} key={way.value} disabled={loading ? true : false} >
        {way.value}</button> :
      <button className='btn btn-primary wayFree' onClick={(event) => create(event)} value={way.value} key={way.value} disabled={loading ? true : false} >
        {way.value}</button>);

    const buttons = btns.map(bt => bt.paid ?
      <button className='btn btn-warning bntPaid' onClick={(event) => existWay(event, currentId, bt)} value={bt.value} key={bt.value} disabled={loading ? true : false} >
        {bt.value}</button> :
      <button className='btn btn-primary btnFree' onClick={(event) => existWay(event, currentId, bt)} value={bt.value} key={bt.value} disabled={loading ? true : false} >
        {bt.value}</button>);

    return (
      <div>
        <div className='overlayWrapper'>
          <SlidesOverlay />
        
          <div className='fullContent'>
            <LockedStoryModal />
            <div className='mainContent'>
              <Header search={search} isAllLoaded={isAllLoaded}/>
              {story}
            </div>
            {user.admin ?
              <React.Fragment>
                <button type='button' className='btn btn-primary' data-toggle='modal' data-target='#myModal'>Create</button>
                <div className='modal' id='myModal'>
                  <div className='modal-dialog modal-xl modal-dialog-centered'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h4 className='modal-title'>Make your story</h4>
                        <button type='button' className='close' data-dismiss='modal' onClick={onClose} disabled={loading ? true : false} >
                          &times;</button>
                      </div>
                      <div className='modal-body'>
                        {user.admin && <div className='container'>{title}
                          <div className='form-group'>
                              <textarea type='text' className='form-control' placeholder='Title' ref={titleRef} />
                              <textarea type='text' className='form-control description' placeholder='description' ref={descriptionRef} ></textarea>
                              <textarea type='text' className='form-control' placeholder='Question' ref={questionRef} ></textarea>
                                {bgrImg ?
                                  <div className='row'>
                                    {parentId === 'main' ?
                                      <React.Fragment>
                                        <label> Story Picture :
                                          <input ref={register} type='file' name='picture' className='col-7 ml-5' />
                                        </label>
                                        <button className='btn btn-light ' onClick={handleSubmit(onSubmitImg)}>Edit</button>
                                        <label className='col-12 d-none' > Price :
                                          <input defaultValue={tokensRef.current.value} className='form-control' ref={tokensRef} type='number' id='tokensAmount' name='tokensAmount' min='0' max='20'/>
                                        </label>
                                      </React.Fragment> :
                                      <label className='col-12' > Price :
                                        <input defaultValue={tokensRef.current.value} className='form-control' ref={tokensRef} type='number' id='tokensAmount' name='tokensAmount' min='0' max='20'/>
                                      </label> }
                                    {!isReuseChecked?(
                                      <React.Fragment>
                                        <label> Background Picture :
                                          <input ref={register} type='file' name='image' className='col-7' />
                                        </label>
                                        <button className='btn btn-light ' onClick={handleSubmit(onSubmitBgr)}>Edit</button>
                                        <label> Small Picture :
                                          <input ref={register} type='file' name='smpict' className='col-7 ml-5' />
                                        </label>
                                        <button className='btn btn-light' onClick={handleSubmit(onUpdateSmPic)} >Edit</button>
                                      </React.Fragment>)
                                      :(<></>)
                                    }
                                    <React.Fragment>
                                      <label> Sound Pictures:
                                        <input ref={register} type='file' name='audio' className='col-7  pl-5' />
                                      </label>
                                      <button className='btn btn-light' onClick={handleSubmit(onUpdateAudio)}>Edit</button>
                                    </React.Fragment>
                                    <div className='btn-group'>
                                      <button type='button' className='btn btn-info dropdown-toggle' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>{modeOfStory}</button>
                                      <div className='dropdown-menu'>
                                        <p className='dropdown-item' onClick={() => setModeOfStory('Default')}>Default</p>
                                        <p className='dropdown-item' onClick={() => setModeOfStory('Dark')}>Dark</p>
                                      </div>
                                      <button className='btn btn-success' onClick={e=>editMode(e)}>Save</button>
                                    </div>
                                  </div> :
                                  <React.Fragment>
                                    <div className='row'>
                                      {parentId === 'main' ?
                                        <React.Fragment>
                                          <label> Story Picture :
                                            <input ref={register} type='file' name='picture' className='col-7 ml-5' />
                                          </label>
                                          <label className='col-12 d-none' > Price :
                                            <input defaultValue={tokensRef.current.value} className='form-control' ref={tokensRef} type='number' id='tokensAmount' name='tokensAmount' min='0' max='20'/>
                                          </label>
                                        </React.Fragment> :
                                      <label className='col-12 d-none' > Price :
                                        <input defaultValue={tokensRef.current.value} className='form-control' ref={tokensRef} type='number' id='tokensAmount' name='tokensAmount' min='0' max='20'/>
                                      </label> }
                                      {!isReuseChecked?(
                                        <React.Fragment>
                                          <label> Background Picture :
                                            <input ref={register} type='file' name='image' className='col-7' />
                                          </label>
                                          <label> Small Picture :
                                            <input ref={register} type='file' name='smpict' className='col-7 ml-5' />
                                          </label>
                                        </React.Fragment>):<></>
                                      }
                                      <label> Sound Pictures:
                                        <input ref={register} type='file' name='audio' className='col-7  pl-5' />
                                      </label>
                                    </div>
                                    <div className='change-story-mode'>
                                      <button type='button' className='btn btn-info dropdown-toggle' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>{mode}</button>
                                      <div className='dropdown-menu'>
                                        <p className='dropdown-item' onClick={() => changeMode('Default')}>Default</p>
                                        <p className='dropdown-item' onClick={() => changeMode('Dark')}>Dark</p>
                                      </div>
                                    </div>
                                    <button className='btn btn-success' onClick={handleSubmit(onSubmit)}>Save</button>
                                </React.Fragment> }
                          </div>
                        </div>}
                        {parentId!=='main'?
                          <div>
                            <input type="checkbox" checked={isReuseChecked} onChange={isReusePressed}/>
                            <lable>Reuse image from last scene</lable>
                          </div>
                        :<></>}
                        <div className='modal-footer'>
                          <button className='btn btn-info btn-lg' onClick={(event) => setWay(event, false)}>Add</button>
                          <button className='btn btn-warning btn-lg' onClick={(event) => setWay(event, true)}>Add</button>
                          {way ? way : null}
                          {buttons ? buttons : null}
                          <button type='button' className='btn btn-info btn-arrow-left' onClick={onBack} disabled={loading ? true : false} >
                            Back</button>
                          <button className='btn btn-success' onClick={save} disabled={loading ? true : false} >
                            Save</button>
                          <button type='button' className='btn btn-danger' data-dismiss='modal' onClick={onClose} disabled={loading ? true : false} >
                            Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment> :
              null }

          </div>
          
        </div>
        {isLogoActive?<div className='loadeBlock'><Loading></Loading></div>:<></>}
      </div>
    );
}

const mapStateToProps = state => ({
  storiesPageProps: state.storiesPageState,
  userInteractionProps: state.userInteractionState,
  localCompletedStory: state.userInteractionState.localCompletedStory,
  newStoryanimation: state.userInteractionState.newStoryanimation,
  fromLogin: state.authState.fromLogin
});

export default connect(mapStateToProps, { setToStart, setOverlay, isMobileNavOpen, changePageNum, changeParentId, changeCurrentId, setTypewriterState, showAnswers,
                                          addWays, isMain, addAudio, addImg, addBgrImg, addSmpict, addBtns, setImgsToNull, renderTypewriter,
                                          changeMode, addTitle, changeList, changeMainId, getMainImgs, addSortedMainstories, successfulAuthorization,
                                          getResizedBgrImgAction, getResizedSmpictAction, fromLoginPage })(SearchHistory);
