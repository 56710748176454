import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Avatar from 'react-avatar';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import { addProfilePageError, getAvatar, getResizedAvatar,
        setIsFileBeenChoosen, clearAvatar } from '../../actions/authActions';
import { successfulRegistation } from '../../actions/registrationActions';

import { useAuth } from '../../context/AuthContext';
import { useFirestore } from '../../context/FirestoreContext';

import EditImageModal from './EditImageModal';
import ProfileModal from './ProfileModal';
import SlidesHamButton from '../../pages/Slides/slidesHamButton/SlidesHamButton';
import SlidesNav from '../../pages/Slides/slidesNav/SlidesNav';

import logo from '../../essentials/logo.svg';
import logoutIcon from '../../assets/icons/logout.svg';

import './profile.css';


function Profile({ authProps, addProfilePageError, getAvatar, setIsFileBeenChoosen,
                    getResizedAvatar, successfulRegistation, clearAvatar }) {

  const history = useHistory();

  const [selectedImage, setSelectedImage] = useState(null); 


  const { online, avatar, resizedAvatar, isFileBeenChoosen } = authProps;

  const { currentUser, user, logout, deleteUser } = useAuth();
  const { deleteUserF, uploadAvatar, getResizedStoryImg, deleteUserAvatar } = useFirestore();

  const isRegisteredViaEmail = currentUser.providerData[0].providerId === 'password';

  const userAvatar = online.avatar;
  const showAvatar = avatar || resizedAvatar;

  function resendEmail() {
    currentUser.sendEmailVerification().then(() => {
    });
  }

  async function handleLogout() {
    addProfilePageError('');
    try {
      await logout();
      getAvatar('');
      clearAvatar();
      history.push('/home');
    } catch {
      addProfilePageError('Failed to log out')
    }
  }

  async function onDelete() {
    await deleteUserF(currentUser.email);
    await deleteUser();
    getAvatar('');
    clearAvatar();
    history.push('/home');
  }

  const onSubmit = async (data) => {
    if (!data.image) {
      return;
    }
    if(showAvatar){
      await deleteUserAvatar(currentUser.uid, showAvatar);
    }
    try {
      const img = await uploadAvatar(data, currentUser);
      await getAvatar(img);
    } catch(err) {}
    setIsFileBeenChoosen(false);
  }



  const deleteAvatar = async () => {
    getAvatar('');
    clearAvatar();
    const img = avatar ? avatar : userAvatar;
    await deleteUserAvatar(currentUser.uid, img);
  };
  const refreshPage = () => {
    window.location.reload(true);
  };

  useEffect(() => {
    if (userAvatar && !resizedAvatar) {
      successfulRegistation(false);
      getResizedAvatar(userAvatar, getResizedStoryImg);
    }
  }, [userAvatar, avatar]);

  return (
    <div className='profileContainer'>
      <SlidesHamButton />
      <SlidesNav />
      <div className='page-container'>
        <div className='header'>
          <Link to='/'><img src={logo} alt='' /></Link>
        </div>
        {user.providerData ? user.providerData[0].providerId === 'password' && !currentUser.emailVerified ?
          <div className='emailVerifyMessage'>
            <h3 className='emailVerifyH3'>
              Please verify your account via email link and refresh the page
            </h3>
            <button className='profile-page-btn emailVerifyBtn refresh'type='button' onClick={resendEmail}>
              Resend email
            </button>
            <button className="profile-page-btn" onClick={refreshPage}>Refresh</button>
          </div>
            : null : null}
        <div className='ProfilePageContent'>
            {selectedImage?<Avatar src={URL.createObjectURL(selectedImage)} name={currentUser.email} round={true} />:<div>
            <Avatar src={showAvatar} name={currentUser.email} round={true} />
            </div>} 
              <div className='profileEmail'>{currentUser.email}</div>
                {showAvatar ?
                  <button className='profile-page-btn delete-image' onClick={deleteAvatar}>Delete Profile Image</button> :
                  null
                }
              <span className='avatarUploadContainer'>
              <button className='profile-page-btn edit' data-toggle='modal' data-target='#profileEditImageModal'>Edit Picture</button>
                {/* {isFileBeenChoosen ?
                  <React.Fragment>
                    <div style={{display:"flex"}}>
                      {!selectedImage?<label className='avatarInput' htmlFor='upload-profile-photo'>Choose image</label>:<button className='avatarInput' onClick={()=>{setSelectedImage(null)}}>Cancel</button>}
                      <input ref={register} type='file' name='image' id='upload-profile-photo' onChange={handleFileChange} />
                      <button className='profile-page-btn upload' onClick={handleSubmit(onSubmit)}>Save</button>
                    </div>
                  </React.Fragment> :
                  <></>
                } */}
              </span>
            {isRegisteredViaEmail ?
              <Link to='/changePassword'>
                <button className='profile-page-btn'>Change Password</button>
              </Link> : null}
          <Link to='/' className='logoutProfile'>
            <button className='profile-page-btn logout' onClick={handleLogout}><img src={logoutIcon} alt='' />Logout</button>
          </Link>
          <button className='profile-page-btn delete-btn' data-toggle='modal' data-target='#profileDeleteModal'>
            Delete Account
          </button>
        </div>
      </div>
      <EditImageModal userEmail={currentUser.email} showAvatar={showAvatar} onSubmit={onSubmit}/>
      <ProfileModal onDelete={onDelete}/>
    </div>
  );
}

const mapStateToProps = state => ({
  authProps: state.authState
});

export default connect(mapStateToProps, { addProfilePageError, setIsFileBeenChoosen,
                        getAvatar, getResizedAvatar, successfulRegistation, clearAvatar })(Profile);
