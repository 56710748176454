import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../context/AuthContext.js';


function SingInRoute({ component: Component, ...rest }) {

  const { currentUser } = useAuth();

  return (
    <Route
      {...rest}
      render={props => {
        return currentUser ? <Redirect to='/profile' /> : <Component {...props} />;
      }}></Route>
  );
}

export default SingInRoute;
