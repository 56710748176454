import React from 'react';
import { connect } from 'react-redux';

import QuestionEdit from './QuestionEdit';
import QuestionView from './QuestionView';


const Question = ({ currentStoryProps, story }) => {

  const { edit } = currentStoryProps;

  return (
    <React.Fragment>
      {!edit ?
        <QuestionView story={story} /> :
        <QuestionEdit story={story} />
      }
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  currentStoryProps: state.currentStoryState
});

export default connect(mapStateToProps)(Question);
