import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { addSignInPageError, isSignInPageLoading, fromLoginPage, newUserIDPLogin } from '../../actions/authActions';
import { setRedirectToLogin, onHomeLoginClick } from '../../actions/homeRouteActions';

import { useAuth } from '../../context/AuthContext.js';

import SlidesHamButton from '../../pages/Slides/slidesHamButton/SlidesHamButton';
import SlidesNav from '../../pages/Slides/slidesNav/SlidesNav';

import logo from '../../essentials/logo.svg';
import loginPageImg from '../../assets/images/login_page_img.svg';
import facebook from '../../assets/icons/login_page_facebook.svg';
import google from '../../assets/icons/login_page_google.svg';

import './Login.css';


const LoginList = ({ userInteractionProps, fromLoginPage, authProps, homeRouteProps,
                      onHomeLoginClick, mainStoryId, addSignInPageError, isSignInPageLoading,
                      setRedirectToLogin, newUserIDPLogin, newIDPUser }) => {

  const history = useHistory();

  const { signupGoogle, signupFaceBook} = useAuth();

  const { storyId, localCompletedStory } = userInteractionProps;
  const { feedBackPathname, isFeedbackBtnClicked } = homeRouteProps;


  async function socialSingIn(event, media) {
    event.preventDefault();
      try {
        addSignInPageError('');
        isSignInPageLoading(true);
        fromLoginPage(true);
        const auth = await media();
        const isNewUser = auth.additionalUserInfo.isNewUser;
        if (isNewUser) {
          setRedirectToLogin();
          onHomeLoginClick(false);
          newUserIDPLogin(true);
          localStorage.setItem('homeSearchStoriesClicked', true);
          localStorage.setItem('newIDPUser', true);
          return history.push('/ageVerify');
        }
      } catch (event) {
        addSignInPageError(event.message.slice(0, 46));
      }
      isSignInPageLoading(false);
      if ((feedBackPathname !== '') && isFeedbackBtnClicked) {
        console.log(0)
        return history.push(`/feedback/${feedBackPathname}`);
      } else if (storyId === '') {
        return history.push('/');
      } else {
        console.log(3)
        return history.push(`/story/${storyId}`);
      }
  }
  const isFirstTime = localStorage.getItem('isFirstTime')
  return (
    <div className='login-container' style={{ backgroundImage: `url(${loginPageImg})` }}>
      <div className='header'>
        <img src={logo} alt='' className='logo'/>
        <SlidesHamButton />
        <SlidesNav />
      </div>
      <div className='body-container'>
        
        {localCompletedStory ? <span className='loginAdditionalText'>Login to access more stories and free tokens</span> : <span className='loginPageText'>Login to access FREE tokens</span>}
        
        <div onClick={(e) => socialSingIn(e, signupGoogle)}>
          <button className='loginButton googleLogin' >
            <img src={google} alt='' className='img' />
          </button>
        </div>
        <Link to={isFirstTime?"/registration":'/sing-in'} className='loginButton emailLogin'>
          Email / Password
        </Link>
      </div>
    </div>
  );

};

const mapStateToProps = state => ({
  authProps: state.authState,
  userInteractionProps: state.userInteractionState,
  homeRouteProps: state.homeRouteState,
  newIDPUser: state.authState.newIDPUser
});

export default connect(mapStateToProps, { fromLoginPage, addSignInPageError, onHomeLoginClick,
                      isSignInPageLoading, setRedirectToLogin, newUserIDPLogin })(LoginList);
