import * as actionTypes from '../actions/types';


const initialState = {
  currentUser: '',
  contextLoading: true,
  online: '',
  profilePageError: '',
  signInPageError: '',
  signInPageLoading: false,
  avatar: '',
  resizedAvatar: '',
  fromLogin: false,
  redirectToVerifyEmail: false,
  isAuthSuccessful: false,
  isForgotPassModalOpen: false,
  sendPasswordResetEmailMessage: '',
  isFileBeenChoosen: false,
  newIDPUser: (localStorage.getItem('newIDPUser') === 'true') || false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      }
    case actionTypes.AUTH_CONTEXT_LOADING:
      return {
        ...state,
        contextLoading: action.payload
      }
    case actionTypes.IS_ONLINE:
      return {
        ...state,
        online: action.payload
      }
    case actionTypes.PROFILE_PAGE_ERROR:
      return {
        ...state,
        profilePageError: action.payload
      }
    case actionTypes.SIGN_IN_PAGE_ERROR:
    return {
      ...state,
      signInPageError: action.payload
    }
    case actionTypes.SIGN_IN_PAGE_LOADING:
    return {
      ...state,
      signInPageLoading: action.payload
    }
    case actionTypes.GET_RESIZED_AVATAR:
      return {
        ...state,
        resizedAvatar: action.payload
      }
    case actionTypes.GET_AVATAR:
      return {
        ...state,
        avatar: action.payload
      }
    case actionTypes.CLEAR_AVATAR:
      return {
        ...state,
        resizedAvatar: ''
      }
    case actionTypes.FROM_LOGIN_PAGE:
      return {
        ...state,
        fromLogin: action.payload
      }
    case actionTypes.EMAIL_NOT_VERIFIED:
      return {
        ...state,
        redirectToVerifyEmail: action.payload
      }
    case actionTypes.SUCCESSFUL_AUTHORIZATION:
      return {
        ...state,
        isAuthSuccessful: action.payload
      }
    case actionTypes.PASSWORD_RESET_MESSAGE:
      return {
        ...state,
        sendPasswordResetEmailMessage: action.payload
      }
    case actionTypes.AVATAR_FILE:
      return {
        ...state,
        isFileBeenChoosen: action.payload
      }
    case actionTypes.NEW_IDP_USER_LOGIN:
      return {
        ...state,
        newIDPUser: action.payload
      }
    default:
        return state;
  }
};

export default authReducer;
