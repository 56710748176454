import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import useWindowSize from '../../../../hooks/useWindowSize';

import { changeStoryMode } from '../../../../actions/currentStoryActions';

import Description from './description';
import ToggleQuestionAndRedirect from './question/toggleQuestionAndRedirect';

import '../SlidesMainContentQuestions.css';
import SlidesBigImage from '../../slidesBigImage/SlidesBigImage';

const isStandAlone = window.matchMedia('(display-mode: standalone)').matches;


const Card = ({ currentStoryProps, text, story, changeStoryMode, userExperienceProps }) => {

  const { num, mode, nextBtn } = currentStoryProps;
  const { typeWriterIsMounted, typeWriterType, questionTyping, typeWriterState, questionsDelay, answersDelay } = userExperienceProps;

  const { width } = useWindowSize();
  const contextBoxRef = useRef('');

  // useEffect(() => {
  //   const refScrollHeight = contextBoxRef.current.scrollHeight;
  //   const refClientHeight = contextBoxRef.current.clientHeight;
  //   const scroll = refScrollHeight - refClientHeight;
  //   const visibleSpans = document.querySelectorAll('.visible');
  //   const lastVisibleSpan = visibleSpans[visibleSpans.length - 1];
  //   if (width <= 750) {
  //     if (contextBoxRef !== undefined) {
  //       if (typeWriterType === 'description') {

  //       } else if ((typeWriterType === 'question') && !questionTyping && !answersDelay) {
  //         contextBoxRef.current.scrollTo(0, scroll);
  //       }
  //     }
  //   }
  // }, [typeWriterState, typeWriterType, questionTyping, nextBtn, questionsDelay, answersDelay]);

  useEffect(() => {
    story.mode === 'Dark' ? changeStoryMode('dark') : changeStoryMode('default');
  }, [story.mode]);

  const toggleTypeWriterState = (!!text[num + 1] || !!story.question) ? typeWriterIsMounted : true; // if it is the end of the story, then dont use typeWriter state
  return (
    <div
      ref={contextBoxRef}
      className={`contentBox ${text[num] ? 'contentDescription' : 'contentQuestion'} ${typeWriterType === 'description' ? 'contentBoxScroll' : (questionTyping ? 'contentBoxNoScroll' : 'contentBoxScroll')} ${mode === 'dark' ? 'contentBox-dark' : 'contentBox-light'}`}
      >
      {window.innerWidth<650 ? <SlidesBigImage story={story} /> : null}
      {text[num] ?
        <Description text={text}/> :
        <ToggleQuestionAndRedirect story={story} />
      }
    </div>
  );
};

const mapStateToProps = state => ({
  currentStoryProps: state.currentStoryState,
  userExperienceProps: state.userExperienceState
});

export default connect(mapStateToProps, { changeStoryMode })(Card);
