import * as actionTypes from './types';


const addToPriceState = priceObj => ({
  type: actionTypes.GET_PRICES,
  payload: priceObj
});

export const changeBtnText = () => {
  return {
    type: actionTypes.BTN_INNER_TEXT,
  }
}

export const chooseTicket = (amount, price) => {
  const choosenTicketArr = [];
  choosenTicketArr.push(amount, price);
  return {
    type: actionTypes.CHOOSE_TICKET,
    payload: choosenTicketArr
  }
};

export const getPrices = fetchPrices => {
  return async(dispatch) => {
    try {
      const priceObj = await fetchPrices();
      dispatch(addToPriceState(priceObj));
    } catch (err) {
      throw err;
    }
  }
};

export const changeTokensAmount = tokens => {
  return {
    type: actionTypes.CHANGE_TOKENS_AMOUNT,
    payload: tokens
  }
}

export const setEducationalOverlay = bool => {
  return {
    type: actionTypes.EDUCATIONAL_OVERLAY,
    payload: bool
  }
}

export const isEducationalOverlayBeenShown = bool => {
  return {
    type: actionTypes.EDUCATIONAL_OVERLAY_BEEN_SHOWN,
    payload: bool
  }
}

export const setNewPaidAnswer = bool => {
  return {
    type: actionTypes.NEW_PAID_ANSWER_CHOOSING,
    payload: bool
  }
}

export const setCountry = country => {
  let switchCountry;
  const eurCountries = ['AT', 'BE', 'CY', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SI', 'ES'];
  if (eurCountries.includes(country)) {
    switchCountry = 'EU';
  } else {
    switchCountry = country;
  }

  let countryData;
  switch(switchCountry) {
    case 'GB':
      countryData = ['GB', '£'];
      break;
    case 'CA':
      countryData = ['CA', '$'];
      break;
    case 'AU':
      countryData = ['AU', '$'];
      break;
    case 'EU':
      countryData = ['EU', '€'];
      break;
    default:
      countryData = ['default', '$'];
  }

  return {
    type: actionTypes.SET_COUNTRY,
    payload: countryData
  }

}

export const redirectToCheckout = bool => {
  return {
    type: actionTypes.REDIRECT_TO_CHECKOUT,
    payload: bool
  }
}
