import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import logo from "../../../essentials/logo.svg";
import arrowDown from "../../../assets/icons/pwa_app.svg";

import "./pwaPopUp.css";

import safariIcon from "../../../assets/icons/safari.png";
import chromeIcon from "../../../assets/icons/chrome.png";

import { db } from "../../../firebase";

const PWAPopUp = () => {
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState("");

  let OsName = "Unknown OS";
  if (navigator.userAgent.indexOf("Android") != -1) OsName = "Android OS";
  if (navigator.userAgent.indexOf("like Mac") != -1 || navigator.userAgent.match(/Mac OS/)) OsName = "iOS";

  let browserName = "Unknown Browser";

  if (navigator.userAgent.indexOf("Chrome") != -1) browserName = "Chrome";
  if (
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    navigator.userAgent.match(/AppleWebKit/) ||
    navigator.userAgent.match(/Safari/)
  ) {browserName = "Safari"};

  if (OsName === "iOS" && browserName !== "Safari") {
    browserName = "Chrome"
  };

  const getVideo = async () => {
    const dataVideos = await db.collection("videos").get();

    const allVideos = dataVideos.docs.map((doc) => ({
      OsName: doc.OsName,
      ...doc.data(),
    }));

    const currentVideo = allVideos.find((item) => item.OsName === OsName);

    if (currentVideo.path.includes("videos%2Fandroid")) {
      setUrl(currentVideo.path.replace("android%20.mp4", "android.mp4"));
    } else {
      setUrl(currentVideo.path);
    }
  };

  useEffect(() => {
    getVideo();
  }, []);

  return (
    <>
      <button
        className="main-header-download-link"
        onClick={() => setShow(true)}
      >
        <img src={arrowDown} alt="" className="arrow-down" />
        App
      </button>
      <Modal
        id="pwaPopUpModal"
        backdropClassName="profileDeleteModalBackdrop"
        contentClassName="pwaPopUpModalContent"
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        centered
      >
        <div className="pwaModalHeader">
          <button
            type="button"
            className="close"
            onClick={() => {
              setShow(false)
            }}
          >
            &times;
          </button>
          {!(browserName === "Unknown Browser") ? null : (
            <img alt="" src={logo} />
          )}
          <div className="pwaModalStub" />
        </div>
        <div className="modal-body">
          {browserName !== "Unknown Browser" && OsName !== "Unknown OS" ? (
            <div className="pwaModalContent">
              <video controls className="pwaVideo">
                <source src={url} type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
          ) : (
            <div className="pwaModalContent">
              <h1 className="pwaModalH1">
                Please use {OsName === "iOS" ? "Safari" : "Chrome"} to
                <br />
                install the App
              </h1>
              {OsName === "iOS" ? (
                <img src={safariIcon} alt="" className="browserIcon" />
              ) : (
                <img src={chromeIcon} alt="" className="browserIcon" />
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default PWAPopUp;
