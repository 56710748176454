import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { useAuth } from '../../../context/AuthContext';

import { isMobileNavOpen } from '../../../actions/currentStoryActions';
import { isLeaveStoryModalOpen } from '../../../actions/userInteractionActions';

import X from '../../../assets/images/X.png';
import meta from '../../../assets/images/meta.png';
import Inst from '../../../assets/images/Inst.png';
import logo from '../../../essentials/logo.svg';

import './SlidesNav.css';


const SlidesNav = ({ open, isMobileNavOpen, isLeaveStoryModalOpen,
                      homeRouteProps, newIDPUser }) => {

  const history = useHistory();

  const { currentUser } = useAuth();

  const { homeSearchStoriesClicked, overEighteen, termsAgree } = homeRouteProps;

  const isAllHomeRoutePropsTrue = homeSearchStoriesClicked && overEighteen && termsAgree;
  const canLinksBeShown = (currentUser && !newIDPUser) || isAllHomeRoutePropsTrue;

  const isStoryPage = history.location.pathname.substring(1, 6) === 'story';

  function onStoryClick(event) {
    isMobileNavOpen(false);
    const href = event.target.href.split('/')[3];
    if (isStoryPage) {
      event.preventDefault();
      isLeaveStoryModalOpen(true, href);
    }
  }

  return (
    <nav className={`navbar ${open ? 'showNav' : ''}`}>
      <div className='footer2'>
        <img src={logo} alt='' className='logo-nav'/>
        <div className='navList'>
          <NavLink className='slidesNavLinks' to={canLinksBeShown ? '/' : '/home'} onClick={(event) => onStoryClick(event)}>Stories</NavLink>
          <NavLink className='slidesNavLinks' to={canLinksBeShown ? '/profile' : '/home'} onClick={(event) => onStoryClick(event)}>Profile</NavLink>
          <NavLink className='slidesNavLinks' to={canLinksBeShown ? '/privacy' : '/home'} onClick={(event) => onStoryClick(event)}>Privacy</NavLink>
          <NavLink className='slidesNavLinks' to={canLinksBeShown ? '/faq' : '/home'} onClick={(event) => onStoryClick(event)}>FAQ</NavLink>
        </div>
        <div className='footerRight'>
          <div className='roundLinksNav'><img src={X} alt='X' /></div>
          <div className='roundLinksNav'><img src={meta} alt='Meta' /></div>
          <div className='roundLinksNav'><img src={Inst} alt='Instagram' /></div>
        </div>
      </div>
    </nav>
  );
}

const mapStateToProps = state => ({
  homeRouteProps: state.homeRouteState,
  open: state.currentStoryState.open,
  newIDPUser: state.authState.newIDPUser
});

export default connect(mapStateToProps, { isMobileNavOpen, isLeaveStoryModalOpen })(SlidesNav);
