import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import OfflineApp from './OfflineApp';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    { navigator.onLine ?
    <App /> :
    <OfflineApp />
    }
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
