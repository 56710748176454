import React from 'react';
import { connect } from 'react-redux';

import * as timeOutsConstants from '../../../constants/timeOutsConstants';

import { changePageNum, isNextBtnNonActive } from '../../../actions/currentStoryActions';
import { setOverlay, setQuestionsDelay, isQuestionTyping, stopTyping, renderTypewriter } from '../../../actions/userExperienceActions';

import BookButton from '../../bookButton';
import LeftArrow from '../../../pages/Slides/slidesMainContentQuestions/arrows/LeftArrow';
import RightArrow from '../../../pages/Slides/slidesMainContentQuestions/arrows/RightArrow';

import './index.css'


const QuestionsFooter = ({currentStoryProps, changePageNum, story, text, setOverlay,
                          setQuestionsDelay, isQuestionTyping, stopTyping,
                          isNextBtnNonActive, renderTypewriter }) => {

  const arrowClick = () => {
    stopTyping(false);
    isNextBtnNonActive(true);
    setQuestionsDelay(true);
    isQuestionTyping(true);
  };

  const overlayTransition = () => {
    setOverlay(true);
    setTimeout(function() {
      setOverlay(false);
    }, timeOutsConstants.REMOVE_OVERLAY_DELAY);
    renderTypewriter(false);
    setTimeout(function() {
      renderTypewriter(true);
    }, timeOutsConstants.CHANGE_TYPEWRITER_STATE_DELAY);
  };

  return (
    <div className='questionsFooter'>
      <LeftArrow
          text={text}
          arrowClick={arrowClick}
          overlayTransition={overlayTransition}
          story={story}
          isMobile={true}
      />
      <div className='bookBtnWrapper'><BookButton /></div>
      <RightArrow
          text={text}
          arrowClick={arrowClick}
          overlayTransition={overlayTransition}
          story={story}
          isMobile={true}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  currentStoryProps: state.currentStoryState
});

export default connect(mapStateToProps, { changePageNum, setOverlay,
                      setQuestionsDelay, renderTypewriter, isQuestionTyping,
                      stopTyping, isNextBtnNonActive })(QuestionsFooter);
