import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import './profile.css';
import { useForm } from 'react-hook-form';

const EditImageModal = ({userEmail,showAvatar,onSubmit }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [firstStep,setFirstStep] = useState(true)
  const { register, handleSubmit, watch,setValue } = useForm();

  const values = watch()

  useEffect(()=>{
    if(!showAvatar) {
      setSelectedImage(null)
      setValue('image',undefined)
    }
  },[showAvatar])

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFirstStep(false)
    setSelectedImage(file);
  };

  const handleCancel = () =>{
    setSelectedImage(null)
    setFirstStep(true)
    const fileInput = document.getElementById('upload-profile-photo');
    if (fileInput) {
      fileInput.value = ''; 
    }
    console.log("values",values)
  }

  const submit = async (payload) =>{
    await onSubmit(payload)
    setFirstStep(true)
  }

  return (
    <div className='modal' id='profileEditImageModal'>
      <div className='modal-dialog modal-xl modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-edit-avatar'>
            <div className='avatar-panel' >
              {selectedImage?
                <Avatar src={URL.createObjectURL(selectedImage)} name={userEmail} round={true}/>
                :
                  <Avatar src={showAvatar} name={userEmail} round={true} />
              }
            </div>
            <span className='avatarUploadContainer'>
              <React.Fragment>
                <div className='avatar-control-panel'>
                  {!selectedImage || firstStep?
                    <div>
                      <label className='profile-page-label' htmlFor='upload-profile-photo'>Choose image</label>
                      <button className='profile-page-btn' data-dismiss="modal">Exit</button>
                    </div>
                  : <div>
                      <button className='profile-page-btn' onClick={handleCancel}>Cancel</button>
                      <button className='profile-page-btn upload' data-dismiss="modal" onClick={handleSubmit(submit)}>Save</button>
                    </div>}
                  <input ref={register} type='file' name='image' id='upload-profile-photo' onChange={handleFileChange} /> 
                </div>
              </React.Fragment> 
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditImageModal;
