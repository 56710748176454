import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux';

import * as timeOutsConstants from '../../../constants/timeOutsConstants';

import { isNextBtnNonActive } from '../../../actions/currentStoryActions';
import { setOverlay, setQuestionsDelay, isQuestionTyping, stopTyping,
        renderTypewriter } from '../../../actions/userExperienceActions';

import LeftArrow from './arrows/LeftArrow';
import RightArrow from './arrows/RightArrow';
import Card from './card/Card'

import { useFirestore } from '../../../context/FirestoreContext';

import './SlidesMainContentQuestions.css';


const SlidesMainContentQuestions = ({ currentStoryProps, setOverlay, setQuestionsDelay,
              stopTyping, renderTypewriter, text, isQuestionTyping, isNextBtnNonActive }) => {

  const { stories } = useFirestore();
  const params = useParams();

  let story = stories.find(item => item.id === params.id);

  useEffect(() => {
    setTimeout(function() {
      setQuestionsDelay(false);
    }, 3000);
  }, []);


  const arrowClick = () => {
    stopTyping(false);
    isNextBtnNonActive(true);
    setQuestionsDelay(true);
    isQuestionTyping(true);
  };

  const overlayTransition = () => {
    setOverlay(true);
    setTimeout(function() {
      setOverlay(false);
    }, timeOutsConstants.REMOVE_OVERLAY_DELAY);
    renderTypewriter(false);
    setTimeout(function() {
      renderTypewriter(true);
    }, timeOutsConstants.CHANGE_TYPEWRITER_STATE_DELAY);
  };

  return (
    <div className='mainContentQuestions'>
      <LeftArrow
          text={text}
          arrowClick={arrowClick}
          overlayTransition={overlayTransition}
          story={story}
          isMobile={false}
      />
      <Card text={text} story={story} />
      <RightArrow
          text={text}
          arrowClick={arrowClick}
          overlayTransition={overlayTransition}
          story={story}
          isMobile={false}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  currentStoryProps: state.currentStoryState
});

export default connect(mapStateToProps, { renderTypewriter, setOverlay, setQuestionsDelay,
          isQuestionTyping, isNextBtnNonActive, stopTyping })(SlidesMainContentQuestions);
