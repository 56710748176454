import React from 'react';

import Book from '../../assets/images/book.png';

import './index.css';

const BookButton = () => {

    return(
        <React.Fragment>
            <button className='btnBook' data-toggle='modal' data-target='#myModal'>
                <img src={Book} alt="Book"/>
            </button>
        </React.Fragment>
    );
    
};

export default BookButton;
