import React from 'react';

import logo from '../../essentials/logo.svg';

import './loading.css';

const Loading = () => {
  return (
    <div className='loadingContainer'>
      <img src={logo} alt='' />
    </div>
  );
}

export default Loading;
