import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { onOverEighteenClick } from '../../actions/homeRouteActions';

import logo from '../../essentials/logo.svg';
import ageVerificationImg from '../../assets/images/age_verification_img.svg';
import areYouOverEighteen from '../../assets/images/Areyouovereighteen.svg';

import './ageVerify.css';


const AgeVerify = ({ homeRouteProps, onOverEighteenClick }) => {

  const history = useHistory();
  const [loaded,setLoaded] = useState(false)
  const [isDisapear,setIsDisapear] = useState(false)


  const onClickYes = () => {
    setIsDisapear(true)
    onOverEighteenClick(true);
    localStorage.setItem('overEighteen', true);
    setTimeout(()=>{
      history.push('/termsAndConditions');
    },3000)
  };

  const onClickNo = () => {
    setIsDisapear(true)
    onOverEighteenClick(false);
    localStorage.setItem('overEighteen', false);
    setTimeout(()=>{
      history.push('/home');
    },3000)
  };

  const handleLoad = () =>{
    return setLoaded(true)
  }

  return (
    <div className={`ageVerify ${isDisapear? `ageVerify-disapear`:undefined}`}  >
      <img src={logo} className='ageVerifyLogo' alt='' />
      <img src={areYouOverEighteen} alt='' className='areYouOverEighteen' />
      <div className='ageVerifivationButtons'>
        { (loaded) ?
        <button className='ageVerificationBtn No' onClick={() => onClickNo()}>
          No</button>: <></>
        }
        <img src={ageVerificationImg} alt='' className='ageVerificationImg' onLoad={handleLoad}/>
        { (loaded) ?
        <button className='ageVerificationBtn Yes' onClick={() => onClickYes()}>
          Yes</button> : <></>
        }
      </div>
    </div>
  );

};

const mapStateToProps = state => ({
  homeRouteProps: state.homeRouteState
});

export default connect(mapStateToProps, { onOverEighteenClick })(AgeVerify);
