import React from 'react';
import { connect } from 'react-redux';

import TypeWriter from '../../../../../components/typeWriter/TypeWriter';
import SlidesButtons from './../../../slidesButtons/SlidesButtons';


const QuestionView = ({ currentStoryProps, story, overlay }) => {

  const slidesButtonsContainerClass =  story.ways.length < 3 ? '' : 'higherAnswersContainer';

  return (
    <React.Fragment>
      <TypeWriter txt={story.question} type={'question'}/>
      <div className={`slides-buttons-container ${slidesButtonsContainerClass}`} >
        <SlidesButtons story={story} />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  currentStoryProps: state.currentStoryState,
  overlay: state.userExperienceState.overlay
});

export default connect(mapStateToProps)(QuestionView);
