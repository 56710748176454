import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

import { useAuth } from "../context/AuthContext.js";
import { addFeedbackPath } from '../actions/homeRouteActions'


function PrivateRoute({ addFeedbackPath, component: Component, ...rest }) {

  const { currentUser } = useAuth();

  return (
    <Route
      {...rest}
      render={props => {
        //addFeedbackPath(props.location.pathname);
        return currentUser ? <Component {...props} /> : <Redirect to="/login" />
      }}></Route>
  );

}

const mapStateToProps = state => ({
  feedBackPathname: state.homeRouteState.feedBackPathname
});

export default connect(mapStateToProps, {addFeedbackPath})(PrivateRoute);
