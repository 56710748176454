import * as actionTypes from './types';


export const addStoryInteractionData = (storyId , mainStoryId, isMain, num) => {
  localStorage.setItem('storyId', storyId);
  localStorage.setItem('mainStoryId', mainStoryId);
  localStorage.setItem('isMain', isMain);
  localStorage.setItem('num', num);
  return {
    type: actionTypes.ADD_STORY_INTERACTION_DATA,
    isMain: isMain,
    mainStoryId: mainStoryId,
    storyId: storyId,
    num: num
  }
};

export const setToStart = () => {
  localStorage.removeItem('isMain');
  localStorage.removeItem('mainStoryId');
  localStorage.removeItem('storyId');
  localStorage.removeItem('num');
  return {
    type: actionTypes.SET_TO_START,
  }
};

export const isLeaveStoryModalOpen = (bool, href = '') => {
  return {
    type: actionTypes.LEAVE_STORY_MODAL,
    payload: bool,
    href: href
  }
};

export const addToLocalCompletedStory = story => {
  localStorage.setItem('localCompletedStory', story);
  return {
    type: actionTypes.STORY_COMPLETING,
    payload: story
  }
}

export const setLastCompletedStory = storyId => {
  localStorage.setItem('lastCompletedStory', storyId);
  return {
    type: actionTypes.LAST_COMPLETED_STORY,
    payload: storyId
  }
}

export const setNewStoryAnimation = bool => {
  return {
    type: actionTypes.NEW_STORY_ANIMATION,
    payload: bool
  }
};
