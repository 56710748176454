import * as actionTypes from '../actions/types';


const initialState = {
  parentId: 'main',
  currentId: '',
  mobileMenuOpen: false,
  ways: [],
  main: true,
  audio: '',
  img: '',
  bgrImg: '',
  smpict: '',
  btns: [],
  mode: 'Default',
  title: '',
  list: [],
  mainId: '',
  mainImgs: {},
  commitsNum: 3,
  sortedStories: [],
  openStories: [],
  findedStories: []
};


const storiesPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_PARENT_ID:
      return {
        ...state,
        parentId: action.payload
      }
    case actionTypes.CHANGE_CURRENT_ID:
      return {
        ...state,
        currentId: action.payload
      }
    case actionTypes.IS_MOBILE_MENU_OPEN:
      return {
        ...state,
        mobileMenuOpen: action.payload
      }
    case actionTypes.ADD_WAYS:
      return {
        ...state,
        ways: action.payload
      }
    case actionTypes.IS_MAIN:
      return {
        ...state,
        main: action.payload
      }
    case actionTypes.ADD_AUDIO:
      return {
        ...state,
        audio: action.payload
      }
    case actionTypes.ADD_IMG:
      return {
        ...state,
        img: action.payload
      }
    case actionTypes.ADD_BGR_IMG:
      return {
        ...state,
        bgrImg: action.payload
      }
    case actionTypes.ADD_SMPICT:
      return {
        ...state,
        smpict: action.payload
      }
    case actionTypes.ADD_BTNS:
      return {
        ...state,
        btns: action.payload
      }
    case actionTypes.CHANGE_MODE:
      return {
        ...state,
        mode: action.payload
      }
    case actionTypes.ADD_TITLE:
      return {
        ...state,
        title: action.payload
      }
    case actionTypes.CHANGE_LIST:
      return {
        ...state,
        list: action.payload
      }
    case actionTypes.CHANGE_MAIN_ID:
      return {
        ...state,
        mainId: action.payload
      }
    case actionTypes.GET_MAIN_IMGS:
      return {
        ...state,
        mainImgs: action.payload
      }
    case actionTypes.LOAD_MORE_COMMITS:
      return {
        ...state,
        commitsNum: state.commitsNum + 2
      }
    case actionTypes.SET_COMMITS_NUM_TO_ZERO:
      return {
        ...state,
        commitsNum: 3
      }
    case actionTypes.SORTED_MAIN_STORIES:
      let availableStories = [];
      try {
        if (!action.completedStories || action.completedStories.length === 0) {
          availableStories.push(action.payload[0].id);
          if (action.localCompletedStory !== '' && action.completedStories) {
            availableStories.push(action.payload[1].id);
          }
        } else {
          action.completedStories.map(storyId => {
            action.payload.map(story => story.id === storyId);
            const notInArr = !(availableStories.find(id => id === storyId));
            if (notInArr) {
              availableStories.push(storyId);
            }
            if (action.payload[availableStories.length]) {
              const curId = action.payload[availableStories.length].id;
              const notInArr = !(availableStories.find(id => id === curId));
              if (notInArr) {
                availableStories.push(curId);
              }
            }
          });
        }
      } catch {}
      return {
        ...state,
        sortedStories: action.payload,
        openStories: availableStories
      }
    case actionTypes.ADD_TO_FINDED_STORIES:
      return {
        ...state,
        findedStories: action.payload
      }
    default:
        return state;
  }
};

export default storiesPageReducer;
