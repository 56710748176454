import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Strip from './components/strip';

import './index.css';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);


function App() {
  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <Router>
          <Strip />
        </Router>
      </Provider>
    </Elements>
  );
}

export default App;
