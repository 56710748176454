import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useWindowSize from '../../hooks/useWindowSize';
import { useFirestore } from '../../context/FirestoreContext';
import { useAuth } from '../../context/AuthContext';

import { chooseTicket, getPrices, setCountry, redirectToCheckout } from '../../actions/buyingTicketsActions';

import BuyTicketsPayment from './BuyTicketsPayment';

import logo from '../../essentials/logo.svg';
import BgrImgBig from '../../assets/images/oval_and_peoples.svg';
import BgrImgSmall from '../../assets/images/buy_mobile.svg';
import BigTicket from '../../assets/images/big_ticket.svg';
import SmallTicket from '../../assets/images/small_ticket.svg';

import './BuyTickes.css';
import { useLocation } from 'react-router-dom';


const BuyTickesFlashPage = ({ buyingTicketsProps, userInteractionProps, ticketsPrices,
                              chooseTicket, getPrices,setCountry, redirectToCheckout }) => {

  const history = useHistory();

  const { currentUser } = useAuth();
  const { fetchPrices } = useFirestore();
  const { width } = useWindowSize();
  const { choosenTicket, country } = buyingTicketsProps;
  const storyId  = localStorage.getItem('currentStoryId');
  const successUrl = !!storyId ? `/story/${storyId}` : '/';

  let price;
  switch(country[0]) {
    case 'GB':
      price = 'price_gbr';
      break;
    case 'CA':
      price = 'price_cad';
      break;
    case 'AU':
      price = 'price_aud';
      break;
    case 'EU':
      price = 'price_eur';
      break;
    default:
      price = 'price_usd';
  }

  function chooseTicketToPay (amount, price) {
    chooseTicket(amount, price);
  }

  const stripechekout = localStorage.getItem('stipecheckout')
  if(stripechekout){
    localStorage.removeItem('stipecheckout')
  }

  useEffect(() => {
    redirectToCheckout(true);
    localStorage.setItem('fromCheckout', true);
    fetch('https://extreme-ip-lookup.com/json/?key=GRzFCZ5PhdFWdBZUvozz')
      .then(res => res.json())
      .then(response => {
      setCountry(response.countryCode);
    })
    .then(getPrices(fetchPrices))
    .catch(status => {
        console.log('Request failed.  Returned status of', status)
      });
  },[]);

 useEffect(() => {
   if (price && ticketsPrices[0]) {
     chooseTicketToPay(ticketsPrices[0].amount, ticketsPrices[0][price])
   }
 }, [price, ticketsPrices]);


  function goBack() { 
    history.push(successUrl);         
  }

  return (
    <div className='BuyTickesFlashPage'>
      <div className='header'>
        <Link to='/'><img src={logo} alt='' /></Link>
      </div>
      <div className='topBuyContent'>
        <button type='button' className='buyCloseBtn'  onClick={()=> goBack()}>&times;</button>
        <h2>Choose number of tokens</h2>
      </div>
      <div className='ticketsContent'  style={{ backgroundImage: `url(${width <= 625 ? BgrImgSmall : BgrImgBig})` }}>
        <div className='bigTicket' style={{ backgroundImage: `url(${BigTicket})` }}>
          <p className='ticketsAmount'>{choosenTicket[0] ? choosenTicket[0] : null}</p>
          <p className='ticketsPrice'>{choosenTicket[1] ? `${country[1]} ${choosenTicket[1].toFixed(2)}` : null}</p>
        </div>
        <div className='smallTicketsContainer'>
          {(ticketsPrices && country) ? ticketsPrices.map(ticket => (
            <div className='smallTicket' style={{ backgroundImage: `url(${SmallTicket})` }} key={ticket.id}  onClick={() => chooseTicketToPay(ticket.amount, ticket[price])} >
              <p className='ticketsAmount'>{ticket.amount}</p>
            <p className='ticketsPrice'>{`${country[1]} ${ticket[price].toFixed(2)}`}</p>
            </div>
          )) : null}
        </div>
      </div>
      <div className='buyBtn'>
        <BuyTicketsPayment countryCode={country[0]} choosenTicket={choosenTicket[0]} uid={currentUser.uid} successUrl={successUrl}/>
      </div>
    </div>
  );

}

const mapStateToProps = state => ({
  buyingTicketsProps: state.buyingTicketsState,
  userInteractionProps: state.userInteractionState,
  ticketsPrices: state.buyingTicketsState.ticketsPrices
});

export default connect(mapStateToProps, { chooseTicket, getPrices, setCountry, redirectToCheckout })(BuyTickesFlashPage);
