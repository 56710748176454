import * as actionTypes from './types';


export const onHomeStoriesButtonClick = bool => {
  return {
    type: actionTypes.HOME_STORIES_BUTTON_CLICK,
    payload: bool
  }
};

export const onOverEighteenClick = bool => {
  return {
    type: actionTypes.OVER_EIGHTEEN,
    payload: bool
  }
};

export const onTermsAgree = bool => {
  return {
    type: actionTypes.TERMS_AGREE,
    payload: bool
  }
};

export const addFeedbackPath = path => {
  return {
    type: actionTypes.FEEDBACK_PATHNAME,
    payload: path
  }
};

export const isFeedbackClicked = bool => {
  return {
    type: actionTypes.FEEDBACK_CLICKED,
    payload: bool
  }
};

export const setRedirectToLogin = () => {
  return {
    type: actionTypes.REDIRECT_TO_LOGIN,
  }
};

export const onHomeLoginClick = bool => {
  return {
    type: actionTypes.HOME_LOGIN_CLICKED,
    payload: bool
  }
};
