import * as actionTypes from '../actions/types';


const initialState = {
  typeWriter: false,
  overlay: false,
  questionsDelay: true,
  questionTyping: true,
  stopTyping: false,
  answersDelay: true,
  typeWriterType: '',
  typeWriterState: 0,
  typeWriterIsMounted: false
};


const userExperienceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RENDER_TYPEWRITER:
      return {
        ...state,
        typeWriter: action.payload
      }
    case actionTypes.STORY_OVERLAY:
      return {
        ...state,
        overlay: action.payload
      }
    case actionTypes.QUESTIONS_DELAY:
      return {
        ...state,
        questionsDelay: action.payload
      }
    case actionTypes.QUESTION_TYPING:
      return {
        ...state,
        questionTyping: action.payload
      }
    case actionTypes.STOP_TYPING:
      return {
        ...state,
        stopTyping: action.payload
      }
    case actionTypes.ANSWERS_DELAY:
      return {
        ...state,
        answersDelay: action.payload
      }
    case actionTypes.TYPEWRITER_TYPE:
      return {
        ...state,
        typeWriterType: action.payload
      }
    case actionTypes.CHANGE_TYPEWRITER_STATE:
      return {
        ...state,
        typeWriterState: action.payload
      }
    case actionTypes.TYPEWRITER_MOUNTED:
      return {
        ...state,
        typeWriterIsMounted: action.payload
      }
    default:
      return state;
  }
}

export default userExperienceReducer;
