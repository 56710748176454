import React from 'react';
import { connect } from 'react-redux';

import { setDescription } from '../../../../actions/currentStoryActions';

import TypeWriter from '../../../../components/typeWriter/TypeWriter';

//Props: text
const Description = ({ text, currentStoryProps, setDescription, overlay }) => {

  const { edit, description, num } = currentStoryProps;


  const handleChange = (event) => {
    setDescription(event.target.value);
  };

  return (
    <React.Fragment>
      {!edit  ?
        <TypeWriter txt={text[num]} type={'description'} /> :
        <textarea value={description} onChange={handleChange} className='form-control' />
      }
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  currentStoryProps: state.currentStoryState,
  overlay: state.userExperienceState.overlay
});

export default connect(mapStateToProps, { setDescription } )(Description);
