import * as actionTypes from '../actions/types';


const initialState = {
  error: '',
  loading: false,
  logInError: '',
  logInLoading: false,
  newUser: false,
  isRegSuccessful: false
};

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case actionTypes.IS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case actionTypes.SIGN_UP_PAGE_ERROR:
      return {
        ...state,
        logInError: action.payload
      }
    case actionTypes.SIGN_UP_PAGE_LOADING:
      return {
        ...state,
        logInLoading: action.payload
      }
    case actionTypes.NEW_USER:
      return {
        ...state,
        newUser: action.payload
      }
    case actionTypes.SUCCESSFUL_REGISTRATION:
      return {
        ...state,
        isRegSuccessful: action.payload
      }
    default:
        return state;
  }
};

export default registrationReducer;
