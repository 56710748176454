import * as actionTypes from '../actions/types';

const initialState = {
  isMain: (localStorage.getItem('isMain') === 'true') || false,
  mainStoryId: localStorage.getItem('mainStoryId') || '',
  storyId: localStorage.getItem('storyId') || '',
  num: parseInt(localStorage.getItem('num')) || 0,
  leaveStoryModalOpen: false,
  href: '',
  localCompletedStory: localStorage.getItem('localCompletedStory') || '',
  lastCompletedStory: localStorage.getItem('lastCompletedStory') || '',
  newStoryanimation: false
};


const userInteractionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_STORY_INTERACTION_DATA:
      return {
        ...state,
        isMain: action.isMain,
        mainStoryId: action.mainStoryId,
        storyId: action.storyId,
        num: action.num,
      }
    case actionTypes.SET_TO_START:
      return {
        isMain: false,
        mainStoryId: '',
        storyId: '',
        num: 0,
        leaveStoryModalOpen: false,
        href: '',
        localCompletedStory: localStorage.getItem('localCompletedStory') || '',
        lastCompletedStory: localStorage.getItem('lastCompletedStory')
      }
    case actionTypes.LEAVE_STORY_MODAL:
      return {
        ...state,
        leaveStoryModalOpen: action.payload,
        href: action.href
      }
    case actionTypes.STORY_COMPLETING:
      return {
        ...state,
        localCompletedStory: action.payload
      }
    case actionTypes.LAST_COMPLETED_STORY:
      return {
        ...state,
        lastCompletedStory: action.payload
      }
    case actionTypes.NEW_STORY_ANIMATION:
      return {
        ...state,
        newStoryanimation: action.payload
      }
    default:
      return state;
  }
};

export default userInteractionReducer;
