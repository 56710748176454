import React from 'react';
import { connect } from 'react-redux';

import logo from '../../essentials/logo.svg';
import LockedStoryPopUpImg from '../../assets/icons/lock_story_popup_icon.svg';

import './index.css';

const LockedStoryModal = ({ completedStories }) => {

  return (
    <div className='modal' id='lockStoryModal'>
      <div className='modal-dialog'>
        <div className='modal-content lockStoryModal'>
          <div className='lockStoryModalHeader'>
            <img src={logo} alt='' />
          </div>
          <div className='modal-body'>
            <img src={LockedStoryPopUpImg} alt='' />
            <h2>Locked</h2>
            <p>Complete previous story to Unlock</p>
          </div>
          <button className='okBtn' data-dismiss='modal'>Ok</button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  completedStories: state.authState.online.completedStories,
});

export default connect(mapStateToProps)(LockedStoryModal);
