import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';

import { changeCurrentUser, isAuthContextLoading, isOnline, successfulAuthorization, setPasswordResetMessage } from '../actions/authActions';
import { addError, successfulRegistation, addSignUpPageError } from '../actions/registrationActions';

import { auth, providerGoogle, providerFaceBook, functions } from '../firebase';
import { db } from '../firebase';

const AuthContext = React.createContext();


export function useAuth() {
  return useContext(AuthContext)
}

const mapStateToProps = state => ({
  registrationError: state.registrationState.error,
  authProps: state.authState,
  tokensAmount: state.buyingTicketsState.tokensAmount,
  localCompletedStory: state.userInteractionState.localCompletedStory
});

export const AuthProvider = connect(mapStateToProps, { addError, successfulRegistation, successfulAuthorization, setPasswordResetMessage,
    changeCurrentUser, isAuthContextLoading, isOnline, addSignUpPageError })(({ addError, successfulRegistation, successfulAuthorization,
    localCompletedStory, authProps, tokensAmount, changeCurrentUser, isAuthContextLoading, isOnline, children, setPasswordResetMessage,
    registrationError, addSignUpPageError }) => {

  const { currentUser, contextLoading, online } = authProps;

  const getUser = async (current) => {
    const data = await db.collection('users').get();
    const users = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    const user = users.find(item=> item.id === current.uid)
      isOnline({...user, ...current});
  };

  async function deleteUser(){
    const userDelete = functions.httpsCallable('onUserDelete');
    await userDelete({uid: currentUser.uid});
    changeCurrentUser('');
    isOnline('');
  }

  async function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
    .then(function() {
      setPasswordResetMessage('Password reset email sent');
    })
    .catch(function(error) {
      setPasswordResetMessage('Failed to verify if user exist. Please try again.');
    });
  }

  async function checkOldPassword(oldPassword) {
    const user = auth.currentUser;
    return auth.signInWithEmailAndPassword(user.email, oldPassword)
      .then(function() {
        // successfulAuthorization(true);
        return true;
      }).catch(() => {
        return false
      });
    
  }

  async function changePassword(newPassword) {
    const user = auth.currentUser;
    await user.updatePassword(newPassword);
  }

  function signupGoogle() {
    return auth.signInWithPopup(providerGoogle);
  }

  function signupFaceBook() {
    return auth.signInWithPopup(providerFaceBook);
  }

  function signup(email, password) {
    auth.createUserWithEmailAndPassword(email, password).then((creds) => {
      successfulRegistation(true);
      send_verification_email();
      return creds;
    }).catch(function(err) {
        return addError(err.message);
      });
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
    .then(function(result) {
      successfulAuthorization(true);
      return result;
    }).catch(function(error) {
      addSignUpPageError(error.message);
    });
  }

  function logout() {
    isOnline('')
    return auth.signOut()
  }

  function send_verification_email(){
    var user = auth.currentUser;
    user.sendEmailVerification().then(() => {
    }).catch(err => err)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        changeCurrentUser(user);
        user.getIdTokenResult().then(idTokenResult => {
          user.admin = idTokenResult.claims.admin;
          getUser(user);
          setTimeout(() => getUser(user), 2000);
          isAuthContextLoading(false);
        })
      } else {
        changeCurrentUser(null);
        isAuthContextLoading(false);
      }
    })
    return () => unsubscribe()
  }, [tokensAmount, localCompletedStory]);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    signupGoogle,
    signupFaceBook,
    changePassword,
    checkOldPassword,
    resetPassword,
    deleteUser,
    user: online
  }

  return (
    <AuthContext.Provider value={value}>
      {!contextLoading && children}
    </AuthContext.Provider>
  )
});
