import React from 'react';
import { connect } from 'react-redux';

import { changePageNum } from '../../../../actions/currentStoryActions';
import { renderTypewriter, stopTyping, isTypeWriterMounted } from '../../../../actions/userExperienceActions';

import rightArrow from '../../../../assets/icons/right_arrow.svg';

import '../SlidesMainContentQuestions.css';


const RightArrow = ({ currentStoryProps, text, arrowClick, overlayTransition, isMobile,
          changePageNum, renderTypewriter, stopTyping, typeWriterType, story, isTypeWriterMounted }) => {

  const { num, nextBtn } = currentStoryProps;
  const isTheEnd = !(!!text[num + 1] || !!story.question);
  const nextBtnOnClick = () => {
    isTypeWriterMounted(false);
    if (nextBtn) {
      if(typeWriterType !== '') {
        stopTyping(true);
      }
    } else {
      if (((num + 1) === 0) || isTheEnd) {
        overlayTransition(); 
      }
      arrowClick();
      renderTypewriter(true);
      changePageNum(num + 1);
    }
  };

  return (
    <React.Fragment>
      {text[num] ?
        <div disabled={nextBtn} className={`${isMobile ? 'mobile-circle-btn' : 'desktop-circle-btn'} circle-btn`} onClick={() => nextBtnOnClick()}>
          <img src={rightArrow} alt=''/>
        </div> :
        <div className={`${isMobile ? 'mobile-btn-stub-right' : 'desktop-btn-stub-right'} btn-stub-right`}></div>
      }
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  currentStoryProps: state.currentStoryState,
  typeWriterType: state.userExperienceState.typeWriterType
});

export default connect(mapStateToProps, { changePageNum, renderTypewriter,
                      stopTyping, isTypeWriterMounted })(RightArrow);
