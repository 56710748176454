import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactStars from "react-rating-stars-component";
import Avatar from 'react-avatar';

import { loadMoreCommits, setCommitsNumToZero  } from '../../actions/storiesPageActions';

import { useAuth } from '../../context/AuthContext';

import StarComponent from '../../components/ratingStar/RatingStar';


import './index.css';


const CommitsModal = ({ pressed,screenSize, commits, completedStories, storyId, storyTitle, usersAvatars, commitsNum, loadMoreCommits, setCommitsNumToZero }) => {

  const history = useHistory();

  const { currentUser } = useAuth();

  let disableBtn = true;
  const modalContent = useRef(null)
  if (currentUser) {
    if (completedStories) {
      const isStoryInComplete = completedStories.findIndex(element => element === storyId);
      disableBtn = isStoryInComplete === -1;
    }
  }

  const storyCommits = commits.filter(commit => commit.storyId === storyId);

  const onClickAddReview = () => {
    history.push(`/feedback/${storyId}`);
  };

  useEffect(()=>{
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if(storyCommits.length > commitsNum) {
            loadMoreCommits()
          }
          observer.unobserve(entry.target);
        }
      })})
    
    if(modalContent.current) {
      observer.observe(modalContent.current)}

    return () => {
      
      if (modalContent.current) {
        observer.unobserve(modalContent.current);
      }
    }
  },[commitsNum,pressed]) 

  const onClickClose = () => {
    setCommitsNumToZero();
  }

  return (
    <div className='modal' id={`commitsModal_${storyId}`}>
      <div className='modal-dialog modal-dialog-centered commitsModalDialog'>
        <div className='modal-content commitsModal'>
          <div className='commitsModalHeader'>
            <h2 className='commitsModalTitle'>{storyTitle}</h2>
            <div className='commitsModalHeaderBtns'>
              <button type='button' disabled={disableBtn} className='modalAddReview' onClick={()=> onClickAddReview()} data-dismiss='modal'>
                Add Review
              </button>
              <button type='button' className='close-modal-button' data-dismiss='modal' onClick={() => onClickClose()}>&times;</button>
            </div>
          </div>
          <div className='modal-body commitsModalBody'>
              { storyCommits.map((commit) => (
                <div className='commitDetails' key={commit.id}>
                  <div className='avatarContainer'>
                    <Avatar src={commit.userAvatar} name={commit.userEmail[0]}  round={true} size={65} key={commit.id} />
                      <div className='commitRatingMobile'>
                        <ReactStars
                          count={5}
                          classNames='starMain'
                          name='rating'
                          a11y={false}
                          edit={false}
                          value={commit.rating}
                          size={screenSize === 1 ? 15 : 10}
                          isHalf={false}
                          emptyIcon={<StarComponent isEmpty={true} size={screenSize === 1 ? 15 : 10} />}
                          filledIcon={<StarComponent isEmpty={false} size={screenSize === 1 ? 15 : 10} />}
                        />
                      </div>
                  </div>
                  <div className='commitInfoContainer'>
                    <div className='commitRating'>
                      <ReactStars
                        count={5}
                        classNames='starMain'
                        name='rating'
                        a11y={false}
                        edit={false}
                        value={commit.rating}
                        size={screenSize === 1 ? 15 : 10}
                        isHalf={false}
                        emptyIcon={<StarComponent isEmpty={true} size={screenSize === 1 ? 15 : 10} />}
                        filledIcon={<StarComponent isEmpty={false} size={screenSize === 1 ? 15 : 10} />}
                      />
                    </div>
                    <div className={`commitText ${(commit.commit === '') && 'emptyCommit'}`}>{commit.commit !== '' ? `«${commit.commit}»` : null}</div>
                  </div>
                </div>
              )).slice(0, commitsNum)}
              <div ref={modalContent}></div>
          </div>
          <button disabled={(storyCommits.length === commitsNum) || (storyCommits.length <= 3)} className='loadMore' >
            {(storyCommits.length <= commitsNum) || (storyCommits.length <= 3) ? 'No More Reviews' : 'Loading more'}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  completedStories: state.authState.online.completedStories,
  commitsNum: state.storiesPageState.commitsNum,
  commits: state.firebaseState.commits
});


export default connect(mapStateToProps, { loadMoreCommits, setCommitsNumToZero })(CommitsModal);
