import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import BookButton from '../../bookButton';

import { useAuth } from '../../../context/AuthContext';

import Ticket from '../../../assets/icons/Ticket.svg';

import './index.css';


const QuestionsHeader = ({ buyingTicketsProps }) => {

  const { currentUser, user } = useAuth();

  const { educationalOverlay, newPaidAnswer } = buyingTicketsProps;

  const stipeCheckout = localStorage.getItem('stipecheckout')

  setTimeout(()=>{
    localStorage.removeItem('stipecheckout')
  },1500)

  const paddingClass = user.tokens <= 9 ? 'one-digit-padding' : 'two-digits-padding';

  useEffect(() => {}, [user]);

    return (
      <div className='topContentQuestions'>
        {currentUser  ?
          <Link
            className={`${newPaidAnswer ? 'answerScale' : null} headerTicketsLink`}
            to='/buyTickets'
            // style={educationalOverlay ? { zIndex: 99, transform: 'scale(1.1)' } : null}
          >
            <div
              id='headerTickets'
              className={`header-tickets-story ${paddingClass} ${user.tokens === 0 ? 'tickets-red' : null} ${educationalOverlay||stipeCheckout ? 'first-tokens' : null}`}
              style={{ backgroundImage: `url(${Ticket})` }}
            >
              {user.tokens}
            </div>
          </Link>
        : null}
        <BookButton />
      </div>
    )
};


const mapStateToProps = state => ({
  buyingTicketsProps: state.buyingTicketsState
});

export default connect(mapStateToProps)(QuestionsHeader);
