import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';

import { stopTyping, isQuestionTyping, setTypewriterType,
        setTypewriterState, renderTypewriter, setOverlay,
        isTypeWriterMounted } from '../../actions/userExperienceActions';
import { isNextBtnNonActive } from '../../actions/currentStoryActions';

import './typeWriter.css';
import useFontLoader from '../../hooks/useFontLoader';
import { SpinLoader } from '../spinerLoader/spinLoader';


const TypeWriter = ({ txt, type, userExperienceProps, isNextBtnNonActive, mode,
                      setTypewriterState, isQuestionTyping, setTypewriterType,
                      renderTypewriter, setOverlay, isTypeWriterMounted }) => {


  const fontLoaded = useFontLoader("Raleway")
  const [additionalClass,setAdditionalClass] = useState(null)
  useEffect(() => {
    if(additionalClass&&fontLoaded){
      isTypeWriterMounted(true);
    }
    
  }, [additionalClass,fontLoaded]);

  useLayoutEffect(()=>{
    if (type === 'description') {
      setAdditionalClass(`main-text ${mode === 'dark' ? 'main-text-dark' : 'main-text-light'}`);
    } else if (type === 'question') {
      setAdditionalClass(`question-text ${mode === 'dark' ? 'question-text-dark' : 'question-text-light'}`);
    } else {
      setAdditionalClass(null)
    }
  },[type,mode])

  const { stopTyping } = userExperienceProps;


  function addToState() {
    if (type === 'description') {
      isNextBtnNonActive(false);
    } else {
      isQuestionTyping(false);
    }
  }

  useLayoutEffect   (()=>{
    addToState()
    setTypewriterType(type)
    renderTypewriter(true);
  },[])
    
  return ( userExperienceProps.typeWriterIsMounted)? <div className={`typeWriter ${additionalClass}`} id='typingText' >{txt}</div>:<SpinLoader/>;
};

const mapStateToProps = state => ({
  userExperienceProps: state.userExperienceState,
  mode: state.currentStoryState.mode
});

export default connect(mapStateToProps,{ stopTyping, isNextBtnNonActive,
                      isQuestionTyping, setTypewriterType, setTypewriterState,
                      renderTypewriter, setOverlay, isTypeWriterMounted })(TypeWriter);
